import { React, useState } from "react";
import TestsList from "./TestsList";
import Tabs from "../../../../../Shared/Tabs";
import TestBulkupload from "./TestBulkupload";
import Test from "./Test";
import { useFetchSimpleSpeakingTests } from "./../../../../../../hooks/AdminDashboard/useSpeaking";

const SpeakingContent = ({ subcategoryId }) => {
  const [selectedTest, setSelectedtest] = useState(null);

  const { data, isLoading } = useFetchSimpleSpeakingTests(subcategoryId);

  if (isLoading) {
    return <>Loading ... plz wait</>;
  }

  console.log(data);

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row h-100">
        <TestsList
          tests={data}
          selectedTest={selectedTest}
          handleSelectTest={setSelectedtest}
        />

        {/* Main Content */}
        <div className="col-9">
          <Tabs
            tabs={[
              {
                key: "test",
                label: "Test",
                content: (
                  <Test
                    subcategoryId={subcategoryId}
                    selectedTest={selectedTest}
                    setSelectedtest={setSelectedtest}
                  />
                ),
              },
              {
                key: "bulkuploadtests",
                label: "Bulk upload tests",
                content: <TestBulkupload subcategoryId={subcategoryId} />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default SpeakingContent;
