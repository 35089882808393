import axios from "axios";

const refresh_URL = "/api/users/auth/refresh/";
// const logout_URL = "/api/users/auth/logout/";

// Create an Axios instance
const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL, // Base URL from environment variables
  withCredentials: true, // Send HTTP-only cookies with requests
});

// Global redirect flag to prevent multiple redirects
let isRedirecting = false;

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    console.debug("Request Config:", config); // Debugging request config
    return config;
  },
  (error) => {
    console.error("Request Error:", error); // Log request errors
    return Promise.reject(error); // Propagate error for handling
  }
);

// Response Interceptor
api.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loop for refresh endpoint
    if (originalRequest.url.includes(refresh_URL)) {
      console.log("Refresh request failed—no retry.");
      return Promise.reject(error); // Stop retry loop
    }

    // Handle 401 Unauthorized Errors (Session Expired)
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request to avoid retry loops

      try {
        console.log("Attempting to refresh the token...");

        // Attempt to refresh the token
        await api.post(refresh_URL, {});

        console.log("Token refreshed successfully!");

        // Retry the original request
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);

        // Immediately log out and redirect after refresh failure
        if (!isRedirecting) {
          isRedirecting = true;
        }
      }
    }

    // For other errors, propagate as usual
    return Promise.reject(error);
  }
);

export default api;
