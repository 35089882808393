// Validate Phone Number
export const validatePhoneNumber = (phone) => {
  const phoneRegex = /^\+998 \d{2} \d{3} \d{2} \d{2}$/;
  return phoneRegex.test(phone);
};

// Validate Password Strength
export const validatePasswordStrength = (password) => {
  // Backend rules (basic requirements)
  const minLength = 8; // Minimum length
  const hasLetter = /[a-zA-Z]/.test(password); // At least 1 letter
  const hasNumber = /\d/.test(password); // At least 1 number

  // Additional checks for strength
  const hasUpperCase = /[A-Z]/.test(password); // Uppercase letter
  const hasLowerCase = /[a-z]/.test(password); // Lowercase letter
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Special character

  // Check backend requirements
  if (password.length < minLength || !hasLetter || !hasNumber) {
    return "Weak"; // Matches backend rejection
  }

  // Determine strength based on additional checks
  const isStrong = hasUpperCase && hasLowerCase && hasSpecialChar;

  return isStrong ? "Strong" : "Medium";
};

// Validate Password Match
export const validatePasswordMatch = (password, confirmPassword) => {
  return password === confirmPassword ? "" : "Passwords do not match!";
};

// ================================
// validates grammar questions for bulk upload
// ================================

export const validateQuestions = (questions) => {
  console.log("Questions:", questions);

  const isValid = questions.every((q, index) => {
    console.log(`\nChecking question ${index + 1}:`, q);

    // Validate common fields
    if (!q.format) {
      console.error(`Question ${index + 1} failed: Missing 'format'`);
      return false;
    }

    if (!q.text) {
      console.error(`Question ${index + 1} failed: Missing 'text'`);
      return false;
    }

    if (!q.feedback) {
      console.error(`Question ${index + 1} failed: Missing 'feedback'`);
      return false;
    }

    // Format-specific validation
    switch (q.format) {
      case "MCQ":
        if (!Array.isArray(q.choices)) {
          console.error(
            `Question ${index + 1} failed: 'choices' is not an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer) || q.correct_answer.length !== 1) {
          console.error(
            `Question ${
              index + 1
            } failed: 'correct_answer' must be a single-item array`
          );
          return false;
        }
        if (!q.choices.includes(q.correct_answer[0])) {
          console.error(
            `Question ${index + 1} failed: 'correct_answer' is not in 'choices'`
          );
          console.log("Choices:", q.choices);
          console.log("Correct Answer:", q.correct_answer);
          return false;
        }
        break;

      case "GAP_FILL":
        if (q.choices !== null) {
          console.error(`Question ${index + 1} failed: 'choices' must be null`);
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `Question ${index + 1} failed: 'correct_answer' must be an array`
          );
          return false;
        }
        break;

      case "MATCHING":
        if (!Array.isArray(q.choices)) {
          console.error(
            `Question ${index + 1} failed: 'choices' must be an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `Question ${index + 1} failed: 'correct_answer' must be an array`
          );
          return false;
        }
        break;

      case "MULTI_SELECT":
        if (!Array.isArray(q.choices)) {
          console.error(
            `Question ${index + 1} failed: 'choices' is not an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `Question ${index + 1} failed: 'correct_answer' must be an array`
          );
          return false;
        }
        if (!q.correct_answer.every((ans) => q.choices.includes(ans))) {
          console.error(
            `Question ${
              index + 1
            } failed: One or more 'correct_answer' values are not in 'choices'`
          );
          console.log("Choices:", q.choices);
          console.log("Correct Answer:", q.correct_answer);
          return false;
        }
        break;

      default:
        console.error(
          `Question ${index + 1} failed: Unsupported format '${q.format}'`
        );
        return false;
    }

    console.log(`Question ${index + 1} is valid.`);
    return true; // All checks passed for this question
  });

  console.log("Validation Result:", isValid);
  return isValid;
};

// =======================
// Validates bulk JSON with words with questions
// =======================

export const validateBulkWordsWithQuestions = (words) => {
  console.log("Words:", words);

  // Iterate through each word
  const isValid = words.every((word, wordIndex) => {
    console.log(`\nValidating word ${wordIndex + 1}:`, word);

    // Validate word fields
    if (!word.name) {
      console.error(`Word ${wordIndex + 1} failed: Missing 'name'`);
      return false;
    }

    if (!word.description) {
      console.error(`Word ${wordIndex + 1} failed: Missing 'description'`);
      return false;
    }

    console.log(`Word ${wordIndex + 1} is valid.`);
    return true;
  });

  console.log("Validation Result:", isValid);
  return isValid;
};

// =======================
// Validates bulk JSON with questions of a word
// =======================
export const validateWordQuestions = (questions) => {
  console.log("Validating questions:", questions);

  // Validate each question
  const isValid = questions.every((q, index) => {
    console.log(`\nValidating question ${index + 1}:`, q);

    // Check required fields
    if (!q.format) {
      console.error(`Question ${index + 1} failed: Missing 'format'.`);
      return false;
    }
    if (!q.text) {
      console.error(`Question ${index + 1} failed: Missing 'text'.`);
      return false;
    }
    if (!q.feedback) {
      console.error(`Question ${index + 1} failed: Missing 'feedback'.`);
      return false;
    }

    // Format-specific validation
    switch (q.format) {
      case "MCQ":
        if (!Array.isArray(q.choices)) {
          console.error(
            `MCQ Question ${
              questionIndex + 1
            } failed: 'choices' is not an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer) || q.correct_answer.length !== 1) {
          console.error(
            `MCQ Question ${
              questionIndex + 1
            } failed: 'correct_answer' must be a single-item array`
          );
          return false;
        }
        if (!q.choices.includes(q.correct_answer[0])) {
          console.error(
            `MCQ Question ${
              questionIndex + 1
            } failed: 'correct_answer' is not in 'choices'`
          );
          return false;
        }
        break;

      case "GAP_FILL":
        if (q.choices !== null) {
          console.error(
            `GAP_FILL Question ${
              questionIndex + 1
            } failed: 'choices' must be null`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `GAP_FILL Question ${
              questionIndex + 1
            } failed: 'correct_answer' must be an array`
          );
          return false;
        }
        break;

      case "MATCHING":
        if (!Array.isArray(q.choices)) {
          console.error(
            `MATCHING Question ${
              questionIndex + 1
            } failed: 'choices' must be an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `MATCHING Question ${
              questionIndex + 1
            } failed: 'correct_answer' must be an array`
          );
          return false;
        }
        break;

      case "MULTI_SELECT":
        if (!Array.isArray(q.choices)) {
          console.error(
            `MULTI_SELECT Question ${
              questionIndex + 1
            } failed: 'choices' is not an array`
          );
          return false;
        }
        if (!Array.isArray(q.correct_answer)) {
          console.error(
            `MULTI_SELECT Question ${
              questionIndex + 1
            } failed: 'correct_answer' must be an array`
          );
          return false;
        }
        if (!q.correct_answer.every((ans) => q.choices.includes(ans))) {
          console.error(
            `MULTI_SELECT Question ${
              questionIndex + 1
            } failed: Invalid answer(s)`
          );
          return false;
        }
        break;

      default:
        console.error(
          `Question ${questionIndex + 1} failed: Unsupported format '${
            q.format
          }'`
        );
        return false;
    }

    console.log(`Question ${index + 1} is valid.`);
    return true; // All checks passed for this question
  });

  console.log("Validation Result:", isValid);
  return isValid;
};

// =======================
// Validates bulk JSON with examples of a word
// =======================
export const validateWordExamples = (examples) => {
  if (!Array.isArray(examples)) {
    console.error("Invalid data format: Expected an array.");
    return false;
  }

  return examples.every((example, index) => {
    if (!example.sentence || typeof example.sentence !== "string") {
      console.error(
        `Example ${index + 1} failed: Missing or invalid 'sentence'`
      );
      return false;
    }

    if (example.translation && typeof example.translation !== "string") {
      console.error(`Example ${index + 1} failed: Invalid 'translation'`);
      return false;
    }

    if (example.audio_url && !isValidUrl(example.audio_url)) {
      console.error(`Example ${index + 1} failed: Invalid 'audio_url'`);
      return false;
    }

    if (example.video_url && !isValidUrl(example.video_url)) {
      console.error(`Example ${index + 1} failed: Invalid 'video_url'`);
      return false;
    }

    return true;
  });
};

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
