import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import {
  useDeleteExample,
  useExamplesByWord,
  useUpdateExample,
  useExamplesCountByWord,
} from "../../../../../../hooks/AdminDashboard/useWordExamples";

import { useQueryClient } from "@tanstack/react-query";

import SearchBar from "../../../../../Shared/SearchBar";
import { validateWordExamples } from "../../../../../../utils/validation";

const Examples = ({ selectedWord, categoryId, subcategoryId }) => {
  const queryClient = useQueryClient();

  const [searchTerm, setSearchTerm] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentExamples, setCurrentExamples] = useState("");
  const [uploadErrors, setUploadErrors] = useState([]);

  // Fetch examples based on search
  const { data, isFetching, refetch } = useExamplesByWord(
    categoryId,
    subcategoryId,
    selectedWord?.id,
    searchTerm
  );

  // Fetch example count
  const { data: examplesCount, isLoading: examplesCountLoading } =
    useExamplesCountByWord(categoryId, subcategoryId, selectedWord?.id);

  function handleSearch() {
    if (!selectedWord) {
      alert("Select a word, please!");
      return;
    }
    refetch();
  }

  function handleSetEditing() {
    if (!selectedWord) {
      alert("Select a word, please!");
      return;
    }
    if (!data || data.length !== 1) {
      alert("Please select exactly one example!");
      return;
    }
    setEditing(true);
    setCurrentExamples(JSON.stringify(data, null, 2));
  }

  // Update example mutation
  const updateExampleMutation = useUpdateExample(() => {
    alert("Example successfully updated!");
    setCurrentExamples("");
    // queryClient.invalidateQueries([
    //   "examples",
    //   categoryId,
    //   subcategoryId,
    //   selectedWord?.id,
    //   searchTerm,
    // ]);
    refetch();
  });

  const handleSave = () => {
    if (!data || data.length !== 1) {
      alert("Please select exactly one example!");
      return;
    }

    try {
      const parsedExamples = JSON.parse(currentExamples);
      if (!validateWordExamples(parsedExamples))
        throw new Error("Invalid example format.");

      updateExampleMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
        exampleId: data[0].id,
        exampleData: parsedExamples[0],
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  // Delete example mutation
  const deleteExampleMutation = useDeleteExample(() => {
    alert("Example successfully deleted!");
    setCurrentExamples("");
    refetch();
  });

  const handleDeleteExample = () => {
    if (!data || data.length !== 1) {
      alert("Please select exactly one example!");
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this example?"
    );

    if (isConfirmed) {
      deleteExampleMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
        exampleId: data[0].id,
      });
    }
  };

  return (
    <div>
      <h3 className="mb-4 text-center">{examplesCount?.count} Examples</h3>

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        isFetching={isFetching}
      />

      <MonacoEditor
        height="600px"
        language="json"
        readOnly={!editing}
        value={editing ? currentExamples : JSON.stringify(data, null, 2)}
        onChange={(value) => setCurrentExamples(value)}
        options={{ minimap: { enabled: false } }}
      />

      <div className="d-flex justify-content-end gap-2">
        {editing ? (
          <button
            className="btn btn-success mt-2"
            onClick={handleSave}
            disabled={updateExampleMutation.isPending}
          >
            Save
          </button>
        ) : (
          <button
            disabled={updateExampleMutation.isPending}
            className="btn btn-warning mt-2"
            onClick={handleSetEditing}
          >
            {updateExampleMutation.isPending ? "Saving... " : "Edit"}
          </button>
        )}
        <button
          disabled={deleteExampleMutation.isPending}
          className="btn btn-danger mt-2"
          onClick={handleDeleteExample}
        >
          {deleteExampleMutation.isPending ? "Deleting... " : "Delete"}
        </button>
      </div>

      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Examples;
