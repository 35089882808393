import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";

// Hooks
import {
  useGrammarQuestions,
  useGrammarQuestionsCount,
  useUpdateGrammarQuestion,
  useBulkUploadGrammarQuestions,
  useDeleteGrammarQuestion,
} from "../../../../../../hooks/AdminDashboard/useGrammarContent";

// Components
import QuestionEditor from "./QuestionEditor";
import BulkUploader from "./BulkUploader";
import SearchBar from "../../../../../Shared/SearchBar";

// Utilities
import { validateQuestions } from "../../../../../../utils/validation";

const GrammarContent = ({ categoryId, subcategoryId }) => {
  const queryClient = useQueryClient();

  // States
  const [searchTerm, setSearchTerm] = useState("");
  const [jsonData, setJsonData] = useState(sampleQuestions);
  const [currentQuestions, setCurrentQuestions] = useState("");
  const [editing, setEditing] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);

  // Queries
  const grammarQuestionsCount = useGrammarQuestionsCount(
    categoryId,
    subcategoryId,
    searchTerm
  );
  const { data, isFetching, refetch } = useGrammarQuestions(
    categoryId,
    subcategoryId,
    searchTerm
  );

  // Mutations
  const updateGrammarQuestionMutation = useUpdateGrammarQuestion(
    () => {
      queryClient.invalidateQueries([
        "grammarQuestions",
        categoryId,
        subcategoryId,
      ]);
      setUploadErrors([]);
      alert("Updated successfully!");
      setEditing(false);
    },
    (err) => handleErrors(err)
  );

  const bulkUploadMutation = useBulkUploadGrammarQuestions(
    () => {
      queryClient.invalidateQueries([
        "grammarQuestions",
        categoryId,
        subcategoryId,
      ]);
      setJsonData("");
      setUploadErrors([]);
    },
    (err) => handleErrors(err)
  );

  const deleteGrammarQuestionMutation = useDeleteGrammarQuestion(() =>
    queryClient.invalidateQueries([
      "grammarQuestions",
      categoryId,
      subcategoryId,
    ])
  );

  // Error Handling
  const handleErrors = (err) => {
    const errors = err.response?.data?.errors || [
      { row: "N/A", errors: ["Unknown error"] },
    ];
    setUploadErrors(errors);
  };

  // Handlers
  const handleSearch = () => refetch();

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one question!");
      return;
    }
    try {
      const questions = JSON.parse(jsonData);
      if (!validateQuestions(questions))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({ categoryId, subcategoryId, questions });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  const handleUpdateQuestion = () => {
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }

    try {
      const parsedQuestions = JSON.parse(currentQuestions);
      if (!validateQuestions(parsedQuestions))
        throw new Error("Invalid question format.");
      console.log(parsedQuestions);
      updateGrammarQuestionMutation.mutate({
        categoryId,
        subcategoryId,
        questionId: data[0].id,
        questionData: parsedQuestions[0],
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  const handleDeleteQuestion = () => {
    // Validate that one question is selected
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }

    // Confirm deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this question?"
    );

    // Proceed only if confirmed
    if (isConfirmed) {
      deleteGrammarQuestionMutation.mutate({
        categoryId,
        subcategoryId,
        questionId: data[0].id,
      });
    }
  };

  return (
    <div className="container py-4" style={{ backgroundColor: "#FFFFFF" }}>
      <h3 className="mb-4 text-center">
        {grammarQuestionsCount.data?.count} Grammar Questions
      </h3>

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        isFetching={isFetching}
      />

      <QuestionEditor
        editing={editing}
        setEditing={setEditing}
        currentQuestions={currentQuestions}
        setCurrentQuestions={setCurrentQuestions}
        handleUpdateQuestion={handleUpdateQuestion}
        data={data}
        handleDeleteQuestion={handleDeleteQuestion}
        deleting={deleteGrammarQuestionMutation.isPending}
        updating={updateGrammarQuestionMutation.isPending}
      />

      <BulkUploader
        jsonData={jsonData}
        setJsonData={setJsonData}
        handleJsonUpload={handleJsonUpload}
        uploadErrors={uploadErrors}
        uploading={bulkUploadMutation.isPending}
      />
    </div>
  );
};

export default GrammarContent;

// Define the sample questions as a string
const sampleQuestions = `[
  {
    "format": "MCQ",
    "text": "What is the capital of France?",
    "choices": ["Paris", "London", "Berlin", "Rome"],
    "correct_answer": ["Paris"],
    "feedback": "Paris is the capital of France."
  },
  {
    "format": "GAP_FILL",
    "text": "The ___ is the largest ocean.",
    "choices": null,
    "correct_answer": ["Pacific"],
    "feedback": "The Pacific Ocean is the largest ocean."
  },
  {
    "format": "MATCHING",
    "text": "Match the countries with their capitals.",
    "choices": [
      {"country": "France", "capital": "Paris"},
      {"country": "Germany", "capital": "Berlin"},
      {"country": "Italy", "capital": "Rome"}
    ],
    "correct_answer": [
      {"country": "France", "capital": "Paris"},
      {"country": "Germany", "capital": "Berlin"},
      {"country": "Italy", "capital": "Rome"}
    ],
    "feedback": "Match each country to its correct capital."
  },
  {
    "format": "MULTI_SELECT",
    "text": "Select all the prime numbers.",
    "choices": ["2", "3", "4", "5", "6"],
    "correct_answer": ["2", "3", "5"],
    "feedback": "2, 3, and 5 are prime numbers."
  }
]`;
