const createSubmissionPayload = (selectedAnswers, type, attempt_id, testId) => {
  const formattedAnswers = selectedAnswers.map((answer) => ({
    id: answer.questionId,
    selectedAnswer: answer.answer,
  }));
  return {
    skill: type,
    answers: formattedAnswers,
    attempt_id: attempt_id,
    testId: testId,
    time_spent: 10,
  };
};

export default createSubmissionPayload;
