import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import {
  useDeleteQuestion,
  useQuestionsByWord,
  useUpdateQuestion,
  useQuestionsCountByWord,
} from "../../../../../../hooks/AdminDashboard/useWords";
import SearchBar from "../../../../../Shared/SearchBar";
import { validateWordQuestions } from "../../../../../../utils/validation";

const Questions = ({ selectedWord, categoryId, subcategoryId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentQuestions, setCurrentQuestions] = useState("");
  const [uploadErrors, setUploadErrors] = useState([]);

  const { data, isFetching, refetch } = useQuestionsByWord(
    categoryId,
    subcategoryId,
    selectedWord?.id,
    searchTerm
  );

  const { data: questionsCount, isLoading: questionsCountLoading } =
    useQuestionsCountByWord(
      categoryId,
      subcategoryId,
      selectedWord?.id,
      searchTerm
    );

  function handleSearch() {
    if (!selectedWord) {
      alert("Select a word plz!");
      return;
    }
    refetch();
  }

  function handleSetEditing() {
    if (!selectedWord) {
      alert("Select a word plz!");
      return;
    }
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }
    setEditing(true);
    setCurrentQuestions(JSON.stringify(data, null, 2));
  }

  const updateQuestionMutation = useUpdateQuestion(() => {
    alert("Question successfully updated!");
    setCurrentQuestions("");
  });

  const handleSave = () => {
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }

    try {
      const parsedQuestions = JSON.parse(currentQuestions);
      if (!validateWordQuestions(parsedQuestions))
        throw new Error("Invalid question format.");
      console.log(parsedQuestions);
      updateQuestionMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
        questionId: data[0].id,
        questionData: parsedQuestions[0],
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  const deleteQuestionMutation = useDeleteQuestion(() => {
    alert("Question successfully deleted!");
    setCurrentQuestions("");
  });

  const handleDeleteQuestion = () => {
    // Validate that one question is selected
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }

    // Confirm deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this question?"
    );

    // Proceed only if confirmed
    if (isConfirmed) {
      deleteQuestionMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
        questionId: data[0].id,
      });
    }
  };

  return (
    <div>
      <>
        <h3 className="mb-4 text-center">{questionsCount?.count} Questions</h3>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          isFetching={isFetching}
        />

        <MonacoEditor
          height="600px"
          language="json"
          readOnly={!editing}
          value={editing ? currentQuestions : JSON.stringify(data, null, 2)}
          onChange={(value) => setCurrentQuestions(value)}
          options={{ minimap: { enabled: false } }}
        />

        <div className="d-flex justify-content-end gap-2">
          {editing ? (
            <button
              className="btn btn-success mt-2"
              onClick={handleSave}
              disabled={updateQuestionMutation.isPending}
            >
              Save
            </button>
          ) : (
            <button
              disabled={updateQuestionMutation.isPending}
              className="btn btn-warning mt-2"
              onClick={handleSetEditing}
            >
              {updateQuestionMutation.isPending ? "saving... " : "edit"}
            </button>
          )}
          <button
            disabled={deleteQuestionMutation.isPending}
            className="btn btn-danger mt-2"
            onClick={handleDeleteQuestion}
          >
            {deleteQuestionMutation.isPending ? "deleting... " : "delete"}
          </button>
        </div>
        {uploadErrors.length > 0 && (
          <div className="alert alert-danger mt-3">
            <h5>Errors:</h5>
            <ul>
              {uploadErrors.map((err, index) => (
                <li key={index}>
                  Row {err.row}: {err.errors.join(", ")}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    </div>
  );
};

export default Questions;
