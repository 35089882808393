import React from "react";
import Collapsible from "./Collapsible";

const AssignmentModal = ({ assignmentData, onClose }) => {
  if (!assignmentData) return null;

  const {
    grammar,
    vocabulary,
    reading,
    listening,
    writing_responses,
    vocabulary_translation,
  } = assignmentData;

  const getWritingDetails = (writingResponses) => {
    if (!writingResponses) return [];
    return writingResponses.map((test) => {
      let sum = 0;
      let count = 0;
      let totalTime = 0;
      let totalQuestions = 0;

      test.tasks.forEach((task) => {
        if (task.score !== undefined && task.score !== null) {
          sum += parseFloat(task.score);
          count++;
        }
        totalQuestions++;
        totalTime += task.time_spent
          ? task.time_spent
              .split(":")
              .reduce((acc, time) => 60 * acc + +time, 0)
          : 0;
      });

      const testAverage = count > 0 ? (sum / count).toFixed(2) : "N/A";

      return {
        testId: test.test_id,
        testName: test.name,
        testDesc: test.description,
        testAverage,
        totalQuestions,
        totalTime,
        tasks: test.tasks,
      };
    });
  };

  const writingDetails = getWritingDetails(writing_responses);

  return (
    <div
      className="modal fade show d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Skill</th>
                  <th>Score</th>
                  <th>Total Questions</th>
                  <th>Time Spent</th>
                </tr>
              </thead>
              <tbody>
                {grammar?.score && (
                  <tr>
                    <td>Grammar</td>
                    <td>{grammar?.score ?? "N/A"}</td>
                    <td>{grammar?.total_questions ?? "N/A"}</td>
                    <td>{grammar?.time_spent ?? "N/A"}</td>
                  </tr>
                )}

                {vocabulary?.score && (
                  <tr>
                    <td>Vocabulary</td>
                    <td>{vocabulary?.score ?? "N/A"}</td>
                    <td>{vocabulary?.total_questions ?? "N/A"}</td>
                    <td>{vocabulary?.time_spent ?? "N/A"}</td>
                  </tr>
                )}

                {vocabulary_translation?.score && (
                  <tr>
                    <td>Vocabulary Translation</td>
                    <td>{vocabulary_translation?.score ?? "N/A"}</td>
                    <td>{vocabulary_translation?.total_questions ?? "N/A"}</td>
                    <td>{vocabulary_translation?.time_spent ?? "N/A"}</td>
                  </tr>
                )}

                {Array.isArray(reading) &&
                  reading.map((r, index) => (
                    <tr key={`reading-${index}`}>
                      <td>Reading Test #{r.test_id}</td>
                      <td>{r.score}</td>
                      <td>{r.total_questions}</td>
                      <td>{r.time_spent}</td>
                    </tr>
                  ))}
                {Array.isArray(listening) &&
                  listening.map((l, index) => (
                    <tr key={`listening-${index}`}>
                      <td>Listening Test #{l.test_id}</td>
                      <td>{l.score}</td>
                      <td>{l.total_questions}</td>
                      <td>{l.time_spent}</td>
                    </tr>
                  ))}
                {writingDetails.map((wd, i) => (
                  <React.Fragment key={`test-${i}`}>
                    <tr>
                      <td colSpan="4">
                        <Collapsible
                          title={`${wd.testName} - ${wd.testAverage} % on average`}
                        >
                          <p>{wd.testDesc}</p>
                          {wd.tasks.map((task) => (
                            <Collapsible
                              key={`task-${task.task_id}`}
                              title={`Task ${task.task_number} - ${task.score}%  - ${task.time_spent} spent`}
                            >
                              <p>
                                <strong>Prompt:</strong> <br /> {task.prompt}
                              </p>
                              <p>
                                <strong>Your Response: </strong> <br />
                                {task.response_text}
                              </p>
                              <p>
                                <strong>Feedback:</strong>
                                <br /> {task.feedback}
                              </p>
                            </Collapsible>
                          ))}
                        </Collapsible>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentModal;
