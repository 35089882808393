import { React } from "react";

const CategoryList = ({ categories, onSelectCategory }) => {
  return (
    <div>
      <ul className="list-group">
        {categories?.map((category) => (
          <li
            key={category.id}
            className="list-group-item d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center">
              <span onClick={() => onSelectCategory(category.id)}>
                {category.name} | {category.description}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
