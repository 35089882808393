// Single function to construct today's date + given time in "YYYY-MM-DDTHH:MM" format
const getTodayDateTimeString = (rawTimeString) => {
  // 1) Construct today's date
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");

  // 2) Slice the raw time string to get "HH:MM" (first 5 characters of "8:00:00" -> "8:00")
  //    If no time is provided, default to "00:00".
  const timePart = rawTimeString ? rawTimeString.slice(0, 5) : "00:00";

  // 3) Return combined date + time in datetime-local format
  //    e.g., "2025-01-14T08:00"
  return `${yyyy}-${mm}-${dd}T${timePart}`;
};

export default getTodayDateTimeString;
