import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  bulkUploadReadingTests,
  bulkUpdateReadingTest,
  fetchSimpleReadingTests,
  fetchReadingTestById,
  deleteReadingTest,
} from "../../api/adminAPI/readingAPI";

export const useBulkUploadReadingTests = (subcategoryId, onSuccess) => {
  return useMutation({
    mutationFn: (data) => bulkUploadReadingTests(subcategoryId, data),
    onSuccess,
  });
};

export const useBulkUpdateReadingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ data, testId, subcategoryId }) =>
      bulkUpdateReadingTest(subcategoryId, testId, data),
    onSuccess,
  });
};

export const useFetchSimpleReadingTests = (subcategoryId) =>
  useQuery({
    queryKey: ["simpleReadingTests", subcategoryId],
    queryFn: () => fetchSimpleReadingTests(subcategoryId),
    enabled: !!subcategoryId, // Fetch only when subcategoryId is available
  });

export const useFetchReadingTestById = (subcategoryId, testId) =>
  useQuery({
    queryKey: ["readingTest", subcategoryId, testId],
    queryFn: () => fetchReadingTestById(subcategoryId, testId),
    enabled: !!subcategoryId && !!testId, // Fetch only when both IDs are available
  });

export const useDeleteReadingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ testId, subcategoryId }) =>
      deleteReadingTest(subcategoryId, testId),
    onSuccess,
  });
};
