import React from "react";

const CueCard = ({ cueCard }) => {
  return (
    <div className="cue-card border p-3 bg-light">
      <h5 className="text-primary">{cueCard.topic}</h5>
      <p className="mb-2">
        <strong>Prompt:</strong> {cueCard.prompt}
      </p>
      <p>
        <strong>Hints:</strong> {cueCard.hints}
      </p>
    </div>
  );
};

export default CueCard;
