import React, { useState } from "react";
import LoadingAnimation from "./../../../../../../utils/LoadingAnimation";
import { useAdminAllProgress } from "../../../../../../hooks/Progress/useProgress";
import AssignmentModal from "./AssignmentModel";

const ProgressList = ({ selectedAssignmentId }) => {
  const { data: progressData, isLoading } = useAdminAllProgress(
    1,
    35,
    selectedAssignmentId
  );

  const [selectedProgress, setSelectedProgress] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [visibleColumns, setVisibleColumns] = useState({
    grammar: true,
    vocabulary: true,
    vocabulary_translation: true,
    listening: true,
    reading: true,
    writing: true,
    average: true,
  });

  const handleRowClick = (progress) => {
    setSelectedProgress(progress);
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  const calculateAverage = (scoresArray) => {
    if (!scoresArray || scoresArray.length === 0) return 0;

    // Convert any string scores to numbers and replace "N/A" with 0
    const validScores = scoresArray.map((score) => {
      if (typeof score === "string") {
        return score === "N/A" ? 0 : parseFloat(score);
      }
      return score ?? 0;
    });

    // Sum all scores (including zero) and divide by total count
    const total = validScores.reduce((sum, score) => sum + score, 0);

    return (total / validScores.length).toFixed(0) || "N/A"; // Return rounded average
  };

  const getRowAverage = (entry) => {
    const scores = [];

    if (visibleColumns.grammar) {
      scores.push(entry.grammar?.score !== undefined ? entry.grammar.score : 0);
    }
    if (visibleColumns.vocabulary) {
      scores.push(
        entry.vocabulary?.score !== undefined ? entry.vocabulary.score : 0
      );
    }
    if (visibleColumns.vocabulary_translation) {
      scores.push(
        entry.vocabulary_translation?.score !== undefined
          ? entry.vocabulary_translation.score
          : 0
      );
    }

    if (visibleColumns.listening) {
      if (entry.listening?.length) {
        scores.push(...entry.listening.map((item) => item.score));
      } else {
        scores.push(0); // Add zero if no listening scores exist
      }
    }

    if (visibleColumns.reading) {
      if (entry.reading?.length) {
        scores.push(...entry.reading.map((item) => item.score));
      } else {
        scores.push(0); // Add zero if no reading scores exist
      }
    }

    if (visibleColumns.writing) {
      if (entry.writing_responses?.length) {
        entry.writing_responses.forEach((test) => {
          test.tasks.forEach((task) => {
            scores.push(task.score !== undefined ? task.score : 0);
          });
        });
      } else {
        scores.push(0); // Add zero if no writing responses exist
      }
    }

    return calculateAverage(scores);
  };

  const sortedProgressData = [...(progressData?.results || [])].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const valueA =
      sortConfig.key === "average"
        ? getRowAverage(a)
        : a[sortConfig.key]?.score || 0;
    const valueB =
      sortConfig.key === "average"
        ? getRowAverage(b)
        : b[sortConfig.key]?.score || 0;

    if (sortConfig.direction === "asc") {
      return valueA - valueB;
    } else {
      return valueB - valueA;
    }
  });

  const toggleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <div>
      <h2>Progress Data</h2>

      {/* Column Visibility Controls */}
      <div style={{ marginBottom: "10px" }}>
        {Object.keys(visibleColumns).map((column) => (
          <label
            key={column}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <input
              type="checkbox"
              checked={visibleColumns[column]}
              onChange={() =>
                setVisibleColumns((prev) => ({
                  ...prev,
                  [column]: !prev[column],
                }))
              }
              style={{ cursor: "pointer" }} // Makes the checkbox itself clickable
            />{" "}
            {column.charAt(0).toUpperCase() + column.slice(1)}
          </label>
        ))}
      </div>

      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid black",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>#</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Name</th>
            {visibleColumns.grammar && (
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleSort("grammar")}
              >
                Grammar{" "}
                {sortConfig.key === "grammar"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </th>
            )}
            {visibleColumns.vocabulary && (
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleSort("vocabulary")}
              >
                Vocabulary{" "}
                {sortConfig.key === "vocabulary"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </th>
            )}
            {visibleColumns.vocabulary_translation && (
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleSort("vocabulary_translation")}
              >
                V.T.{" "}
                {sortConfig.key === "vocabulary_translation"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </th>
            )}
            {visibleColumns.listening && (
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Listening Avg
              </th>
            )}
            {visibleColumns.reading && (
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Reading Avg
              </th>
            )}
            {visibleColumns.writing && (
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Writing Avg
              </th>
            )}
            {visibleColumns.average && (
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleSort("average")}
              >
                Average Score{" "}
                {sortConfig.key === "average"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedProgressData.map((entry, index) => {
            const listeningScores =
              entry.listening?.map((item) => item.score) || [];
            const readingScores =
              entry.reading?.map((item) => item.score) || [];
            const writingScores =
              entry.writing_responses?.flatMap((test) =>
                test.tasks.map((task) => task.score)
              ) || [];
            const averageScore = getRowAverage(entry);

            return (
              <tr
                key={index}
                onClick={() => handleRowClick(entry)}
                style={{ cursor: "pointer" }}
              >
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {entry.student.first_name} {entry.student.last_name}
                </td>
                {visibleColumns.grammar && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {entry.grammar?.score ?? "N/A"}
                  </td>
                )}
                {visibleColumns.vocabulary && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {entry.vocabulary?.score ?? "N/A"}
                  </td>
                )}
                {visibleColumns.vocabulary_translation && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {entry.vocabulary_translation?.score ?? "N/A"}
                  </td>
                )}
                {visibleColumns.listening && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {calculateAverage(listeningScores)}
                  </td>
                )}
                {visibleColumns.reading && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {calculateAverage(readingScores)}
                  </td>
                )}
                {visibleColumns.writing && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {calculateAverage(writingScores)}
                  </td>
                )}
                {visibleColumns.average && (
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {averageScore}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {selectedProgress && (
        <AssignmentModal
          assignmentData={selectedProgress}
          onClose={() => setSelectedProgress(null)}
        />
      )}
    </div>
  );
};

export default ProgressList;
