const SearchBar = ({ searchTerm, setSearchTerm, handleSearch, isFetching }) => (
  <div className="mb-3">
    <input
      type="text"
      placeholder="Search questions..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="form-control"
    />
    <div className="text-end mt-2">
      <button
        disabled={isFetching}
        className="btn btn-success"
        onClick={handleSearch}
      >
        {isFetching ? "Searching..." : "Search"}
      </button>
    </div>
  </div>
);

export default SearchBar;
