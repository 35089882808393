import { useQuery, useMutation } from "@tanstack/react-query";

import {
  fetchExamples,
  bulkUploadExamples,
  updateExample,
  deleteExample,
  fetchExamplesCount,
} from "../../api/adminAPI/wordExampleAPI"; // Import your API functions

// **Fetch Examples**
export const useExamplesByWord = (categoryId, subcategoryId, wordId, search) =>
  useQuery({
    queryKey: ["examples", categoryId, subcategoryId, wordId, search],
    queryFn: () => fetchExamples({ categoryId, subcategoryId, wordId, search }),
    enabled: false, // Prevent execution if wordId is undefined
  });

// **Bulk Upload Examples**
export const useBulkUploadExamples = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, examplesData }) =>
      bulkUploadExamples({
        categoryId,
        subcategoryId,
        wordId,
        examplesData,
      }),
    onSuccess,
  });

// **Update an Example**
export const useUpdateExample = (onSuccess) =>
  useMutation({
    mutationFn: ({
      categoryId,
      subcategoryId,
      wordId,
      exampleId,
      exampleData,
    }) =>
      updateExample({
        categoryId,
        subcategoryId,
        wordId,
        exampleId,
        exampleData,
      }),
    onSuccess,
  });

// **Delete an Example**
export const useDeleteExample = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, exampleId }) =>
      deleteExample({ categoryId, subcategoryId, wordId, exampleId }),
    onSuccess,
  });

export const useExamplesCountByWord = (categoryId, subcategoryId, wordId) =>
  useQuery({
    queryKey: ["examplesCount", categoryId, subcategoryId, wordId],
    queryFn: () => fetchExamplesCount({ categoryId, subcategoryId, wordId }),
    enabled: !!wordId, // Prevents execution if wordId is undefined
  });
