import { React } from "react";
import GrammarVocabulary from "../GrammarVocabulary/GrammarVocabulary";
import ReadingTest from "../ReadingTest/ReadingTest";
import WritingTest from "../WritingTest/WritingTest";
import ListeningTest from "../ListeningTest/ListeningTest";
import SpeakingTest from "../SpeakingTest/SpeakingTest";

const TestDoDisplay = ({ selectedTest, assignmentId, attempt_id }) => {
  const renderSection = () => {
    switch (selectedTest.type) {
      case "grammar":
        return (
          <GrammarVocabulary type={"grammar"} assignmentId={assignmentId} />
        );
      case "vocabulary":
        return (
          <GrammarVocabulary type={"vocabulary"} assignmentId={assignmentId} />
        );

      case "vocabulary_translation":
        return (
          <GrammarVocabulary
            type={"vocabulary_translation"}
            assignmentId={assignmentId}
          />
        );
      case "reading":
        return (
          <ReadingTest
            testId={selectedTest.id}
            assignmentId={assignmentId}
            // attempt_id={attempt_id}
          />
        );
      case "listening":
        return (
          <ListeningTest testId={selectedTest.id} assignmentId={assignmentId} />
        );
      case "speaking":
        return (
          <SpeakingTest
            selectedTest={selectedTest}
            assignmentId={assignmentId}
            attempt_id={attempt_id}
          />
        );
      case "writing":
        return (
          <WritingTest testId={selectedTest.id} assignmentId={assignmentId} />
        );
      default:
        return null;
    }
  };
  return <div>{renderSection()}</div>;
};

export default TestDoDisplay;
