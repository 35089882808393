import api from "../api";

export const saveProgress = async (assignmentId, progressData) => {
  try {
    const response = await api.post(
      `/api/assignments/assignments/${assignmentId}/save-progress/`,
      progressData // Progress data to save
    );

    return response.data; // Returns the server response, including the updated progress
  } catch (error) {
    console.error("Error saving progress:", error);
    throw error; // Propagate error for further handling
  }
};

export const getStudentProgress = async (page = 1) => {
  try {
    const response = await api.get(`/api/progress/progress/?page=${page}`);
    return response.data; // Returns paginated progress data
  } catch (error) {
    console.error("Error retrieving student progress:", error);
    throw error;
  }
};

//====================================================================
// Admin
//====================================================================

export const getAllProgress = async (
  page = 1,
  page_size = 20,
  assignment_id
) => {
  try {
    const response = await api.get(
      `/api/progress/progress/?assignment_id=${assignment_id}&page=${page}&page_size=${page_size}`
    );

    return response.data; // Returns all progress data with pagination
  } catch (error) {
    console.error("Error retrieving all progress for admin:", error);
    throw error;
  }
};

export const getProgressById = async (progressId) => {
  try {
    const response = await api.get(`/api/progress/progress/${progressId}/`);
    return response.data; // Returns the specific progress entry
  } catch (error) {
    console.error("Error retrieving progress by ID:", error);
    throw error;
  }
};

export const updateProgress = async (progressId, updatedData) => {
  try {
    const response = await api.put(
      `/api/progress/progress/${progressId}/`,
      updatedData
    );
    return response.data; // Returns the updated progress entry
  } catch (error) {
    console.error("Error updating progress:", error);
    throw error;
  }
};

export const deleteProgress = async (progressId) => {
  try {
    const response = await api.delete(`/api/progress/progress/${progressId}/`);
    return response.data; // Confirms deletion
  } catch (error) {
    console.error("Error deleting progress:", error);
    throw error;
  }
};
