import api from "../api";

export const fetchSimpleSpeakingTests = async (subcategoryId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/speaking-tests/`
  );
  return response.data;
};

export const fetchSpeakingTestById = async (subcategoryId, testId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/speaking-tests/${testId}/`
  );
  return response.data;
};

export const bulkUploadSpeakingTests = async (subcategoryId, data) => {
  console.log(" data ===== ", data); // Optional: Log data for debugging
  const response = await api.post(
    `/api/admin/subcategory/${subcategoryId}/speaking-tests/bulk_create/`,
    data
  );
  return response.data;
};

export const bulkUpdateSpeakingTest = async (subcategoryId, testId, data) => {
  const response = await api.put(
    `/api/admin/subcategory/${subcategoryId}/speaking-tests/${testId}/update_test/`,
    data
  );
  return response.data;
};

export const deleteSpeakingTest = async (subcategoryId, testId) => {
  const response = await api.delete(
    `/api/admin/subcategory/${subcategoryId}/speaking-tests/${testId}/`
  );
  return response.data;
};
