import React, { useState } from "react";

const MultiSelect = ({
  questionNumber,
  questionData,
  answer,
  isSubmitted,
  handleAnswerSelect,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState(
    answer?.selectedAnswer || []
  );

  const handleCheckboxChange = (choice) => {
    const updatedAnswers = selectedAnswers.includes(choice)
      ? selectedAnswers.filter((answer) => answer !== choice)
      : [...selectedAnswers, choice];

    setSelectedAnswers(updatedAnswers);
    handleAnswerSelect(questionData.id, updatedAnswers); // Pass the updated array to the parent
  };

  return (
    <div className="mb-4 border rounded shadow-sm p-3">
      <p className="fw-semibold">
        {questionNumber}. {questionData.text}
      </p>
      <ul className="list-group">
        {questionData.choices.map((choice, idx) => {
          const isSelected = selectedAnswers.includes(choice);

          return (
            <li
              key={idx}
              className={`list-group-item shadow-sm mb-2 list-group-item-action ${
                isSubmitted ? "disabled" : ""
              }`}
            >
              <label
                className="form-check-label w-100 "
                style={{ cursor: "pointer" }}
              >
                <input
                  id={`multi-${questionData.id}-${idx}`} // Unique id for each choice
                  name={`multi-question-${questionData.id}`} // Unique name per question
                  type="checkbox"
                  className="me-2"
                  value={choice}
                  onChange={() => handleCheckboxChange(choice)}
                  disabled={isSubmitted}
                  checked={isSelected} // Ensure checked state matches the selection
                  style={{ cursor: "pointer" }}
                />
                {choice}
              </label>
            </li>
          );
        })}
      </ul>

      {/* Display Correct Answers if Submission is Incorrect */}
      {isSubmitted && !answer?.is_correct && (
        <>
          <p className="mt-2 text-success">
            <strong>Correct Answers:</strong>{" "}
            {questionData.correct_answer?.join(", ")}
          </p>
          <p className="mt-3 text-danger">
            <strong>Feedback:</strong> <em>{questionData.feedback}</em>
          </p>
        </>
      )}
    </div>
  );
};

export default MultiSelect;
