import React from "react";

const Header = ({ partInfo }) => {
  return (
    <div className="bg-light py-1 px-2 border-bottom">
      <h4 className="m-1">Part {partInfo.part_number}</h4>
      <p className="text-muted m-1">{partInfo.instructions}</p>
    </div>
  );
};

export default Header;
