const highlightWordInExample = (example, word) => {
  // Case-insensitive regex to match the word
  const regex = new RegExp(`(${word})`, "gi");

  // Split the example into parts using the regex
  const parts = example.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <strong key={index}>{part}</strong> // Bold the matched word
        ) : (
          part // Render non-matched parts as-is
        )
      )}
    </>
  );
};

export default highlightWordInExample;
