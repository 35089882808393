import React, { useState } from "react";
import { BsFillMicFill } from "react-icons/bs";

const Questions = ({ questions }) => {
  const [transcriptions, setTranscriptions] = useState({}); // Store transcriptions for each question
  const [isRecording, setIsRecording] = useState(false);

  const handleRecording = (questionId) => {
    if (
      !("webkitSpeechRecognition" in window || "SpeechRecognition" in window)
    ) {
      alert(
        "Speech recognition is not supported in your browser. Please use Chrome."
      );
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.lang = "en-US"; // Set language
    recognition.interimResults = false; // Use final results
    recognition.continuous = false; // Single response mode

    recognition.start(); // Start speech recognition

    setIsRecording(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;

      // Update transcriptions state
      setTranscriptions((prev) => ({
        ...prev,
        [questionId]: transcript,
      }));

      setIsRecording(false);
    };

    recognition.onerror = (err) => {
      console.error("Speech recognition error:", err);
      setIsRecording(false);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };
  };

  return (
    <div className="questions">
      {questions
        .sort((a, b) => a.order - b.order) // Sort questions by order
        .map((question) => (
          <div key={question.id} className="mb-4">
            <p>
              <strong>Q{question.order}:</strong> {question.question_text}
            </p>
            {/* <button
              className={`btn btn-${
                isRecording ? "danger" : "primary"
              } d-flex align-items-center`}
              onClick={() => handleRecording(question.id)}
              disabled={isRecording} // Disable button while recording
            >
              <BsFillMicFill className="me-2" />
              {isRecording ? "Recording..." : "Record Response"}
            </button> */}
            {/* {transcriptions[question.id] && (
              <p className="mt-2">
                <strong>Transcription:</strong> {transcriptions[question.id]}
              </p>
            )} */}
          </div>
        ))}
    </div>
  );
};

export default Questions;
