import React from "react";

const PayloadPreview = ({ payload, fullGroupData }) => {
  // Destructure payload data
  const {
    type,
    assignedTo,
    assigned_to_students = [],
    deadline,
    start_time,
    time_limit,
    description,
    grammar = [],
    vocabulary = [],
    reading,
    listening,
    speaking,
    writing,
  } = payload;

  // Find selected student names based on IDs
  const selectedStudentNames =
    assignedTo === "Individual"
      ? fullGroupData?.students
          .filter((student) => assigned_to_students.includes(student.id))
          .map((student) => student.first_name) || []
      : [];

  // Helper function to format test settings dynamically
  const renderTestSettings = (label, testData) => {
    if (!testData) return null; // Skip if testData is missing
    const testType = testData.random ? "Random" : "Specific";
    const testValue = testData.random
      ? testData.quantity
      : testData.specificTests?.join(", ") || "No Tests Selected";

    return (
      <li className="list-group-item">
        <strong>{label}:</strong> {testValue} ({testType})
      </li>
    );
  };

  return (
    <div className="card mt-3 shadow-sm">
      <div className="card-header bg-primary text-white">
        <h5 className="mb-0">Assignment Preview</h5>
      </div>
      <div className="card-body bg-light">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <strong>Assignment Type:</strong> {type || "Not Set"}
          </li>
          <li className="list-group-item">
            <strong>Assigned To:</strong> {assignedTo}
          </li>
          {assignedTo === "Individual" && (
            <li className="list-group-item">
              <strong>Students:</strong>{" "}
              {selectedStudentNames.length > 0
                ? selectedStudentNames.join(", ")
                : "No Students Selected"}
            </li>
          )}
          <li className="list-group-item">
            <strong>Deadline:</strong>{" "}
            {deadline ? new Date(deadline).toLocaleString() : "Not Set"}
          </li>
          <li className="list-group-item">
            <strong>Start Time:</strong>{" "}
            {start_time ? new Date(start_time).toLocaleString() : "Not Set"}
          </li>
          <li className="list-group-item">
            <strong>Time Limit:</strong>{" "}
            {time_limit ? `${time_limit} mins` : "No Limit"}
          </li>
          <li className="list-group-item">
            <strong>Description:</strong> {description || "No Description"}
          </li>
        </ul>

        {/* Grammar Settings */}
        {grammar.length > 0 && (
          <>
            <h6 className="mt-3">Grammar Settings:</h6>
            <ul className="list-group mb-3">
              {grammar.map((item, index) => (
                <li key={index} className="list-group-item">
                  <strong>{item.format}:</strong> {item.quantity}
                </li>
              ))}
            </ul>
          </>
        )}

        {/* Vocabulary Settings */}
        {vocabulary.length > 0 && (
          <>
            <h6>Vocabulary Settings:</h6>
            <ul className="list-group mb-3">
              {vocabulary.map((item, index) => (
                <li key={index} className="list-group-item">
                  <strong>{item.format}:</strong> {item.quantity}
                </li>
              ))}
            </ul>
          </>
        )}

        {/* Test Settings */}
        <h6>Test Settings:</h6>
        <ul className="list-group">
          {renderTestSettings("Reading", reading)}
          {renderTestSettings("Listening", listening)}
          {renderTestSettings("Speaking", speaking)}
          {renderTestSettings("Writing", writing)}
        </ul>
      </div>
    </div>
  );
};

export default PayloadPreview;
