import React from "react";

const Books = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
        color: "#333",
        textAlign: "center",
      }}
    >
      <img
        src="https://cdn-icons-png.flaticon.com/512/2132/2132336.png"
        alt="Under Construction Icon"
        style={{ width: "150px", marginBottom: "20px" }}
      />
      <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
        This section is under construction 🚧
      </h1>
      <p style={{ fontSize: "16px", color: "#666" }}>
        We're working hard to bring this feature to you soon!
      </p>
    </div>
  );
};

export default Books;
