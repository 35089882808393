import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchSimpleWritingTests,
  fetchWritingTestById,
  bulkUploadWritingTests,
  bulkUpdateWritingTest,
  deleteWritingTest,
} from "../../api/adminAPI/writingAPI";

// =============================
// 1. Fetch Simple Tests List
// =============================
export const useFetchSimpleWritingTests = (subcategoryId) =>
  useQuery({
    queryKey: ["simpleWritingTests", subcategoryId],
    queryFn: () => fetchSimpleWritingTests(subcategoryId),
    enabled: !!subcategoryId, // Ensure subcategoryId is present before fetching
  });

// =============================
// 2. Fetch Detailed Test
// =============================
export const useFetchWritingTestById = (subcategoryId, testId) =>
  useQuery({
    queryKey: ["detailedWritingTest", subcategoryId, testId],
    queryFn: () => fetchWritingTestById(subcategoryId, testId),
    enabled: !!subcategoryId && !!testId, // Ensure both IDs are present
  });

// =============================
// 3. Bulk Upload Tests
// =============================
export const useBulkUploadWritingTests = (subcategoryId) => {
  const queryClient = useQueryClient(); // React Query cache management

  return useMutation({
    mutationFn: (data) => bulkUploadWritingTests(subcategoryId, data),
    onSuccess: () => {
      // Invalidate cache to refresh the tests list
      queryClient.invalidateQueries(["simpleWritingTests", subcategoryId]);
      alert("Writing tests added successfully!");
    },
  });
};

// =============================
// 4. Bulk Update Tests
// =============================
export const useBulkUpdateWritingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ data, testId, subcategoryId }) =>
      bulkUpdateWritingTest(subcategoryId, testId, data),
    onSuccess,
  });
};

// =============================
// 5. Delete a Test
// =============================
export const useDeleteWritingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ testId, subcategoryId }) =>
      deleteWritingTest(subcategoryId, testId),
    onSuccess,
  });
};
