import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import {
  fetchGrammarQuestions,
  fetchGrammarQuestionsCount,
  bulkUploadGrammarQuestions,
  addGrammarQuestion,
  updateGrammarQuestion,
  deleteGrammarQuestion,
} from "@api/adminAPI";

import { validateQuestions } from "../../utils/validation";

// **Use query to fetch grammar questions**
export const useGrammarQuestions = (categoryId, subcategoryId, search) =>
  useQuery({
    queryKey: ["grammarQuestions", categoryId, subcategoryId, search], // Updated query key
    queryFn: () => fetchGrammarQuestions(categoryId, subcategoryId, search),
    enabled: false, // Fetch only if both IDs exist
  });

// **Use query to fetch grammar questions Count**
export const useGrammarQuestionsCount = (categoryId, subcategoryId, search) =>
  useQuery({
    queryKey: ["grammarQuestionsCount", categoryId, subcategoryId, search], // Updated query key
    queryFn: () =>
      fetchGrammarQuestionsCount(categoryId, subcategoryId, search),
    enabled: !!categoryId && !!subcategoryId, // Fetch only if both IDs exist
  });

// **Bulk upload grammar questions**
export const useBulkUploadGrammarQuestions = (onSuccess, onError) =>
  useMutation({
    mutationFn: async ({ categoryId, subcategoryId, questions }) => {
      return bulkUploadGrammarQuestions({
        categoryId,
        subcategoryId,
        questions,
      });
    },
    onSuccess,
    onError,
  });

// Add a grammar question
export const useAddGrammarQuestion = (onSuccess) =>
  useMutation({
    mutationFn: ({ subcategoryId, questionData }) =>
      addGrammarQuestion({ subcategoryId, questionData }),
    onSuccess,
  });

// Update a grammar question
export const useUpdateGrammarQuestion = (onSuccess) =>
  useMutation({
    mutationFn: updateGrammarQuestion,
    onSuccess,
  });

// Delete a grammar question
export const useDeleteGrammarQuestion = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, questionId }) =>
      deleteGrammarQuestion(categoryId, subcategoryId, questionId),
    onSuccess,
  });

export const useGrammarContentHandler = ({
  queryClient,
  categoryId,
  subcategoryId,
}) => {
  const [jsonData, setJsonData] = useState("");
  const [currentQuestions, setCurrentQuestions] = useState("");
  const [editing, setEditing] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);

  const handleValidation = (data) => {
    try {
      const isValid = validateQuestions(data);
      if (!isValid) throw new Error("Invalid question format.");
      return true;
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
      return false;
    }
  };

  return {
    jsonData,
    setJsonData,
    currentQuestions,
    setCurrentQuestions,
    editing,
    setEditing,
    uploadErrors,
    setUploadErrors,
    handleValidation,
  };
};
