import React from "react";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>403 - You cannat access this page</h1>
      <p style={styles.text}>
        We are sorry but we cannot let you see this page.
      </p>
      <Link to="/" style={styles.link}>
        Bosh sahifaga qaytish
      </Link>
    </div>
  );
};

// Inline Styles
const styles = {
  container: {
    textAlign: "center",
    marginTop: "20px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f8f9fa",
  },
  header: {
    fontSize: "2rem",
    color: "#dc3545", // Red color
    marginBottom: "10px",
  },
  text: {
    fontSize: "1.2rem",
    marginBottom: "20px",
  },
  link: {
    textDecoration: "none",
    color: "#007bff", // Blue color
    fontSize: "1rem",
  },
};

export default Unauthorized;
