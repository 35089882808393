import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchMyStudentData,
  fetchMyActiveAsignments,
} from "../../api/studentAPI/studentAPI";

export const useMyStudent = () =>
  useQuery({
    queryKey: ["myStudentData"],
    queryFn: () => fetchMyStudentData(),
    // staleTime: 1000 * 60 * 60,
  });

// export const useMyActiveAssignments = () =>
//   useQuery({
//     queryKey: ["myActiveAssignments"],
//     queryFn: () => fetchMyActiveAsignments(),
//     // staleTime: 1000 * 60 * 10,
//   });

export const useMyActiveAssignments = (selectedGroupId) =>
  useQuery({
    queryKey: ["myActiveAssignments", selectedGroupId],
    queryFn: () => fetchMyActiveAsignments(selectedGroupId),
    enabled: !!selectedGroupId,
  });
