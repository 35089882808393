import React, { useState } from "react";
import { Link } from "react-router-dom";
import { validatePhoneNumber } from "../../utils/validation";
import { formatPhoneNumber } from "../../utils/formatter";
import { useLogin } from "../../hooks/auth/useLogin";

function LoginPage() {
  const { login, isLoginPending, isError, error } = useLogin();

  // Form and error states
  const [phone, setPhone] = useState("+998");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");

  // Handle phone input change
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);

    setPhoneError(
      validatePhoneNumber(formattedPhone)
        ? ""
        : "Telefon raqam noto'g'ri formatda!"
    );
  };

  // Handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate phone input
    if (phoneError) return;

    // Perform login mutation
    login({ phone_number: phone, password: password });
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card shadow">
            <div className="card-header text-white bg-success">
              <h3 className="text-center">Kirish</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                {/* Phone Number Input */}
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Telefon raqamingiz
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    autoComplete="off"
                    required
                  />
                  {phoneError && (
                    <small className="text-danger">{phoneError}</small>
                  )}
                </div>

                {/* Password Input */}
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Parol
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="new-password"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="btn w-100 text-white bg-success mb-3"
                  disabled={phoneError || isLoginPending}
                >
                  {isLoginPending ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Kirish"
                  )}
                </button>

                {/* Error Message */}
                {isError && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {error || "Login failed. Please try again."}
                  </div>
                )}

                {/* Link to Sign Up */}
                <center>
                  <p>Akkauntingiz yo'qmi?</p>
                  <Link to="/signup" className="text-link">
                    Ro'yxatdan o'tish
                  </Link>
                </center>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
