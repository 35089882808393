import React, { useState } from "react";
import StudentRow from "./StudentRow";
import StudentDetail from "./StudentDetail";

const StudentsList = ({ fullGroupData, groupLoading }) => {
  const [selectedStudent, setSelectedStudent] = useState(null); // Track selected student

  if (groupLoading) {
    return <p>Loading ... </p>;
  }

  // Handle click to show profile
  const handleStudentClick = (student) => {
    setSelectedStudent(student); // Set the selected student
  };

  // Handle back button
  const handleBack = () => {
    setSelectedStudent(null); // Clear selected student to go back to the list
  };

  // Show Student Profile if a student is selected
  if (selectedStudent) {
    return (
      <div className="container mt-4">
        <button onClick={handleBack} className="btn btn-sm btn-secondary mb-3">
          ← Back
        </button>
        <StudentDetail student={selectedStudent} />
      </div>
    );
  }

  // Show Students List
  return (
    <div className="container mt-4">
      <h2 className="mb-3 text-primary">Students List</h2>
      <ul className="list-group">
        {fullGroupData?.students?.length > 0 ? (
          fullGroupData.students.map((student) => (
            <StudentRow
              key={student.id}
              student={student}
              onClick={() => handleStudentClick(student)} // Pass the full student object
            />
          ))
        ) : (
          <p className="text-muted">No students available.</p>
        )}
      </ul>
    </div>
  );
};

export default StudentsList;
