const SkillSettings = ({
  selectedType,
  isRandom,
  setIsRandom,
  testQuantities,
  setTestQuantities,
}) => (
  <div className="mt-3">
    <h3>{selectedType} Settings</h3>
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        checked={isRandom[selectedType]}
        onChange={() =>
          setIsRandom((prev) => ({
            ...prev,
            [selectedType]: !prev[selectedType],
          }))
        }
      />
      <label className="form-check-label">Random Test</label>
    </div>
    {isRandom[selectedType] && (
      <div className="mb-3">
        <label>Number of Tests:</label>
        <input
          type="number"
          className="form-control"
          value={testQuantities[selectedType] || ""}
          onChange={(e) =>
            setTestQuantities((prev) => ({
              ...prev,
              [selectedType]: e.target.value,
            }))
          }
        />
      </div>
    )}
  </div>
);

export default SkillSettings;
