import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchWords,
  addWord,
  updateWord,
  deleteWord,
  bulkUploadWords,
  uploadWordImage,
  deleteWordImage, // New API for deleting images
  fetchQuestionsByWord, // New API for fetching questions
  updateQuestion, // New API for updating questions
  deleteQuestion, // New API for deleting questions
  bulkUploadQuestions,
  fetchQuestionsCountByWord, // New API for bulk uploading questions
} from "../../api/adminAPI";

// =======================
// Words Hooks
// =======================

// **Fetch Words**
export const useWords = (categoryId, subcategoryId) =>
  useQuery({
    queryKey: ["words", categoryId, subcategoryId],
    queryFn: () => fetchWords(categoryId, subcategoryId),
    enabled: !!categoryId && !!subcategoryId, // Only fetch when IDs exist
  });

// **Add a Word**
export const useAddWord = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordData }) =>
      addWord({ categoryId, subcategoryId, wordData }),
    onSuccess,
  });

// **Update a Word**
export const useUpdateWord = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, wordData }) =>
      updateWord({ categoryId, subcategoryId, wordId, wordData }),
    onSuccess,
  });

// **Delete a Word**
export const useDeleteWord = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId }) =>
      deleteWord({ categoryId, subcategoryId, wordId }),
    onSuccess,
  });

// **Bulk Upload Words**
export const useBulkUploadWords = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordsData }) =>
      bulkUploadWords({ categoryId, subcategoryId, wordsData }),
    onSuccess,
  });

// **Upload Word Image**
export const useUploadWordImage = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, imageFile }) =>
      uploadWordImage({ categoryId, subcategoryId, wordId, imageFile }),
    onSuccess,
  });

// **Delete Word Image**
export const useDeleteWordImage = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, imageId }) =>
      deleteWordImage({ categoryId, subcategoryId, wordId, imageId }),
    onSuccess,
  });

// =======================
// Questions Hooks
// =======================

// **Fetch Questions for a Word with Search**
export const useQuestionsByWord = (categoryId, subcategoryId, wordId, search) =>
  useQuery({
    queryKey: ["questions", categoryId, subcategoryId, wordId, search],
    queryFn: () =>
      fetchQuestionsByWord({ categoryId, subcategoryId, wordId, search }),
    enabled: false, // Fetch only if IDs exist
  });

// **Fetch Questions COUNT for a Word with Search**
export const useQuestionsCountByWord = (
  categoryId,
  subcategoryId,
  wordId,
  search
) =>
  useQuery({
    queryKey: ["questionscount", categoryId, subcategoryId, wordId, search],
    queryFn: () =>
      fetchQuestionsCountByWord({ categoryId, subcategoryId, wordId, search }),
    enabled: !!categoryId && !!subcategoryId && !!wordId, // Fetch only if IDs exist
  });

// **Update a Question**
export const useUpdateQuestion = (onSuccess) =>
  useMutation({
    mutationFn: ({
      categoryId,
      subcategoryId,
      wordId,
      questionId,
      questionData,
    }) =>
      updateQuestion({
        categoryId,
        subcategoryId,
        wordId,
        questionId,
        questionData,
      }),
    onSuccess,
  });

// **Delete a Question**
export const useDeleteQuestion = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, questionId }) =>
      deleteQuestion({ categoryId, subcategoryId, wordId, questionId }),
    onSuccess,
  });

// **Bulk Upload Questions**
export const useBulkUploadQuestions = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryId, wordId, questionsData }) =>
      bulkUploadQuestions({
        categoryId,
        subcategoryId,
        wordId,
        questionsData,
      }),
    onSuccess,
  });
