import React from "react";
import StudentProfile from "./StudentProfile";
import Progress from "./Progress/Progress";
import Assignments from "./Assignments/Assignments";
import Feedback from "./Feedback/Feedback";
import Tabs from "./../../../../Shared/Tabs";

const StudentDetail = ({ student }) => {
  const tabs = [
    {
      key: "studentProfile",
      label: "Profile",
      content: <StudentProfile student={student} />,
    },
    {
      key: "progress",
      label: "Progress",
      content: <Progress />,
    },
    {
      key: "assignments",
      label: "Assignments",
      content: <Assignments student={student} />,
    },
    {
      key: "feedback",
      label: "Feedback",
      content: <Feedback />,
    },
  ];
  return (
    // <div className="container">
    <Tabs tabs={tabs} />
    // </div>
  );
};

export default StudentDetail;
