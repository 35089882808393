import React, { useState } from "react";
import GrammarVocabularySettings from "./GrammarVocabularySettings";
import SkillSettings from "./SkillSettings";
import PreviewAssignment from "./PreviewAssignment";
import AssignToStudents from "./AssignToStudents";
import transformPayload from "./../../../../../../../utils/transformPayload";
import { useCreateAssignment } from "./../../../../../../../hooks/AdminDashboard/useAssignment";
import getTodayDateTimeString from "./../../../../../../../utils/getTodayDateTimeString";

const AssignmentSettings = ({
  selectedCategoryId,
  selectedSubcategoryIds,
  selectedReadingTests,
  selectedListeningTests,
  selectedSpeakingTests,
  selectedWritingTests,
  fullGroupData,
  selectedType,
  setSelectedType,
}) => {
  const defaultStartTime = getTodayDateTimeString(fullGroupData?.start_time);

  // State
  const [isRandom, setIsRandom] = useState({
    Reading: false,
    Listening: false,
    Speaking: false,
    Writing: false,
  });
  const [assignmentType, setAssignmentType] = useState("homework");
  const [assignedTo, setAssignedTo] = useState("Group");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [timeLimit, setTimeLimit] = useState(null);
  const [startTime, setStartTime] = useState(defaultStartTime);
  const [deadline, setDeadline] = useState(
    new Date(2099, 11, 31, 23, 59).toISOString().slice(0, 16)
  );
  const [description, setDescription] = useState("");
  const [grammarSettings, setGrammarSettings] = useState({});
  const [vocabularySettings, setVocabularySettings] = useState({});
  const [testQuantities, setTestQuantities] = useState({
    Reading: 0,
    Listening: 0,
    Speaking: 0,
    Writing: 0,
  });

  // Hook for API call
  const { mutate: createAssignment, isPending: assignmentCreating } =
    useCreateAssignment();

  // Payload
  const payload = {
    group_id: fullGroupData?.id,
    categoryId: selectedCategoryId,
    subcategories: selectedSubcategoryIds,
    type: assignmentType,
    assignedTo,
    assigned_to_students: assignedTo === "Group" ? [] : selectedStudents,
    deadline: deadline ? new Date(deadline).toISOString() : null,
    time_limit: timeLimit,
    start_time:
      assignmentType === "classwork"
        ? startTime
          ? new Date(startTime).toISOString()
          : null
        : null,
    description,
    active: true,
    grammar: Object.entries(grammarSettings).map(([key, value]) => ({
      format: key,
      quantity: parseInt(value) || 0,
    })),
    vocabulary: Object.entries(vocabularySettings).map(([key, value]) => ({
      format: key,
      quantity: parseInt(value) || 0,
    })),
    reading: {
      random: isRandom.Reading,
      quantity: parseInt(testQuantities.Reading) || 0,
      specificTests: selectedReadingTests,
    },
    listening: {
      random: isRandom.Listening,
      quantity: parseInt(testQuantities.Listening) || 0,
      specificTests: selectedListeningTests,
    },
    speaking: {
      random: isRandom.Speaking,
      quantity: parseInt(testQuantities.Speaking) || 0,
      specificTests: selectedSpeakingTests,
    },
    writing: {
      random: isRandom.Writing,
      quantity: parseInt(testQuantities.Writing) || 0,
      specificTests: selectedWritingTests,
    },
  };

  // Handle create assignment
  const handleCreate = () => {
    if (!payload.subcategories.length) {
      alert("Please select some lessons!");
      return;
    }
    if (window.confirm("Are you sure you want to create this assignment?")) {
      createAssignment(transformPayload(payload));
    }
  };

  return (
    <div className="container mt-4">
      <div className="btn-group mb-3">
        {[
          "Grammar",
          "Vocabulary",
          "Reading",
          "Listening",
          "Speaking",
          "Writing",
        ].map((category) => (
          <button
            key={category}
            className="btn btn-primary"
            onClick={() => setSelectedType(category)}
          >
            {category}
          </button>
        ))}
        <button
          className="btn btn-light"
          onClick={() => setSelectedType("Preview")}
        >
          Preview
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => setSelectedType("Assign")}
        >
          Assign
        </button>
      </div>

      {selectedType === "Grammar" || selectedType === "Vocabulary" ? (
        <GrammarVocabularySettings
          selectedType={selectedType}
          settings={
            selectedType === "Grammar" ? grammarSettings : vocabularySettings
          }
          setSettings={
            selectedType === "Grammar"
              ? setGrammarSettings
              : setVocabularySettings
          }
        />
      ) : ["Reading", "Listening", "Speaking", "Writing"].includes(
          selectedType
        ) ? (
        <SkillSettings
          selectedType={selectedType}
          isRandom={isRandom}
          setIsRandom={setIsRandom}
          testQuantities={testQuantities}
          setTestQuantities={setTestQuantities}
        />
      ) : selectedType === "Preview" ? (
        <PreviewAssignment
          payload={payload}
          assignmentType={assignmentType}
          setAssignmentType={setAssignmentType}
          deadline={deadline}
          setDeadline={setDeadline}
          timeLimit={timeLimit}
          setTimeLimit={setTimeLimit}
          startTime={startTime}
          setStartTime={setStartTime}
          description={description}
          setDescription={setDescription}
          handleCreate={handleCreate}
          assignmentCreating={assignmentCreating}
          fullGroupData={fullGroupData}
        />
      ) : (
        <AssignToStudents
          assignedTo={assignedTo}
          setAssignedTo={setAssignedTo}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          fullGroupData={fullGroupData}
        />
      )}
    </div>
  );
};

export default AssignmentSettings;
