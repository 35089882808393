import { React, useState } from "react";
import MCQ from "../QuestionFormats/MCQ";
import Matching from "../QuestionFormats/Matching";
import GAPFILL from "../QuestionFormats/GAPFILL";
import MultiSelect from "../QuestionFormats/MultiSelect";
import WORD_EXAMPLE_SENTENCE from "../QuestionFormats/WORD_EXAMPLE_SENTENCE";
import { useSaveProgress } from "../../../../../hooks/Progress/useProgress";
import getAnswerByQuestionId from "./getAnswerByQuestionId";
import DangerConfirmationPopup from "../../../../Shared/DangerConfirmationPopup";
import { useQuery } from "@tanstack/react-query";

const createSubmissionPayload = (
  selectedAnswers,
  type,
  attempt_id,
  language
) => {
  // Define the formats that belong to translations
  const translationFormats = ["WORD", "WORD_AND_EXAMPLE", "SENTENCE"];

  // Prepare two separate arrays
  const translationAnswers = [];
  const formattedAnswers = [];

  // Single pass to categorize answers
  selectedAnswers.forEach(({ questionId, answer, format }) => {
    if (translationFormats.includes(format)) {
      translationAnswers.push({
        id: questionId,
        selectedAnswer: answer,
        format,
      });
    } else {
      formattedAnswers.push({
        id: questionId,
        selectedAnswer: answer,
      });
    }
  });

  // Return the payload with both answer lists
  return {
    language: language,
    skill: type,
    answers: formattedAnswers,
    translation_answers: translationAnswers,
    attempt_id: attempt_id,
    time_spent: 10,
  };
};

const GrammarVocabulary = ({ type, assignmentId }) => {
  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false, // or enabled: !!assignmentId, but we have no queryFn
  });

  const attempt = data?.attempt;
  const attempt_id = attempt.id;
  const answersDictionary = attempt.answers[type];
  const answers = answersDictionary?.answers || [];

  const score = answersDictionary?.score;
  const questions = attempt.questions[type];
  const isSubmitted = answers.length > 0 ? true : false;

  // Group questions by format
  const groupedQuestions = questions.reduce((acc, question) => {
    acc[question.format] = acc[question.format] || [];
    acc[question.format].push(question);
    return acc;
  }, {});

  let globalQuestionNumber = 0;

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [language, setLanguage] = useState("uz");

  const { mutate: saveprogress, isPending } = useSaveProgress(assignmentId);

  const handleSubmit = () => {
    const payload = createSubmissionPayload(
      selectedAnswers,
      type,
      attempt_id,
      language
    );

    saveprogress({ assignmentId, progressData: payload });
  };

  const handleAnswerSelect = (
    questionId,
    answer,
    format = null,
    word = null,
    example = null,
    sentence = null
  ) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      // Check if the question ID already exists
      const existingAnswerIndex = prevSelectedAnswers.findIndex(
        (entry) => entry.questionId === questionId
      );

      if (existingAnswerIndex > -1) {
        // If the question already exists, update its answer
        const updatedAnswers = [...prevSelectedAnswers];
        updatedAnswers[existingAnswerIndex].answer = answer;
        return updatedAnswers;
      } else {
        // Otherwise, add a new entry

        return [
          ...prevSelectedAnswers,
          { questionId, answer, format, word, example, sentence },
        ];
      }
    });
  };

  const renderQuestionContent = (question, answer, questionNumber) => {
    switch (question.format) {
      case "MCQ":
        return (
          <MCQ
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "MULTI_SELECT":
        return (
          <MultiSelect
            key={questionNumber}
            questionData={question}
            answer={answer || []}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "GAP_FILL":
        return (
          <GAPFILL
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "MATCHING":
        return (
          <Matching
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "WORD":
      case "WORD_AND_EXAMPLE":
      case "SENTENCE":
        return (
          <WORD_EXAMPLE_SENTENCE
            key={"sentence" + question.word_id}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      default:
        return (
          <p key={questionNumber} className="text-danger">
            Unknown question format
          </p>
        );
    }
  };

  return (
    <div>
      <h2 className="mb-5 text-center display-4 fw-bold">
        {type === "vocabulary_translation"
          ? "Vocabulary translation"
          : type.charAt(0).toUpperCase() + type.slice(1)}

        <span className="text-success"> {score}</span>
      </h2>

      {Object.keys(groupedQuestions).map((format, idx) => (
        <div key={idx} className="mb-1">
          <h5 className="text-primary"> {getQuestionFormat(format)}</h5>
          <div className="row">
            {groupedQuestions[format].map((question) => {
              const answer = getAnswerByQuestionId(
                answers,
                type === "vocabulary_translation"
                  ? question.word_id
                  : question.id
              );

              globalQuestionNumber += 1; // Increment global question number
              return (
                <div key={globalQuestionNumber} className="col-md-4 mb-1 ">
                  {renderQuestionContent(
                    question,
                    answer,
                    globalQuestionNumber
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}

      <div className=" mb-5">
        {!isSubmitted && (
          <button
            className="btn btn-success"
            onClick={() => setShowConfirmPopup(true)}
            disabled={isPending}
          >
            Submit
          </button>
        )}
        <DangerConfirmationPopup
          setLanguage={type === "vocabulary_translation" && setLanguage}
          showConfirmPopup={showConfirmPopup}
          setShowConfirmPopup={setShowConfirmPopup}
          onConfirm={handleSubmit}
          message={
            "Are you sure you want to submit the test? You cannot undo this action!"
          }
        />
      </div>
    </div>
  );
};

export default GrammarVocabulary;

function getQuestionFormat(format) {
  switch (format) {
    case "MCQ":
      return "Select one correct option.";

    case "MULTI_SELECT":
      return "Select all that apply.";

    case "GAP_FILL":
      return "Fill the gaps below.";

    case "MATCHING":
      return "Match the parts correctly.";

    case "WORD":
      return "Translate the WORDS.";

    case "WORD_AND_EXAMPLE":
      return "Translate the WORDS in BOLD.";

    case "SENTENCE":
      return "Translate the SENTENCES.";
  }
}
