import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchAuthStatus } from "../../api/authAPI";

export const useAuth = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["authStatus"],
    queryFn: fetchAuthStatus,
    staleTime: 1000 * 60 * 5,
    // enabled: !!cachedAuth, // Only fetch if we had previous auth data
    retry: false, // Do not retry if user is not authenticated
    onError: (error) => {
      if (error.response?.status === 401) {
        queryClient.clear();
        localStorage.clear();
      }
    },
  });

  return {
    isAuthenticated: !!data?.is_authenticated,
    role: data?.role || "unknown",
    loading: isLoading,
    error: isError,
    refetch,
  };
};
