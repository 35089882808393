import React, { useState } from "react";
import CategoryList from "./CategoryList";
import SubcategoryList from "./SubcategoryList";
import TestList from "./TestList";
import AssignmentSettings from "./AssignmentSettings/AssignmentSettings";

const CreateAssignment = ({ fullGroupData, groupLoading }) => {
  // tab settings for AssignmentSettings
  const [selectedType, setSelectedType] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);

  // assignment states
  const [selectedReadingTests, setSelectedReadingTests] = useState([]);
  const [selectedListeningTests, setSelectedListeningTests] = useState([]);
  const [selectedSpeakingTests, setSelectedSpeakingTests] = useState([]);
  const [selectedWritingTests, setSelectedWritingTests] = useState([]);

  // Handle Back Navigation
  const handleOnBack = () => {
    selectedSubcategoryId
      ? setSelectedSubcategoryId(null)
      : setSelectedCategoryId(null);

    setSelectedSubcategoryIds([]);
    setSelectedReadingTests([]);
    setSelectedListeningTests([]);
    setSelectedSpeakingTests([]);
    setSelectedWritingTests([]);
    setSelectedType(null);
  };

  // Loading State
  if (groupLoading) return <>loading...</>;

  // Render Category List if no category is selected
  if (!selectedCategoryId) {
    return (
      <CategoryList
        categories={fullGroupData?.categories}
        onSelectCategory={setSelectedCategoryId}
      />
    );
  }

  // Render Assignment Settings and Content Based on Selection
  return (
    <>
      <AssignmentSettings
        selectedSubcategoryIds={selectedSubcategoryIds}
        selectedCategoryId={selectedCategoryId}
        fullGroupData={fullGroupData}
        selectedReadingTests={selectedReadingTests}
        selectedListeningTests={selectedListeningTests}
        selectedSpeakingTests={selectedSpeakingTests}
        selectedWritingTests={selectedWritingTests}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      <button
        type="button"
        className="btn btn-secondary mb-1"
        onClick={handleOnBack}
      >
        Back
      </button>

      {!selectedSubcategoryId ? (
        <SubcategoryList
          setSelectedSubcategoryId={setSelectedSubcategoryId}
          setSelectedSubcategoryIds={setSelectedSubcategoryIds}
          selectedSubcategoryIds={selectedSubcategoryIds}
          selectedCategoryId={selectedCategoryId}
        />
      ) : (
        <TestList
          setSelectedReadingTests={setSelectedReadingTests}
          setSelectedListeningTests={setSelectedListeningTests}
          setSelectedSpeakingTests={setSelectedSpeakingTests}
          setSelectedWritingTests={setSelectedWritingTests}
          selectedSubcategoryId={selectedSubcategoryId}
          selectedType={selectedType}
          selectedReadingTests={selectedReadingTests}
          selectedListeningTests={selectedListeningTests}
          selectedSpeakingTests={selectedSpeakingTests}
          selectedWritingTests={selectedWritingTests}
        />
      )}
    </>
  );
};

export default CreateAssignment;
