import React from "react";

const TestsList = ({ tests, selectedTest, handleSelectTest }) => {
  return (
    <div
      className="col-3 border-end p-3 overflow-auto"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <h5 className="mb-3">Test List</h5>
      <ul className="list-group">
        {tests.map((test) => (
          <li
            key={test.id}
            className={`list-group-item ${
              selectedTest?.id === test.id ? "active" : ""
            }`}
            onClick={() => handleSelectTest(test)}
            style={{ cursor: "pointer" }}
          >
            {test.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestsList;
