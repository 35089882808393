import api from "../api";

// ==========================
// 1. Fetch All Tests (Simple List)
// ==========================
export const fetchSimpleListeningTests = async (subcategoryId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/tests/`
  );
  return response.data;
};

// ==========================
// 2. Fetch a Single Test with Details (Sections, Parts, Questions)
// ==========================
export const fetchDetailedListeningTest = async (subcategoryId, testId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/tests/${testId}/`
  );
  return response.data;
};

// ==========================
// 3. Bulk Upload Tests
// ==========================
export const bulkUploadListeningTests = async (subcategoryId, data) => {
  console.log(" data ===== ", data); // Optional: Log data for debugging
  const response = await api.post(
    `/api/admin/subcategory/${subcategoryId}/tests/bulk_create/`,
    data
  );
  return response.data;
};

// ==========================
// 4. Bulk Update Tests
// ==========================
export const bulkUpdateListeningTest = async (subcategoryId, testId, data) => {
  const response = await api.put(
    `/api/admin/subcategory/${subcategoryId}/tests/${testId}/update_test/`,
    data
  );
  return response.data;
};

// ==========================
// 5. Delete a Test
// ==========================
export const deleteListeningTest = async (subcategoryId, testId) => {
  const response = await api.delete(
    `/api/admin/subcategory/${subcategoryId}/tests/${testId}/`
  );
  return response.data;
};

// ==========================
// 6. Update a Single Test (Name or Description)
// ==========================
export const updateListeningTest = async (
  subcategoryId,
  testId,
  updatedData
) => {
  const response = await api.put(
    `/api/admin/subcategory/${subcategoryId}/tests/${testId}/`,
    updatedData
  );
  return response.data;
};
