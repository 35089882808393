import React, { useEffect, useState } from "react";

const AssignmentCard = ({ assignment, type, onStartAssignment }) => {
  const [isDisabled, setIsDisabled] = useState(
    new Date() < new Date(assignment.start_time)
  );
  const [countdown, setCountdown] = useState(null);

  const isToday = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    return (
      today.getFullYear() === targetDate.getFullYear() &&
      today.getMonth() === targetDate.getMonth() &&
      today.getDate() === targetDate.getDate()
    );
  };

  const isTomorrow = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const targetDate = new Date(date);

    return (
      tomorrow.getFullYear() === targetDate.getFullYear() &&
      tomorrow.getMonth() === targetDate.getMonth() &&
      tomorrow.getDate() === targetDate.getDate()
    );
  };

  const formatStartTime = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const timeDiff = now - start;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    const hours = start.getHours().toString().padStart(2, "0");
    const minutes = start.getMinutes().toString().padStart(2, "0");

    if (isToday(startTime)) {
      const timeLeft = start - now;

      if (timeLeft > 0) {
        const hoursLeft = Math.floor(
          (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutesLeft = Math.floor(
          (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
        );
        return `Starts today in `;
      } else {
        return `Started at ${hours}:${minutes}`;
      }
    } else if (daysDiff === 1) {
      return `Started at ${hours}:${minutes} yesterday`;
    } else if (daysDiff === 2) {
      return `Started at ${hours}:${minutes} 2 days ago`;
    } else {
      return `Started at ${hours}:${minutes} on ${start.toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }
      )}`;
    }
  };

  const formatDeadline = (deadline) => {
    const dueDate = new Date(deadline);
    const hours = dueDate.getHours().toString().padStart(2, "0");
    const minutes = dueDate.getMinutes().toString().padStart(2, "0");

    if (isToday(deadline)) {
      return `Due today at ${hours}:${minutes}`;
    } else if (isTomorrow(deadline)) {
      return `Due tomorrow at ${hours}:${minutes}`;
    } else {
      return `Due by ${dueDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })}`;
    }
  };

  const formatTimeLimit = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0 && remainingMinutes > 0) {
      return `You have ${hours} hour${
        hours > 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      return `You have ${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return `You have ${remainingMinutes} minute${
        remainingMinutes > 1 ? "s" : ""
      }`;
    }
  };

  useEffect(() => {
    if (isToday(assignment.start_time)) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const startTime = new Date(assignment.start_time).getTime();
        const timeDifference = startTime - now;

        if (timeDifference <= 0) {
          clearInterval(interval);
          setCountdown(null);
          setIsDisabled(false);
        } else {
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setCountdown(`${minutes}m ${seconds}s`);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [assignment.start_time]);

  return (
    <div
      className={`card h-100 shadow-sm position-relative ${
        isToday(assignment.start_time) ? "border-success" : ""
      }`}
    >
      <div
        className="position-absolute top-0 end-0 p-2 text-secondary"
        style={{ fontSize: "0.75rem", fontWeight: "bold" }}
      >
        {type === "homework" ? "Homework" : "Classwork"} #{assignment.id}
      </div>

      <div className="card-body">
        <h5 className="text-success fw-bold mb-3">{assignment.description}</h5>

        {type === "homework" && (
          <span className=" text-secondary">
            {formatDeadline(assignment.deadline)}
            <br />
          </span>
        )}

        {type === "classwork" && (
          <span className="text-secondary">
            {formatStartTime(assignment.start_time)} <br />
          </span>
        )}

        {countdown && (
          <span className="text-center text-success fw-bold fs-3 mb-3">
            {countdown}
          </span>
        )}

        <span className="text-secondary">
          {formatTimeLimit(assignment.time_limit)}
        </span>
      </div>

      <div className="card-footer bg-light d-flex">
        <button
          className="btn btn-success btn-sm"
          onClick={() => onStartAssignment(assignment.id)}
          disabled={isDisabled}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default AssignmentCard;
