import React, { useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useFetchDetailedListeningTest,
  useBulkUpdateListeningTest,
  useDeleteListeningTest,
} from "../../../../../../hooks/AdminDashboard/useListening";

const Test = ({ subcategoryId, selectedTest, setSelectedtest }) => {
  const queryClient = useQueryClient();

  // Fetch Test Details
  const { data: test, isLoading } = useFetchDetailedListeningTest(
    subcategoryId,
    selectedTest?.id
  );

  // Update Mutation
  const { mutate: bulkUpdateTest, isPending: updating } =
    useBulkUpdateListeningTest(() => {
      queryClient.invalidateQueries(["simpleListeningTests", subcategoryId]); // Refresh data
      alert("Successfully updated!");
    });

  // State for JSON Data
  const [jsonData, setJsonData] = useState("");

  // Handle Editor Changes
  const handleEditorChange = (value) => {
    setJsonData(value);
  };

  // Save Changes
  const handleUpdate = () => {
    if (!selectedTest) {
      alert("Select a test plz!");
      return;
    }
    try {
      const updatedTest = JSON.parse(jsonData); // Validate JSON
      console.log("updatedTest === ", updatedTest);
      bulkUpdateTest({
        testId: selectedTest.id,
        data: updatedTest,
        subcategoryId: subcategoryId,
      });
    } catch (error) {
      alert("Invalid JSON format!");
    }
  };

  const { mutate: deleteTest, isPending: deleting } = useDeleteListeningTest(
    () => {
      queryClient.invalidateQueries(["simpleListeningTests", subcategoryId]);
      setSelectedtest(null);
      setJsonData("");

      alert("Successfully deleted!");
    }
  );

  function handleDelete() {
    if (!selectedTest) {
      alert("Select a test plz!");
      return;
    }
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this test?"
    );
    if (!confirmDelete) return;

    deleteTest({ testId: selectedTest.id, subcategoryId });
  }

  if (isLoading) {
    return <p>Loading the test...</p>;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>{test?.name}</h1>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className="btn btn-success"
            disabled={updating}
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            className="btn btn-danger"
            disabled={deleting}
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
      <p>{test?.description}</p>
      <MonacoEditor
        height="600px"
        language="json"
        value={selectedTest && JSON.stringify(test, null, 2)}
        onChange={handleEditorChange}
        options={{ minimap: { enabled: false } }}
      />
    </div>
  );
};

export default Test;
