import React, { useState } from "react";

const GAPFILL = ({
  questionNumber,
  questionData,
  answer,
  isSubmitted,
  handleAnswerSelect,
}) => {
  const [userAnswer, setUserAnswer] = useState(answer?.selectedAnswer || "");

  function handleAnswerChange(e) {
    const value = e.target.value;
    setUserAnswer(value);
    handleAnswerSelect(questionData.id, value.trim());
  }

  return (
    <div className="mb-4 border rounded shadow-sm p-3">
      <p className="fw-semibold">
        {questionNumber}. {questionData.text}
      </p>
      <input
        id={`gapfill-${questionData.id}`} // Unique id for the input
        name={`gapfill-question-${questionData.id}`} // Unique name per question
        type="text"
        className={`form-control mb-3`}
        value={userAnswer}
        onChange={(e) => handleAnswerChange(e)}
        placeholder="Type your answer here"
        disabled={isSubmitted}
      />

      {/* Display Feedback for Incorrect Submission */}
      {isSubmitted && !answer?.is_correct && (
        <>
          <p className="mt-2 text-success">
            <strong>Correct Answer:</strong> {questionData.correct_answer}
          </p>
          <p className="mt-2 text-danger">
            <strong>Feedback:</strong> <em>{questionData.feedback}</em>
          </p>
        </>
      )}
    </div>
  );
};

export default GAPFILL;
