import MCQ from "../QuestionFormats/MCQ";
import MultiSelect from "../QuestionFormats/MultiSelect";
import GAPFILL from "../QuestionFormats/GAPFILL";
import TrueFalseNotGiven from "../QuestionFormats/TrueFalseNotGiven";
import Matching from "../QuestionFormats/Matching";
import getAnswerByQuestionId from "../GrammarVocabulary/getAnswerByQuestionId";

const Questions = ({ sections, answers, isSubmitted, handleAnswerSelect }) => {
  const renderQuestionContent = (question, answer, questionNumber) => {
    switch (question.format) {
      case "MCQ":
        return (
          <MCQ
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );
      case "MULTI_SELECT":
        return (
          <MultiSelect
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );
      case "GAP_FILL":
        return (
          <GAPFILL
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );
      case "MATCHING":
        return (
          <Matching
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );
      case "TRUE_FALSE_NOT_GIVEN":
        return (
          <TrueFalseNotGiven
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );
      default:
        return (
          <p key={questionNumber} className="text-danger">
            Unknown question format
          </p>
        );
    }
  };

  return (
    <div
      className="questions p-3"
      style={{
        flex: 1,
        overflowY: "auto",
        maxHeight: "calc(100vh - 200px)", // Adjust dynamically for layout
      }}
    >
      {sections.map((section) => (
        <div key={section.id} className="mb-4">
          <h5>{section.name}</h5>
          <p className="text-muted">{section.instructions}</p>
          <div>
            {section.parts.map((part) => (
              <div key={part.id} className="mb-4">
                <h6>{part.name}</h6>
                <p className="text-muted">{part.instructions}</p>
                <div>
                  {part.questions
                    .sort((a, b) => a.order - b.order)
                    .map((question) => {
                      const answer = getAnswerByQuestionId(
                        answers,
                        question.id
                      );
                      return (
                        <div key={question.id} className="mb-3">
                          {renderQuestionContent(
                            question,
                            answer,
                            question.order
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Questions;
