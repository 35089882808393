import React, { useState } from "react";
import Header from "./Header";
import Questions from "./Questions";
import CueCard from "./CueCard";
import Footer from "./Footer";

const SpeakingTest = ({ selectedTest }) => {
  const [currentPartIndex, setCurrentPartIndex] = useState(0);

  const handleNavigation = (direction) => {
    setCurrentPartIndex((prevIndex) =>
      direction === "next"
        ? Math.min(prevIndex + 1, selectedTest.parts.length - 1)
        : Math.max(prevIndex - 1, 0)
    );
  };

  const currentPart = selectedTest.parts[currentPartIndex];

  return (
    <div
      className="d-flex flex-column"
      style={{ height: "calc(100vh - 60px)" }}
    >
      {/* Header */}
      <Header partInfo={currentPart} />

      {/* Main Content */}
      <div
        className="flex-grow-1 d-flex overflow-auto"
        style={{ padding: "10px" }}
      >
        {/* CueCard Section */}
        {currentPart.cue_card && (
          <div className="p-3" style={{ flex: 1 }}>
            <CueCard cueCard={currentPart.cue_card} />
          </div>
        )}

        {/* Questions Section */}
        <div className="p-3" style={{ flex: currentPart.cue_card ? 2 : 3 }}>
          <Questions questions={currentPart.questions} />
        </div>
      </div>

      {/* Footer */}
      <Footer
        currentPartIndex={currentPartIndex}
        totalParts={selectedTest.parts.length}
        onNavigate={handleNavigation}
      />
    </div>
  );
};

export default SpeakingTest;
