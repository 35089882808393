import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import CategoryList from "./CategoryList/CategoryList";
import SubcategoryList from "./CategoryList/SubCategoryList";
import ContentRenderer from "./ContentComponents/ContentRenderer";
import AddSubcategoryModal from "./CategoryList/AddSubcategoryModel";

import {
  useSubcategories,
  useAddSubcategory,
} from "../../../../hooks/AdminDashboard/useSubcategories";

import {
  useCategories,
  useAddCategory,
} from "../../../../hooks/AdminDashboard/useCategories";
import AddCategoryModal from "./CategoryList/AddCategoryModel";

const CategoriesManagement = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const queryClient = useQueryClient();

  // Category State
  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
  });
  const { data: categories, isLoading: categoriesLoading } = useCategories();

  // add category mutation
  const addCategoryMutation = useAddCategory(() => {
    queryClient.invalidateQueries(["categories"]);
    setShowAddCategoryModal(false);
    setNewCategory({ name: "", description: "" });
  });

  const handleAddCategory = () => {
    addCategoryMutation.mutate(newCategory);
  };

  // Subcategory State
  const [newSubcategory, setNewSubcategory] = useState({
    name: "",
    description: "",
  });

  const { data: subcategories, isLoading: subcategoriesLoading } =
    useSubcategories(selectedCategoryId);

  // Add Subcategory Mutation
  const addSubcategoryMutation = useAddSubcategory(() => {
    queryClient.invalidateQueries(["subcategories", selectedCategoryId]);
    setShowModal(false);
    setNewSubcategory({ name: "", description: "" });
  });

  const handleAddSubcategory = () => {
    addSubcategoryMutation.mutate({
      categoryId: selectedCategoryId, // Pass category ID dynamically
      subcategoryData: newSubcategory,
    });
  };

  if (categoriesLoading) return <p>Loading categories...</p>;
  if (subcategoriesLoading) return <p>Loading subcategories...</p>;

  // CSS Wrapper for full-screen layout
  return (
    <div>
      {!selectedCategoryId ? (
        <CategoryList
          categories={categories}
          onAddCategory={() => setShowAddCategoryModal(true)}
          onSelectCategory={setSelectedCategoryId}
        >
          <AddCategoryModal
            show={showAddCategoryModal}
            handleClose={() => setShowAddCategoryModal(false)}
            handleAdd={handleAddCategory}
            newCategory={newCategory}
            setNewCategory={setNewCategory}
            isLoading={addCategoryMutation.isPending}
          />
        </CategoryList>
      ) : !selectedSubcategoryId ? (
        <SubcategoryList
          categoryId={selectedCategoryId}
          subcategories={subcategories}
          onSelectSubcategory={setSelectedSubcategoryId}
          onBack={() => setSelectedCategoryId(null)}
          onAddLesson={() => setShowModal(true)}
        >
          <AddSubcategoryModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleAdd={handleAddSubcategory}
            newSubcategory={newSubcategory}
            setNewSubcategory={setNewSubcategory}
            isLoading={addSubcategoryMutation.isPending}
          />
        </SubcategoryList>
      ) : (
        <ContentRenderer
          categoryId={selectedCategoryId}
          selectedSubcategoryId={selectedSubcategoryId}
          setSelectedSubcategoryId={setSelectedSubcategoryId}
        />
      )}
    </div>
  );
};

export default CategoriesManagement;
