import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AssignmentCard.css"; // Ensure CSS file is included

// Helper function to compute the overall average score of each assignment
const calculateAverageScore = (assignmentData) => {
  const {
    writing_responses,
    reading,
    listening,
    grammar,
    vocabulary,
    vocabulary_translation,
  } = assignmentData;

  let totalScore = 0;
  let scoreCount = 0;

  if (writing_responses?.length) {
    writing_responses.forEach((test) => {
      let testScoreSum = 0;
      let testScoreCount = 0;

      test.tasks.forEach((task) => {
        if (task.score !== undefined && task.score !== null) {
          testScoreSum += parseFloat(task.score);
          testScoreCount++;
        }
      });

      if (testScoreCount > 0) {
        totalScore += testScoreSum / testScoreCount;
        scoreCount++;
      }
    });
  }

  [reading, listening].forEach((section) => {
    if (section?.length) {
      section.forEach((item) => {
        if (item.score !== undefined && item.score !== null) {
          totalScore += item.score;
          scoreCount++;
        }
      });
    }
  });

  [grammar, vocabulary, vocabulary_translation].forEach((section) => {
    if (section?.score !== undefined && section.score !== null) {
      totalScore += section.score;
      scoreCount++;
    }
  });

  return scoreCount === 0
    ? "N/A"
    : parseFloat((totalScore / scoreCount).toFixed(2));
};

// Function to determine badge styles based on score
const getBadgeStyles = (score) => {
  if (score === "N/A")
    return {
      background: "linear-gradient(to right, #6c757d, #495057)",
      color: "#495057",
      icon: "❔",
    };
  if (score >= 90)
    return {
      background: "linear-gradient(to right, #1cc88a, #20c997)",
      color: "#20c997",
      icon: "🏆",
    };
  if (score >= 75)
    return {
      background: "linear-gradient(to right, #36b9cc, #4eb8ff)",
      color: "#4eb8ff",
      icon: "💡",
    };
  if (score >= 50)
    return {
      background: "linear-gradient(to right, #ffc107, #ffdd57)",
      color: "#ffdd57",
      icon: "📚",
    };
  if (score >= 30)
    return {
      background: "linear-gradient(to right, #dc3545, #ff6b6b)",
      color: "#dc3545",
      icon: "⚠️",
    };
  return {
    background: "linear-gradient(to right, #800000, #cc0000)",
    color: "#800000",
    icon: "❌",
  };
};

const AssignmentCard = ({ assignmentData, onClick }) => {
  const averageScore = calculateAverageScore(assignmentData);
  const { created_at, assignment } = assignmentData;
  const { background, color, icon } = getBadgeStyles(averageScore);

  return (
    <div className="badge-card" onClick={onClick}>
      {/* Badge Header */}
      <div className="badge-header" style={{ background }}>
        <span className="badge-icon">{icon}</span>
      </div>

      {/* Badge Content */}
      <div className="badge-info">
        <h5 className="badge-title">{assignment.type}</h5>
        <p className="badge-score" style={{ color }}>
          {averageScore}
        </p>
        <p className="badge-date">
          {new Date(created_at).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </p>
      </div>
    </div>
  );
};

export default AssignmentCard;
