import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  saveProgress,
  getStudentProgress,
  getAllProgress,
  getProgressById,
  updateProgress,
  deleteProgress,
} from "../../api/progressAPI/progressAPI";

export const useSaveProgress = (assignmentId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ assignmentId, progressData }) =>
      saveProgress(assignmentId, progressData),
    onSuccess: (response) => {
      // Log the response received
      queryClient.setQueryData(["assignmentAttempt", assignmentId], response);
    },
    onError: (error) => {
      console.error("Error saving progress:", error);
      alert("Failed to save progress.");
    },
  });
};

export const useStudentProgress = (page = 1) => {
  return useQuery({
    queryKey: ["studentProgress", page],
    queryFn: () => getStudentProgress(page),
    keepPreviousData: true, // Helps with smooth pagination
    onError: (error) => {
      console.error("Error fetching student progress:", error);
    },
  });
};

//=============================================================
// Admin
//=============================================================

export const useAdminAllProgress = (page = 1, page_size, assignment_id) => {
  return useQuery({
    queryKey: ["adminProgress", page, assignment_id],
    queryFn: () => getAllProgress(page, page_size, assignment_id),
    keepPreviousData: true,
    onError: (error) => {
      console.error("Error fetching admin progress:", error);
    },
    enabled: !!assignment_id,
  });
};

export const useProgressById = (progressId) => {
  return useQuery({
    queryKey: ["progress", progressId],
    queryFn: () => getProgressById(progressId),
    onError: (error) => {
      console.error("Error fetching progress by ID:", error);
    },
  });
};

export const useUpdateProgress = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ progressId, updatedData }) =>
      updateProgress(progressId, updatedData),
    onSuccess: () => {
      // queryClient.invalidateQueries("adminProgress");
    },
    onError: (error) => {
      console.error("Error updating progress:", error);
      alert("Failed to update progress.");
    },
  });
};

export const useDeleteProgress = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (progressId) => deleteProgress(progressId),
    onSuccess: () => {
      // queryClient.invalidateQueries("adminProgress");
    },
    onError: (error) => {
      console.error("Error deleting progress:", error);
      alert("Failed to delete progress.");
    },
  });
};
