import api from "../api";

export const fetchStudentAssignments = async (studentId) => {
  const response = await api.get(
    `/api/assignments/assignments?student_id=${studentId}`
  );
  return response.data; // Returns assignments for the specified student
};

export const getActiveAssignmentsByStudent = async (studentId) => {
  console.log("getInactiveAssignmentsByStudent");
  const response = await api.get(`/api/assignments/assignments/`, {
    params: { student_id: studentId, status: "active" },
  });

  console.log("response = ", response);
  return response.data; // Returns active assignments for the student
};

export const getInactiveAssignmentsByStudent = async (studentId) => {
  console.log("getInactiveAssignmentsByStudent");
  const response = await api.get(`/api/assignments/assignments/`, {
    params: { student_id: studentId, status: "inactive" },
  });

  console.log("response = ", response);
  return response.data; // Returns inactive assignments for the student
};

export const getActiveAssignmentsByGroup = async (groupId) => {
  const response = await api.get(`/api/assignments/assignments/`, {
    params: { group_id: groupId, status: "active" },
  });
  return response.data; // Returns active assignments for the group
};

export const getInactiveAssignmentsByGroup = async (groupId) => {
  const response = await api.get(`/api/assignments/assignments/`, {
    params: { group_id: groupId, status: "inactive" },
  });
  return response.data; // Returns inactive assignments for the group
};

// Create an assignment and assign it to multiple students or a group
export const createAssignment = async (assignmentData) => {
  const response = await api.post(
    `/api/assignments/assignments/`,
    assignmentData
  );
  return response.data; // Returns created assignments
};

// Activate an assignment
export const activateAssignment = async (assignmentId) => {
  const response = await api.post(
    `/api/assignments/assignments/${assignmentId}/activate/`
  );
  return response.data; // Returns success message
};

// Deactivate an assignment
export const deactivateAssignment = async (assignmentId) => {
  const response = await api.post(
    `/api/assignments/assignments/${assignmentId}/deactivate/`
  );
  return response.data; // Returns success message
};

// Delete an assignment
export const deleteAssignment = async (assignmentId) => {
  const response = await api.delete(
    `/api/assignments/assignments/${assignmentId}/`
  );
  return response.data; // Returns success message
};

// Update an assignment
export const updateAssignment = async ({ assignmentId, updatedData }) => {
  const response = await api.put(
    `/api/assignments/assignments/${assignmentId}/`,
    updatedData
  );
  return response.data; // Returns updated assignment
};

// Start an assignment
export const startAssignment = async ({ assignmentId, studentId }) => {
  const response = await api.post(
    `/api/assignments/assignments/${assignmentId}/start/`,
    {
      student_id: studentId,
    }
  );
  return response.data; // Returns assignment details and questions
};
