import React, { useState, useEffect } from "react";
import Tabs from "./../../Shared/Tabs";
import Sidebar from "./SideBar/SideBar";

import StudentProfile from "./StudentProfile";
import Books from "./Lessons/Books";
import Achievements from "./Achievements/Achievements";
import Assignments from "./Assignments/Assignments";
import { useMyStudent } from "./../../../hooks/StudentDashboard/useStudent";
import LoadingAnimation from "../../../utils/LoadingAnimation";

// Student Dashboard Component
const StudentDashboard = () => {
  const { data: myData, isLoading } = useMyStudent();
  const [selectedGroupId, setGroupId] = useState(null);

  // Set selectedGroupId after data has been loaded
  useEffect(() => {
    if (myData?.groups?.length > 0) {
      setGroupId(myData.groups[0].id);
    }
  }, [myData]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  // Tabs Content
  const tabs = [
    {
      key: "books",
      label: "Lessons",
      content: <Books />,
    },
    {
      key: "homework",
      label: "My homework",
      content: (
        <Assignments
          myId={myData?.id}
          myName={`${myData?.first_name || ""} ${
            myData?.last_name || ""
          }`.trim()}
          selectedGroupId={selectedGroupId}
          type={"homework"}
        />
      ),
    },
    {
      key: "classwork",
      label: "My classwork",
      content: (
        <Assignments
          myName={`${myData?.first_name || ""} ${
            myData?.last_name || ""
          }`.trim()}
          myId={myData?.id}
          selectedGroupId={selectedGroupId}
          type={"classwork"}
        />
      ),
    },
    {
      key: "achievements",
      label: "Achievements",
      content: <Achievements />,
    },

    {
      key: "profile",
      label: "My profile",
      content: <StudentProfile myData={myData} />,
    },
  ];

  // Render Student Dashboard
  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      {/* Sidebar Component */}
      <Sidebar
        myData={myData}
        selectedGroupId={selectedGroupId}
        setGroupId={setGroupId}
      />

      {/* Right Content */}
      <div
        style={{
          flex: 1,
          marginLeft: "300px", // Matches the fixed width of the sidebar
          padding: "20px",
          // backgroundColor: "#f5f5f5",
        }}
      >
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default StudentDashboard;
