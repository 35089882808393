import api from "../api";

// Fetch all groups
export const getGroups = async () => {
  const res = await api.get("/api/admin/groups/get_simple_list");
  return res.data;
};

export const getGroupById = async (groupId) => {
  const res = await api.get(`/api/admin/groups/${groupId}`);
  return res.data;
};

// **Add a new group**
export const addGroup = async (groupData) => {
  const response = await api.post("/api/admin/groups/", groupData);
  return response.data; // Returns the created group
};

// **Update a group**
export const updateGroup = async ({ id, groupData }) => {
  const response = await api.put(`/api/admin/groups/${id}/`, groupData);
  return response.data; // Returns the updated group
};

// **Delete a group**
export const deleteGroup = async (id) => {
  const response = await api.delete(`/api/admin/groups/${id}/`);
  return response.data; // Returns success message
};

// Attach categories to a group
export const attachCategoriesToGroup = async ({ groupId, categories }) => {
  try {
    const res = await api.post(
      `/api/admin/groups/${groupId}/attach_categories/`,
      { categories }
    );
    return res.data; // Return response data
  } catch (error) {
    console.error("API Error:", error.response || error.message); // Log error
    throw new Error(
      error.response?.data?.message || "Failed to attach categories"
    );
  }
};

// **Detach categories from a group** CREATE detach_categories view
export const detachCategoriesFromGroup = async ({ groupId, categories }) => {
  const res = await api.post(
    `/api/admin/groups/${groupId}/detach_categories/`, // API endpoint for detaching
    { categories } // Pass categories array in the request body
  );
  return res.data; // Returns success message
};

// Add multiple students to a group
export const addStudentsToGroup = async (groupId, data) => {
  const response = await api.post(
    `/api/admin/groups/${groupId}/add_students/`,
    data
  );
  return response.data;
};

// Remove multiple students from a group
export const removeStudentsFromGroup = async (groupId, data) => {
  const response = await api.post(
    `/api/admin/groups/${groupId}/remove_students/`,
    data
  );
  return response.data;
};
