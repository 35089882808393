import React from "react";
import {
  useFetchActiveAssignmentsByStudent,
  useFetchInactiveAssignmentsByStudent,
} from "./../../../../../../hooks/AdminDashboard/useAssignment";

const Assignments = ({ student }) => {
  // Fetch active and inactive assignments
  const { data: activeAssignments, isLoading: loadingActive } =
    useFetchActiveAssignmentsByStudent(student?.id);
  const { data: inactiveAssignments, isLoading: loadingInactive } =
    useFetchInactiveAssignmentsByStudent(student?.id);

  // Helper function to format the date
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options); // e.g., 10-April-2025
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    }); // 24-hour format, e.g., 14:30
    return `${formattedDate} ${formattedTime}`;
  };

  if (loadingActive || loadingInactive) {
    return <div className="text-center my-5">Loading assignments...</div>;
  }

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Assignments</h2>

      {/* Active Assignments */}
      <div className="mb-4">
        <h3 className="text-success">Active Assignments</h3>
        {activeAssignments && activeAssignments.length > 0 ? (
          <ul className="list-group">
            {activeAssignments.map((assignment) => (
              <li
                key={assignment.id}
                className="list-group-item d-flex flex-column align-items-start"
              >
                <div className="mb-2">
                  <h5 className="mb-1 text-primary">
                    {assignment.description}
                  </h5>
                  <p className="mb-1">
                    <strong>Deadline:</strong>{" "}
                    {formatDateTime(assignment.deadline)}
                  </p>
                  <p className="mb-1">
                    <strong>Created At:</strong>{" "}
                    {formatDateTime(assignment.created_at)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="alert alert-info">No active assignments found.</div>
        )}
      </div>

      {/* Inactive Assignments */}
      <div>
        <h3 className="text-danger">Inactive Assignments</h3>
        {inactiveAssignments && inactiveAssignments.length > 0 ? (
          <ul className="list-group">
            {inactiveAssignments.map((assignment) => (
              <li
                key={assignment.id}
                className="list-group-item d-flex flex-column align-items-start"
              >
                <div className="mb-2">
                  <h5 className="mb-1 text-primary">
                    {assignment.description}
                  </h5>
                  <p className="mb-1">
                    <strong>Deadline:</strong>{" "}
                    {formatDateTime(assignment.deadline)}
                  </p>
                  <p className="mb-1">
                    <strong>Created At:</strong>{" "}
                    {formatDateTime(assignment.created_at)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="alert alert-info">No inactive assignments found.</div>
        )}
      </div>
    </div>
  );
};

export default Assignments;
