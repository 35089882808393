function transformPayload(payload) {
  const skills = [
    "reading",
    "listening",
    "speaking",
    "writing",
    "grammar",
    "vocabulary",
  ];

  // Helper function to transform grammar and vocabulary settings
  const transformSkillSettings = (settings, skill) =>
    settings.map((setting) => ({
      skill,
      format: setting.format,
      quantity: parseInt(setting.quantity) || 0,
      random: false, // Assuming grammar/vocabulary doesn't have a "random" option
    }));

  // Transform grammar and vocabulary settings
  const settings = [
    ...transformSkillSettings(payload.grammar, "grammar"),
    ...transformSkillSettings(payload.vocabulary, "vocabulary"),
  ];

  // Add reading, listening, speaking, writing with specific/random flags
  ["reading", "listening", "speaking", "writing"].forEach((skill) => {
    if (payload[skill]) {
      settings.push({
        skill,
        format: null, // Assuming no specific format for these
        quantity: parseInt(payload[skill].quantity) || 0,
        random: payload[skill].random || false,
        specific_reading_tests:
          skill === "reading" ? payload[skill].specificTests || [] : [],
        specific_listening_tests:
          skill === "listening" ? payload[skill].specificTests || [] : [],
        specific_speaking_tests:
          skill === "speaking" ? payload[skill].specificTests || [] : [],
        specific_writing_tests:
          skill === "writing" ? payload[skill].specificTests || [] : [],
      });
    }
  });

  // Return the final transformed payload
  return {
    type: payload.type || "homework", // Default type to "homework"
    group_id: payload.group_id,
    assigned_to_students:
      payload.assignedTo === "Group" ? [] : payload.assigned_to_students,
    subcategories: payload.subcategories,
    description: payload.description,
    deadline: payload.deadline,
    time_limit: payload.time_limit || 600,
    start_time: payload.start_time,
    active: payload.active || true,
    settings,
  };
}

export default transformPayload;
