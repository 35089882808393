import React from "react";
import MonacoEditor from "@monaco-editor/react";

const QuestionEditor = ({
  editing,
  setEditing,
  currentQuestions,
  setCurrentQuestions,
  handleUpdateQuestion,
  handleDeleteQuestion,
  data,
  deleting,
  updating,
}) => {
  const handleSetEditing = () => {
    if (!data || data.length !== 1) {
      alert("Please select exactly one question!");
      return;
    }
    setCurrentQuestions(JSON.stringify(data, null, 2));
    setEditing(true);
  };
  return (
    <>
      <MonacoEditor
        height="600px"
        language="json"
        readOnly={!editing}
        value={editing ? currentQuestions : JSON.stringify(data, null, 2)}
        onChange={(value) => setCurrentQuestions(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end gap-2">
        {editing ? (
          <button
            className="btn btn-success mt-2"
            onClick={handleUpdateQuestion}
          >
            Save
          </button>
        ) : (
          <button
            disabled={updating}
            className="btn btn-warning mt-2"
            onClick={handleSetEditing}
          >
            {updating ? "saving... " : "edit"}
          </button>
        )}
        <button
          disabled={deleting}
          className="btn btn-danger mt-2"
          onClick={handleDeleteQuestion}
        >
          {deleting ? "deleting... " : "delete"}
        </button>
      </div>
    </>
  );
};

export default QuestionEditor;
