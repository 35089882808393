import React, { useState } from "react";
import Tabs from "../../../Shared/Tabs";

import StudentsList from "./StudentsTab/StudentsList";
import TeachersList from "./TeachersList";
import Assignments from "./AssignmentsTab/Assignments/Assignments";
import GroupsList from "./GroupsList/GroupsList";
import CategoriesList from "./CategoriesList";
import {
  useGroups,
  useGroup,
} from "../../../../hooks/AdminDashboard/useGroups";

const GroupsManagement = () => {
  // State for selected group
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { data, isLoading } = useGroups();
  const { data: fullGroupData, isLoading: groupLoading } = useGroup(
    selectedGroup?.id
  );

  // Tabs configuration
  const tabs = [
    {
      key: "students",
      label: "Students",
      content: (
        <StudentsList
          fullGroupData={fullGroupData}
          groupLoading={groupLoading}
        />
      ),
    },
    {
      key: "teachers",
      label: "Teachers",
      content: (
        <TeachersList
          fullGroupData={fullGroupData}
          groupLoading={groupLoading}
        />
      ),
    },
    {
      key: "categories",
      label: "Categories",
      content: (
        <CategoriesList
          fullGroupData={fullGroupData}
          groupLoading={groupLoading}
        />
      ),
    },
    {
      key: "assignments",
      label: "Group Assignments",
      content: (
        <Assignments
          fullGroupData={fullGroupData}
          groupLoading={groupLoading}
        />
      ),
    },
  ];

  if (isLoading) {
    return <>Loading ... plz wait</>;
  }

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row h-100">
        <GroupsList
          groups={data}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />

        {/* Main Content */}
        <div className="col-9">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default GroupsManagement;
