export const validateReadingTests = (tests) => {
  console.log("Validating tests:", tests);

  // Validate each test
  const isValid = tests.every((test, testIndex) => {
    console.log(`\nValidating Test ${testIndex + 1}:`, test);

    // Validate Test fields
    if (!test.name || !test.description) {
      console.error(
        `Test ${testIndex + 1} failed: Missing name or description.`
      );
      return false;
    }

    if (!Array.isArray(test.passages) || test.passages.length === 0) {
      console.error(
        `Test ${testIndex + 1} failed: Passages must be a non-empty array.`
      );
      return false;
    }

    // Validate each passage
    const passageValid = test.passages.every((passage, passageIndex) => {
      console.log(`  Validating Passage ${passageIndex + 1}:`, passage);

      // Validate Passage fields
      if (
        !passage.title ||
        !Array.isArray(passage.content) ||
        passage.content.length === 0 ||
        !passage.order
      ) {
        console.error(
          `Passage ${
            passageIndex + 1
          } failed: Missing title, content, or order.`
        );
        return false;
      }

      if (!Array.isArray(passage.sections) || passage.sections.length === 0) {
        console.error(
          `Passage ${
            passageIndex + 1
          } failed: Sections must be a non-empty array.`
        );
        return false;
      }

      // Validate each section
      const sectionValid = passage.sections.every((section, sectionIndex) => {
        console.log(`    Validating Section ${sectionIndex + 1}:`, section);

        // Validate Section fields
        if (!section.name || !section.instructions || !section.order) {
          console.error(
            `Section ${
              sectionIndex + 1
            } failed: Missing name, instructions, or order.`
          );
          return false;
        }

        if (
          !Array.isArray(section.questions) ||
          section.questions.length === 0
        ) {
          console.error(
            `Section ${
              sectionIndex + 1
            } failed: Questions must be a non-empty array.`
          );
          return false;
        }

        // Validate each question
        const questionValid = section.questions.every((q, questionIndex) => {
          console.log(`      Validating Question ${questionIndex + 1}:`, q);

          // Required fields
          if (!q.format || !q.text || !q.feedback || !q.order) {
            console.error(
              `Question ${
                questionIndex + 1
              } failed: Missing required fields: formar, text, feedback, or order.`
            );
            return false;
          }

          // Format-specific validation
          switch (q.format) {
            case "MCQ":
              if (!Array.isArray(q.choices) || q.choices.length === 0) {
                console.error(
                  `MCQ Question ${
                    questionIndex + 1
                  } failed: Choices must be an array.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length !== 1 ||
                !q.choices.includes(q.correct_answer[0])
              ) {
                console.error(
                  `MCQ Question ${
                    questionIndex + 1
                  } failed: Invalid correct answer.`
                );
                return false;
              }
              break;

            case "GAP_FILL":
              if (q.choices !== null) {
                console.error(
                  `GAP_FILL Question ${
                    questionIndex + 1
                  } failed: Choices must be null.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length === 0
              ) {
                console.error(
                  `GAP_FILL Question ${
                    questionIndex + 1
                  } failed: Invalid correct answer.`
                );
                return false;
              }
              break;

            case "MATCHING":
              if (
                !Array.isArray(q.choices) ||
                !Array.isArray(q.correct_answer)
              ) {
                console.error(
                  `MATCHING Question ${
                    questionIndex + 1
                  } failed: Choices and answers must be arrays.`
                );
                return false;
              }
              break;

            case "MULTI_SELECT":
              if (
                !Array.isArray(q.choices) ||
                !Array.isArray(q.correct_answer) ||
                !q.correct_answer.every((ans) => q.choices.includes(ans))
              ) {
                console.error(
                  `MULTI_SELECT Question ${
                    questionIndex + 1
                  } failed: Invalid answers.`
                );
                return false;
              }
              break;

            case "TRUE_FALSE_NOT_GIVEN":
              if (
                !Array.isArray(q.choices) ||
                (q.choices.length !== 2 && q.choices.length !== 3)
              ) {
                console.error(
                  `TRUE_FALSE_NOT_GIVEN Question ${
                    questionIndex + 1
                  } failed: Choices must contain exactly 2 or 3 options.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length !== 1
              ) {
                console.error(
                  `TRUE_FALSE_NOT_GIVEN Question ${
                    questionIndex + 1
                  } failed: Correct answer must be a single item.`
                );
                return false;
              }
              break;

            case "DIAGRAM_COMPLETION":
              if (
                !Array.isArray(q.choices) ||
                !Array.isArray(q.correct_answer)
              ) {
                console.error(
                  `DIAGRAM_COMPLETION Question ${
                    questionIndex + 1
                  } failed: Choices and answers must be arrays.`
                );
                return false;
              }
              break;

            default:
              console.error(
                `Question ${questionIndex + 1} failed: Unsupported format '${
                  q.format
                }'.`
              );
              return false;
          }

          return true; // Question is valid
        });

        return questionValid;
      });

      return sectionValid;
    });

    return passageValid;
  });

  console.log("Validation Result:", isValid);
  return isValid;
};
