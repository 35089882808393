const getQuestionRange = (section) => {
  if (!section || !section.parts || section.parts.length === 0) {
    return "No questions available";
  }

  // Extract all questions from the parts within the sections
  const questions = section.parts.flatMap((part) => part.questions);

  if (questions.length === 0) {
    return "No questions available";
  }

  // Get the first and last question numbers, sorted by their order
  const sortedQuestions = questions.sort((a, b) => a.order - b.order);
  const firstQuestion = sortedQuestions[0].order;
  const lastQuestion = sortedQuestions[sortedQuestions.length - 1].order;

  return `${firstQuestion} - ${lastQuestion}`;
};

export default getQuestionRange;
