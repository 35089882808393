import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import PopupMessage from "../../../../../Shared/PopupMessage";
import { validateWordExamples } from "./../../../../../../utils/validation";
import { useBulkUploadExamples } from "../../../../../../hooks/AdminDashboard/useWordExamples";

const ExamplesBulkUpload = ({ categoryId, subcategoryId, selectedWord }) => {
  const [jsonData, setJsonData] = useState(sampleExamples);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [popupMessageVisible, setPopupMessageVisible] = useState(false);

  const bulkUploadMutation = useBulkUploadExamples((res) => {
    setUploadErrors([]);
    setSuccessMessage("");

    if (res) {
      setSuccessMessage(res.message);
      setPopupMessageVisible(true);
    }

    if (res.errors && res.errors.length > 0) {
      setUploadErrors(res.errors);
    }
  });

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one example!");
      return;
    }
    try {
      const examplesData = JSON.parse(jsonData);
      if (!validateWordExamples(examplesData))
        throw new Error("Invalid example format.");
      bulkUploadMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord?.id,
        examplesData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add examples for {selectedWord?.name}</h4>

      <MonacoEditor
        height="400px"
        language="json"
        value={jsonData}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />

      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>

      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}

      {popupMessageVisible && (
        <PopupMessage
          onClose={() => setPopupMessageVisible(false)}
          message={successMessage}
        />
      )}
    </div>
  );
};

export default ExamplesBulkUpload;

// Sample JSON format for Examples
const sampleExamples = `[
  {
    "sentence": "How are you?",
    "translation": "Qandaysan?",
    "audio_url": "https://example.com/audio.mp3",
    "video_url": "https://example.com/video.mp4"
  },
  {
    "sentence": "What is your name?",
    "translation": "Isming nima?",
    "audio_url": "https://example.com/audio2.mp3",
    "video_url": "https://example.com/video2.mp4"
  }
]`;
