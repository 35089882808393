import React, { useState } from "react";
import Header from "./Header";
import Passage from "./Passage";
import Questions from "./Questions";
import Footer from "./Footer";
import extractQuestionsFromTest from "./extractQuestionsFromTest";
import { useSaveProgress } from "./../../../../../hooks/Progress/useProgress";
import createSubmissionPayload from "./createSubmissionPayload";
import { useAnswersManagement } from "./useAnswersManagement";
import { useQuery } from "@tanstack/react-query";
import extractTests from "../TestsComponent/extractTests";

const ReadingTest = ({ testId, assignmentId }) => {
  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false, // or enabled: !!assignmentId, but we have no queryFn
  });

  const attempt = data?.attempt;
  const tests = extractTests(attempt);
  const currentTest = tests.find((test) => {
    if (test.id === testId && test.type === "reading") return test;
  });
  const questions = extractQuestionsFromTest(currentTest);
  const isSubmitted = currentTest.isSubmitted;
  const attempt_id = data.attempt.id;

  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const { mutate: saveProgress, isPending: isSubmitting } =
    useSaveProgress(assignmentId);

  const { selectedAnswers, handleAnswerSelect, handleSubmit } =
    useAnswersManagement(questions);

  const handleNavigation = (direction) => {
    setCurrentPassageIndex((prevIndex) =>
      direction === "next"
        ? Math.min(prevIndex + 1, currentTest.passages.length - 1)
        : Math.max(prevIndex - 1, 0)
    );
  };

  const currentPassage = currentTest.passages[currentPassageIndex];

  const handleTestSubmit = () => {
    const payload = createSubmissionPayload(
      selectedAnswers,
      "reading",
      attempt_id,
      testId
    );

    handleSubmit(saveProgress, { assignmentId, progressData: payload });
  };

  return (
    <div className="reading-test-container">
      <Header
        passageInfo={currentPassage}
        score={currentTest.score}
        isSubmitted={isSubmitted}
      />
      <div className="reading-test-content d-flex">
        <Passage
          content={currentPassage.content}
          title={currentPassage.title}
          image={currentPassage.image}
        />
        <Questions
          sections={currentPassage.sections}
          isSubmitted={isSubmitted}
          answers={currentTest.answers}
          handleAnswerSelect={handleAnswerSelect}
        />
      </div>

      <Footer
        handleSubmit={handleTestSubmit}
        currentPassageIndex={currentPassageIndex}
        totalPassages={currentTest.passages.length}
        onNavigate={handleNavigation}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ReadingTest;
