import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchSimpleListeningTests,
  fetchDetailedListeningTest,
  bulkUploadListeningTests,
  bulkUpdateListeningTest,
  deleteListeningTest,
  updateListeningTest,
} from "../../api/adminAPI/listeningAPI";

// =============================
// 1. Fetch Simple Tests List
// =============================
export const useFetchSimpleListeningTests = (subcategoryId) =>
  useQuery({
    queryKey: ["simpleListeningTests", subcategoryId],
    queryFn: () => fetchSimpleListeningTests(subcategoryId),
    enabled: !!subcategoryId, // Ensure subcategoryId is present before fetching
  });

// =============================
// 2. Fetch Detailed Test
// =============================
export const useFetchDetailedListeningTest = (subcategoryId, testId) =>
  useQuery({
    queryKey: ["detailedListeningTest", subcategoryId, testId],
    queryFn: () => fetchDetailedListeningTest(subcategoryId, testId),
    enabled: !!subcategoryId && !!testId, // Ensure both IDs are present
  });

// =============================
// 3. Bulk Upload Tests
// =============================
export const useBulkUploadListeningTests = (subcategoryId) => {
  const queryClient = useQueryClient(); // React Query cache management

  return useMutation({
    mutationFn: (data) => bulkUploadListeningTests(subcategoryId, data),
    onSuccess: () => {
      // Invalidate cache to refresh the tests list
      queryClient.invalidateQueries(["simpleListeningTests", subcategoryId]);
      alert("Tests added successfully!");
    },
  });
};

// =============================
// 4. Bulk Update Tests
// =============================
export const useBulkUpdateListeningTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ data, testId, subcategoryId }) =>
      bulkUpdateListeningTest(subcategoryId, testId, data),
    onSuccess,
  });
};

// =============================
// 5. Delete a Test
// =============================
export const useDeleteListeningTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ testId, subcategoryId }) =>
      deleteListeningTest(subcategoryId, testId),
    onSuccess,
  });
};

// =============================
// 6. Update a Single Test
// =============================
export const useUpdateListeningTest = (subcategoryId, testId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedData) =>
      updateListeningTest(subcategoryId, testId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "detailedListeningTest",
        subcategoryId,
        testId,
      ]); // Refresh updated test
      queryClient.invalidateQueries(["simpleListeningTests", subcategoryId]); // Refresh list
    },
  });
};
