import React from "react";

const HollowCircularScore = ({ score }) => {
  const calculateRotation = () => {
    return (score / 100) * 360;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Outer Circle */}
      <div
        style={{
          position: "relative",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          background: `conic-gradient(
            green ${calculateRotation()}deg,
            lightgray ${calculateRotation()}deg
          )`,
        }}
      >
        {/* Inner Circle for Hollow Effect */}
        <div
          style={{
            position: "absolute",
            top: "15%",
            left: "15%",
            width: "70%",
            height: "70%",
            background: "white",
            borderRadius: "50%",
          }}
        ></div>
        {/* Score Text */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // fontWeight: "bold",
            // fontSize: "1.2rem",
            color: "green",
          }}
        >
          {score || 0}
        </div>
      </div>
    </div>
  );
};

export default HollowCircularScore;
