import React, { useState } from "react";
import highlightWordInExample from "./HighlightWordInExample";

const WORD_EXAMPLE_SENTENCE = ({
  questionNumber,
  questionData,
  answer,
  isSubmitted,
  handleAnswerSelect,
}) => {
  const [userAnswer, setUserAnswer] = useState(answer?.selectedAnswer || "");

  function handleAnswerChange(e) {
    const value = e.target.value;
    setUserAnswer(value);
    handleAnswerSelect(
      questionData.word_id,
      value.trim(),
      questionData.format,
      questionData.word,
      questionData.example,
      questionData.sentence
    );
  }

  return (
    <div className="mb-4 border rounded shadow-sm p-3">
      <p className="fw-semibold">
        {questionNumber}.{" "}
        {questionData.format === "SENTENCE" && questionData.sentence}
        {questionData.format === "WORD" && questionData.word}
        {questionData.format === "WORD_AND_EXAMPLE" && (
          <>
            {questionData.example
              ? highlightWordInExample(questionData.example, questionData.word)
              : "No example available"}
          </>
        )}
      </p>
      <input
        id={`${questionData.format}-${questionData.word_id}`} // Unique ID per question
        name={`${questionData.format}-${questionData.word_id}`} // Unique name
        type="text"
        className={`form-control mb-3`}
        value={userAnswer}
        onChange={(e) => handleAnswerChange(e)}
        placeholder="Enter the translation"
        disabled={isSubmitted}
      />

      {/* Display Feedback for Incorrect Submission */}
      {isSubmitted && !answer?.is_correct && (
        <>
          <p className="mt-2 text-success">
            <em>{answer.feedback}</em>
          </p>
        </>
      )}
    </div>
  );
};

export default WORD_EXAMPLE_SENTENCE;
