import { useState } from "react";

export const useAnswersManagement = (questions) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const handleAnswerSelect = (questionId, answer) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const existingAnswerIndex = prevSelectedAnswers.findIndex(
        (entry) => entry.questionId === questionId
      );

      if (existingAnswerIndex > -1) {
        const updatedAnswers = [...prevSelectedAnswers];
        updatedAnswers[existingAnswerIndex].answer = answer;
        return updatedAnswers;
      } else {
        return [...prevSelectedAnswers, { questionId, answer }];
      }
    });
  };

  const handleSubmit = (saveProgress, payload) => {
    saveProgress(payload);
  };

  return {
    selectedAnswers,
    handleAnswerSelect,
    handleSubmit,
  };
};
