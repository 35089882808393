import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchSubcategories,
  addSubcategory,
  updateSubcategory,
  deleteSubcategory,
} from "@api/adminAPI";

// Fetch subcategories for a category
export const useSubcategories = (categoryId) =>
  useQuery({
    queryKey: ["subcategories", categoryId],
    queryFn: () => fetchSubcategories(categoryId),
    enabled: !!categoryId, // Fetch only if categoryId exists
  });

// Add a new subcategory
export const useAddSubcategory = (onSuccess) =>
  useMutation({
    mutationFn: ({ categoryId, subcategoryData }) =>
      addSubcategory({ categoryId, subcategoryData }),
    onSuccess,
  });
export const useUpdateSubcategory = (onSuccess) => {
  return useMutation({
    mutationFn: ({ categoryId, subcategoryId, subcategoryData }) =>
      updateSubcategory({ categoryId, subcategoryId, subcategoryData }),
    onSuccess: onSuccess,
  });
};

// **Delete a subcategory**
export const useDeleteSubcategory = (onSuccess) =>
  useMutation({
    mutationFn: (id) => deleteSubcategory(id),
    onSuccess,
  });
