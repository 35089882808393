import React, { useState } from "react";
import {
  useFetchActiveAssignmentsByGroup,
  useDeactivateAssignment,
  useDeleteAssignment,
} from "./../../../../../../hooks/AdminDashboard/useAssignment";

import AssignmentList from "./AssignmentsList";
import ProgressList from "../ProgressList/ProgressList";

const ActiveAssignments = ({ groupId }) => {
  const { data: activeAssignmentsByGroup, isLoading } =
    useFetchActiveAssignmentsByGroup(groupId);

  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);

  const deactivateMutation = useDeactivateAssignment();
  const deleteMutation = useDeleteAssignment();

  const handleDelete = (assignment) => {
    if (window.confirm("Are you sure?")) {
      deleteMutation.mutate(assignment.id);
    }
  };

  const handleDeactivate = (assignment) => {
    if (window.confirm("Are you sure?")) {
      deactivateMutation.mutate(assignment.id);
    }
  };

  return (
    <div className="container mt-4">
      {/* If an assignment is selected, show ProgressList. Otherwise, show AssignmentList */}
      {selectedAssignmentId ? (
        <>
          <button
            className="btn btn-secondary mb-3"
            onClick={() => setSelectedAssignmentId(null)}
          >
            ← Back to Assignments
          </button>
          <ProgressList selectedAssignmentId={selectedAssignmentId} />
        </>
      ) : (
        <AssignmentList
          setSelectedAssignmentId={setSelectedAssignmentId}
          title="Active Assignments"
          assignments={activeAssignmentsByGroup}
          isLoading={isLoading}
          onPrimaryAction={handleDeactivate}
          onDelete={handleDelete}
          primaryActionLabel="Deactivate"
        />
      )}
    </div>
  );
};

export default ActiveAssignments;
