import React from "react";

const GroupDetails = ({ selectedGroup }) => {
  return (
    <div
      className="p-3"
      style={{
        backgroundColor: "#f7f7f7",
        borderTop: "1px solid #e0e0e0",
        marginTop: "1rem",
        paddingTop: "1rem",
      }}
    >
      <h6
        className="mb-3"
        style={{ fontWeight: 500, fontFamily: "Roboto, sans-serif" }}
      >
        Group Details
      </h6>
      <div className="d-flex flex-column gap-3">
        {/* Class Days Block */}
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#e3f2fd",
            padding: "0.5rem 1rem",
            // Using sharp edges: no borderRadius added
            border: "1px solid #90caf9",
          }}
        >
          <span style={{ fontSize: "1.2rem", marginRight: "0.75rem" }}>📅</span>
          <div>
            <div
              style={{
                fontSize: "0.8rem",
                color: "#1565c0",
                fontWeight: 600,
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Class Days
            </div>
            <div
              style={{
                fontSize: "0.9rem",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              {selectedGroup.class_days
                ? selectedGroup.class_days.join(", ")
                : "Not Available"}
            </div>
          </div>
        </div>

        {/* Start Time Block */}
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#e8f5e9",
            padding: "0.5rem 1rem",
            border: "1px solid #a5d6a7",
          }}
        >
          <span style={{ fontSize: "1.2rem", marginRight: "0.75rem" }}>⏰</span>
          <div>
            <div
              style={{
                fontSize: "0.8rem",
                color: "#2e7d32",
                fontWeight: 600,
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Start Time
            </div>
            <div
              style={{
                fontSize: "0.9rem",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              {selectedGroup.start_time
                ? selectedGroup.start_time.slice(0, 5)
                : "Not Available"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
