import React from "react";

import TeachersManagement from "./TeachersManagement/TeachersManagement";
import CategoriesManagement from "./CategoriesManagement/CategoriesManagement";
import GroupsManagement from "./GroupsManagement/GroupsManagement";
import StudentsManagement from "./StudentsManagement/StudentsManagement";
import Tabs from "./../../Shared/Tabs";

const AdminDashboard = () => {
  const tabs = [
    {
      key: "categories",
      label: "Categories Management",
      content: <CategoriesManagement />,
    },
    {
      key: "groups",
      label: "Groups Management",
      content: <GroupsManagement />,
    },
    {
      key: "students",
      label: "Students Management",
      content: <StudentsManagement />,
    },
    {
      key: "teachers",
      label: "Teachers Management",
      content: <TeachersManagement />,
    },
  ];

  return (
    <div className="mx-auto max-w-5xl px-4">
      <h2 className="text-center mb-4" style={{ color: "#2a623d" }}>
        Admin Dashboard
      </h2>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default AdminDashboard;
