import React from "react";
import GrammarContent from "./GrammarContent/GrammarContent";
import VocabularyContent from "./VocabularyContent/VocabularyContent";
import ListeningContent from "./ListeningContent/ListeningContent";
import ReadingContent from "./ReadingContent/ReadingContent";
import SpeakingContent from "./SpeakingContent/SpeakingContent";
import WritingContent from "./WritingContent/WritingContent";
import Tabs from "./../../../../Shared/Tabs";

const ContentRenderer = ({
  categoryId,
  selectedSubcategoryId,
  setSelectedSubcategoryId,
}) => {
  return (
    <div className=" mt-4">
      <button
        className="btn btn-secondary mb-3"
        onClick={() => setSelectedSubcategoryId(null)}
      >
        Back
      </button>

      <Tabs
        tabs={[
          {
            key: "grammar",
            label: "Grammar",
            content: (
              <GrammarContent
                categoryId={categoryId}
                subcategoryId={selectedSubcategoryId}
              />
            ),
          },
          {
            key: "vocabulary",
            label: "Vocabulary",
            content: (
              <VocabularyContent
                categoryId={categoryId}
                subcategoryId={selectedSubcategoryId}
              />
            ),
          },
          {
            key: "listening",
            label: "Listening",
            content: <ListeningContent subcategoryId={selectedSubcategoryId} />,
          },
          {
            key: "reading",
            label: "Reading",
            content: (
              <ReadingContent
                categoryId={categoryId}
                subcategoryId={selectedSubcategoryId}
              />
            ),
          },
          {
            key: "speaking",
            label: "Speaking",
            content: (
              <SpeakingContent
                categoryId={categoryId}
                subcategoryId={selectedSubcategoryId}
              />
            ),
          },
          {
            key: "writing",
            label: "Writing",
            content: (
              <WritingContent
                categoryId={categoryId}
                subcategoryId={selectedSubcategoryId}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default ContentRenderer;
