import api from "../api";

export const bulkUploadReadingTests = async (subcategoryId, data) => {
  console.log(" data ===== ", data); // Debugging the data being sent
  const response = await api.post(
    `/api/admin/subcategory/${subcategoryId}/reading-tests/bulk_create/`,
    data
  );
  return response.data;
};

export const bulkUpdateReadingTest = async (subcategoryId, testId, data) => {
  console.log(" data ===== ", data); // Debugging the data being sent
  const response = await api.put(
    `/api/admin/subcategory/${subcategoryId}/reading-tests/${testId}/update_test/`,
    data
  );
  return response.data;
};

export const fetchSimpleReadingTests = async (subcategoryId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/reading-tests/`
  );
  return response.data;
};

export const fetchReadingTestById = async (subcategoryId, testId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/reading-tests/${testId}/`
  );
  return response.data;
};

export const deleteReadingTest = async (subcategoryId, testId) => {
  const response = await api.delete(
    `/api/admin/subcategory/${subcategoryId}/reading-tests/${testId}/`
  );
  return response.data;
};
