import { useState, React } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUpdateWord,
  useDeleteWord,
} from "../../../../../../hooks/AdminDashboard/useWords";

const Word = ({ categoryId, subcategoryId, selectedWord }) => {
  const [editing, setEditing] = useState(false); // Toggle editing mode
  const [currentWord, setCurrentWord] = useState(""); // Word details for editing
  const [currentWordDescription, setCurrentWordDescription] = useState(""); // Word details for editing
  const queryClient = useQueryClient();

  function handleEdit() {
    if (!selectedWord) {
      alert("select a word plz!");
      return;
    }
    setCurrentWord(selectedWord.name);
    setCurrentWordDescription(selectedWord.description);
    setEditing(true);
  }

  const deleteWordMutation = useDeleteWord(() => {
    queryClient.invalidateQueries(["words", categoryId, subcategoryId]);
    setCurrentWord("");
    setCurrentWordDescription("");
    alert("Word deleted successfully!");
  });

  function handleDelete() {
    if (!selectedWord) {
      alert("Select a word plz!");
      return;
    }

    const confirm = window.confirm(
      "Are you sure you want to delete this word with all its questions?"
    );

    if (confirm) {
      deleteWordMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
      });
    }
  }

  const updateWordMutation = useUpdateWord(() => {
    queryClient.invalidateQueries(["words", categoryId, subcategoryId]);
    alert("Word updated successfully!");
  });

  function handleSave() {
    if (!currentWord || !currentWordDescription) {
      alert("Word and description should not be empty!");
      return;
    }

    const confirm = window.confirm(
      "Are you sure you want to update this word?"
    );
    if (confirm) {
      updateWordMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord.id,
        wordData: { name: currentWord, description: currentWordDescription },
      });
    }
    setEditing(false);
    console.log("save");
  }

  return (
    <div>
      {/* Word Name */}
      <input
        type="text"
        value={editing ? currentWord : selectedWord?.name || ""}
        readOnly={!editing}
        onChange={(e) => setCurrentWord(e.target.value)}
        className="form-control mb-3"
      />

      {/* Word Description */}
      <textarea
        className="form-control mb-3"
        rows="3"
        readOnly={!editing}
        value={
          editing ? currentWordDescription : selectedWord?.description || ""
        } // Fixed the property name
        onChange={(e) => setCurrentWordDescription(e.target.value)}
      />

      {/* Action Buttons */}
      <div className="d-flex justify-content-end gap-2 mt-3">
        {editing ? (
          <button className="btn btn-success" onClick={handleSave}>
            Save
          </button>
        ) : (
          <button
            disabled={updateWordMutation.isPending}
            className="btn btn-warning"
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
        <button
          disabled={deleteWordMutation.isPending}
          className="btn btn-danger"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default Word;
