import { useMutation } from "@tanstack/react-query";
import { loginUser } from "../../api/authAPI"; // Adjust the path based on your file structure

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: loginUser, // Use the loginUser function from your API call
    onSuccess: (data) => {
      // Store authentication status in query cache
      queryClient.setQueryData(["authStatus"], data);

      navigate("/user-dashboard");
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
  });

  return {
    login: mutation.mutate, // Call this function to trigger the login
    isLoginPending: mutation.isPending,
    isError: mutation.isError,
    error:
      mutation?.error?.response?.data?.non_field_errors?.[0] ||
      mutation?.error?.message ||
      "Something went wrong",
    data: mutation.data,
  };
};
