import React from "react";
import ScoreDisplay from "./ScoreDisplay";
import GroupDetails from "./GroupDetails";

const Sidebar = ({ myData, selectedGroupId, setGroupId }) => {
  const groups = myData.groups || [];
  const selectedGroup =
    groups.find((group) => group.id === selectedGroupId) || groups[0] || {};

  return (
    <div
      className="d-flex flex-column position-fixed"
      style={{
        width: "300px",
        height: "100vh",
        padding: "1.5rem",
        backgroundColor: "#f7f7f7", // Light gray background (chat-sidebar style)
        borderRight: "1px solid #e0e0e0",
        boxShadow: "2px 0 8px rgba(0, 0, 0, 0.05)",
      }}
    >
      {/* Student Info */}
      <div
        className="text-center mb-4 p-3"
        style={{
          backgroundColor: "#f7f7f7",
          borderBottom: "1px solid #e0e0e0",
          marginBottom: "1rem",
        }}
      >
        {myData.photo && (
          <img
            src={myData.photo}
            alt="Profile"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "0.5rem",
            }}
          />
        )}
        <h1
          className="h6 text-success mb-2"
          style={{ fontWeight: 500, fontFamily: "Roboto, sans-serif" }}
        >
          {myData.first_name} {myData.last_name}
        </h1>
        <h3
          className="h6 text-muted"
          style={{ fontStyle: "normal", fontFamily: "Roboto, sans-serif" }}
        >
          {selectedGroup.name || "No Group"}
        </h3>
      </div>

      {/* Group Selection */}
      <div
        className="mb-4 p-3"
        style={{
          backgroundColor: "#f7f7f7",
          borderBottom: "1px solid #e0e0e0",
          marginBottom: "1rem",
        }}
      >
        <h6
          className="mb-3"
          style={{ fontWeight: 500, fontFamily: "Roboto, sans-serif" }}
        >
          Select Group
        </h6>
        {groups.length > 0 ? (
          groups.map((group) => (
            <div className="form-check" key={group.id}>
              <input
                className="form-check-input"
                type="radio"
                name="group"
                id={`group-${group.id}`}
                value={group.id}
                checked={selectedGroup.id === group.id}
                onChange={() => setGroupId(group.id)}
              />
              <label
                className="form-check-label"
                htmlFor={`group-${group.id}`}
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                {group.name}
              </label>
            </div>
          ))
        ) : (
          <p
            style={{
              fontSize: "0.9rem",
              color: "#757575",
              fontFamily: "Roboto, sans-serif",
            }}
          >
            No groups available
          </p>
        )}
      </div>

      <GroupDetails selectedGroup={selectedGroup} />
    </div>
  );
};

export default Sidebar;
