function extractQuestionsFromTest(test) {
  const questions = [];

  if (test.sections && Array.isArray(test.sections)) {
    test.sections.forEach((section) => {
      if (section.parts && Array.isArray(section.parts)) {
        section.parts.forEach((part) => {
          if (part.questions && Array.isArray(part.questions)) {
            part.questions.forEach((question) => {
              questions.push({
                id: question.id,
                format: question.format,
                text: question.text,
                choices: question.choices || null,
                correct_answer: question.correct_answer || null,
              });
            });
          }
        });
      }
    });
  }

  return questions;
}

export default extractQuestionsFromTest;
