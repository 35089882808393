import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import { useBulkUploadQuestions } from "../../../../../../hooks/AdminDashboard/useWords";
import { validateWordQuestions } from "../../../../../../utils/validation";
import PopupMessage from "./../../../../../Shared/PopupMessage";

const QuestionsBulkupload = ({ categoryId, subcategoryId, selectedWord }) => {
  const [jsonData, setJsonData] = useState(sampleQuestions);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(""); // For success message
  const [popupMessageVisible, setPopupMessageVisible] = useState(false);

  const bulkUploadMutation = useBulkUploadQuestions((res) => {
    // Reset states before processing
    setUploadErrors([]);
    setSuccessMessage("");
    // Handle success
    if (res) {
      setSuccessMessage(res.message); // Store success message
      setPopupMessageVisible(true);
    }

    // Handle errors
    if (res.errors && res.errors.length > 0) {
      setUploadErrors(res.errors); // Store error details
    }
  });

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one question!");
      return;
    }
    try {
      const questionsData = JSON.parse(jsonData);
      if (!validateWordQuestions(questionsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        categoryId,
        subcategoryId,
        wordId: selectedWord?.id,
        questionsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add questions for {selectedWord?.name}</h4>

      <MonacoEditor
        height="600px"
        language="json"
        value={jsonData}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />

      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
      {popupMessageVisible && (
        <PopupMessage
          onClose={() => {
            setPopupMessageVisible(false);
          }}
          message={successMessage}
        />
      )}
    </div>
  );
};

export default QuestionsBulkupload;

const sampleQuestions = `[
    {
      "format": "MCQ", 
      "text": "What is the largest planet in our solar system?",
      "choices": ["Earth", "Jupiter", "Mars", "Venus"],
      "correct_answer": ["Jupiter"],
      "feedback": "Jupiter is the largest planet in our solar system."
    },
    {
      "format": "GAP_FILL", 
      "text": "The Earth revolves around the ___.",
      "choices": null,  
      "correct_answer": ["Sun"],
      "feedback": "The Earth revolves around the Sun."
    },
    {
      "format": "MATCHING",  
      "text": "Match the countries with their capitals.",
      "choices": [
        {"country": "France", "capital": "Paris"},
        {"country": "Germany", "capital": "Berlin"},
        {"country": "Italy", "capital": "Rome"}
      ],
      "correct_answer": [
        {"country": "France", "capital": "Paris"},
        {"country": "Germany", "capital": "Berlin"},
        {"country": "Italy", "capital": "Rome"}
      ],
      "feedback": "Match the countries to their capitals correctly."
    },
    {
      "format": "MULTI_SELECT",
      "text": "Which of the following are programming languages?",
      "choices": ["Python", "Java", "HTML", "CSS"],
      "correct_answer": ["Python", "Java"],
      "feedback": "Python and Java are programming languages."
    }
  ]`;
