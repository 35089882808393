import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/auth/useAuth";
import LoadingAnimation from "../utils/LoadingAnimation";

import { disableUserActions } from "../disableUserActions";

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const { isAuthenticated, role, loading } = useAuth();

  if (loading) return <LoadingAnimation />;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  disableUserActions(role);

  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
