import api from "../api";

export const fetchExamples = async ({
  categoryId,
  subcategoryId,
  wordId,
  search = "",
}) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/examples/?search=${search}`
  );
  return response.data;
};

export const bulkUploadExamples = async ({
  categoryId,
  subcategoryId,
  wordId,
  examplesData,
}) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/examples/bulk_upload/`,
    { examples: examplesData }
  );
  return response.data;
};

export const updateExample = async ({
  categoryId,
  subcategoryId,
  wordId,
  exampleId,
  exampleData,
}) => {
  const response = await api.put(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/examples/${exampleId}/`,
    exampleData
  );
  return response.data;
};

export const deleteExample = async ({
  categoryId,
  subcategoryId,
  wordId,
  exampleId,
}) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/examples/${exampleId}/`
  );
  return response.data;
};

// **Fetch Count of Word Examples**
export const fetchExamplesCount = async ({
  categoryId,
  subcategoryId,
  wordId,
}) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/examples/count/`
  );
  return response.data;
};
