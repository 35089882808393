import React, { useState } from "react";
import { useStudentProgress } from "../../../../hooks/Progress/useProgress";
import AssignmentCard from "./AssignmentCard";
import AssignmentModal from "./AssignmentModel";

const Achievements = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, error } = useStudentProgress(page);

  const [selectedAssignment, setSelectedAssignment] = useState(null);

  if (isLoading)
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>Loading...</div>
    );
  if (error)
    return (
      <div style={{ textAlign: "center", marginTop: "2rem", color: "red" }}>
        Error: {error.message}
      </div>
    );

  const results = data?.results || [];

  const handleCardClick = (assignment) => {
    setSelectedAssignment(assignment);
  };

  const handleCloseModal = () => {
    setSelectedAssignment(null);
  };

  const hasNext = data.next !== null;
  const hasPrevious = data.previous !== null;

  return (
    <div style={{ padding: "1rem", fontFamily: "Arial, sans-serif" }}>
      {/* Assignment Cards */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1.5rem",
          marginTop: "1rem",
          justifyContent: "center",
        }}
      >
        {results.map((assignment) => (
          <AssignmentCard
            key={assignment.id}
            assignmentData={assignment}
            onClick={() => handleCardClick(assignment)}
          />
        ))}
      </div>

      {/* Assignment Modal */}
      {selectedAssignment && (
        <AssignmentModal
          assignmentData={selectedAssignment}
          onClose={handleCloseModal}
        />
      )}

      {/* Pagination */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={!hasPrevious}
          className="btn btn-sm btn-light"
        >
          Previous
        </button>
        <button
          onClick={() => setPage((prev) => prev + 1)}
          disabled={!hasNext}
          className="btn btn-sm btn-light"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Achievements;
