const Footer = ({ currentTaskIndex, totalTasks, onNavigate }) => {
  return (
    <div className="footer bg-light py-2 px-3 d-flex justify-content-between align-items-center border-top">
      {/* Task info on the left */}
      <span>
        Task {currentTaskIndex + 1} of {totalTasks}
      </span>

      {/* Navigation buttons on the right */}
      <div className="ms-auto d-flex">
        <button
          className="btn btn-warning me-2"
          onClick={() => onNavigate("back")}
          disabled={currentTaskIndex === 0}
        >
          &larr; Previous Task
        </button>
        <button
          className="btn btn-warning"
          onClick={() => onNavigate("next")}
          disabled={currentTaskIndex === totalTasks - 1}
        >
          Next Task &rarr;
        </button>
      </div>
    </div>
  );
};

export default Footer;
