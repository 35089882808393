import getQuestionRange from "./getQuestionRange";
import HollowCircularScore from "../WritingTest/HollowCircularScore";

const Header = ({ passageInfo, score, isSubmitted }) => {
  return (
    <div className="header bg-light py-1 px-2 border-bottom d-flex justify-content-between align-items-center">
      {/* Left content */}
      <div>
        <h4 className="m-1">Part {passageInfo.order}</h4>
        <p className="text-muted m-1">
          Read the text below and answer questions{" "}
          {getQuestionRange(passageInfo)}
        </p>
      </div>

      {/* Right content (Score component) */}
      {isSubmitted && (
        <div>
          <HollowCircularScore score={score} />
        </div>
      )}
    </div>
  );
};

export default Header;
