import React from "react";

const AssignToStudents = ({
  assignedTo,
  setAssignedTo,
  selectedStudents,
  setSelectedStudents,
  fullGroupData,
}) => {
  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  return (
    <div>
      <h3>Assign To</h3>
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          value="Group"
          checked={assignedTo === "Group"}
          onChange={() => setAssignedTo("Group")}
        />
        <label className="form-check-label">Group</label>
      </div>
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          value="Individual"
          checked={assignedTo === "Individual"}
          onChange={() => setAssignedTo("Individual")}
        />
        <label className="form-check-label">Individual Students</label>
      </div>

      {assignedTo === "Individual" && (
        <div className="mt-3">
          <h5>Select Students</h5>
          {fullGroupData.students.map((student) => (
            <div key={student.id} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={selectedStudents.includes(student.id)}
                onChange={() => handleStudentSelection(student.id)}
              />
              <label className="form-check-label">{student.first_name}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssignToStudents;
