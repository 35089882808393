const getQuestionRange = (passage) => {
  if (!passage || !passage.sections || passage.sections.length === 0) {
    return "No questions available";
  }

  // Extract all questions from the sections
  const questions = passage.sections.flatMap((section) => section.questions);

  if (questions.length === 0) {
    return "No questions available";
  }

  // Get the first and last question numbers
  const firstQuestion = questions[0].order;
  const lastQuestion = questions[questions.length - 1].order;

  return `${firstQuestion} - ${lastQuestion}`;
};

export default getQuestionRange;
