import ActiveAssignments from "./ActiveAssignments";
import InactiveAssignments from "./InactiveAssignments";
import Review from "../Review";
import Settings from "../Settings";
import CreateAssignment from "../CreateAssignment/CreateAssignment";
import Tabs from "../../../../../Shared/Tabs";

const Assignments = ({ fullGroupData, groupLoading }) => {
  const tabs = [
    {
      key: "active",
      label: "Active",
      content: <ActiveAssignments groupId={fullGroupData?.id} />,
    },
    {
      key: "inactive",
      label: "Inactive",
      content: <InactiveAssignments groupId={fullGroupData?.id} />,
    },
    {
      key: "creat",
      label: "+ Create",
      content: (
        <CreateAssignment
          fullGroupData={fullGroupData}
          groupLoading={groupLoading}
        />
      ),
    },
    {
      key: "review",
      label: "Review",
      content: <Review />,
    },
    {
      key: "settings",
      label: "Settings",
      content: <Settings />,
    },
  ];

  // if (isLoading) {
  //   return <>Loading ... plz wait</>;
  // }

  return <Tabs tabs={tabs} />;
};

export default Assignments;
