import React, { useState } from "react";
import {
  useFetchInactiveAssignmentsByGroup,
  useActivateAssignment,
  useDeleteAssignment,
} from "../../../../../../hooks/AdminDashboard/useAssignment";
import AssignmentList from "./AssignmentsList";
import ProgressList from "../ProgressList/ProgressList";

const InactiveAssignments = ({ groupId }) => {
  const { data: inactiveAssignmentsByGroup, isLoading } =
    useFetchInactiveAssignmentsByGroup(groupId);

  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);

  const activateMutation = useActivateAssignment();
  const deleteMutation = useDeleteAssignment();

  const handleDelete = (assignment) => {
    if (window.confirm("Are you sure?")) {
      deleteMutation.mutate(assignment.id);
    }
  };

  const handleActivate = (assignment) => {
    if (window.confirm("Are you sure?")) {
      activateMutation.mutate(assignment.id);
    }
  };

  return (
    <div className="container mt-4">
      {selectedAssignmentId ? (
        <>
          <button
            className="btn btn-secondary mb-3"
            onClick={() => setSelectedAssignmentId(null)}
          >
            ← Back to Assignments
          </button>
          <ProgressList selectedAssignmentId={selectedAssignmentId} />
        </>
      ) : (
        <AssignmentList
          setSelectedAssignmentId={setSelectedAssignmentId}
          title="Inactive Assignments"
          assignments={inactiveAssignmentsByGroup}
          isLoading={isLoading}
          onPrimaryAction={handleActivate}
          onDelete={handleDelete}
          primaryActionLabel="Activate"
        />
      )}
    </div>
  );
};

export default InactiveAssignments;
