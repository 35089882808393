import React from "react";

const StudentRow = ({ student, onClick }) => {
  return (
    <li
      className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div>
        <strong className="text-primary">
          {student.first_name} {student.last_name}
        </strong>
        {/* <p className="mb-0 text-muted">
          Date of Birth: {student.date_of_birth || "N/A"} <br />
          School: {student.school || "N/A"}
        </p> */}
      </div>
      <span className="badge bg-info rounded-pill">View</span>
    </li>
  );
};

export default StudentRow;
