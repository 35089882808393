import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useBulkDeleteStudents,
  useStudentsWithoutGroups, // ← Assume this hook now returns all students
  useUpdateStudent,
} from "../../../../hooks/AdminDashboard/useStudent";

import {
  useAddStudentsToGroup,
  useRemoveStudentsFromGroup,
  useGroups,
} from "../../../../hooks/AdminDashboard/useGroups";

import LoadingAnimation from "./../../../../utils/LoadingAnimation";

const UnassignedStudents = () => {
  const queryClient = useQueryClient();
  // Assume this hook returns all students (both with and without groups)
  const { data: students = [], isLoading: studentsLoading } =
    useStudentsWithoutGroups();
  const { data: groups = [], isLoading: loadingGroups } = useGroups();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [editingStudentId, setEditingStudentId] = useState(null);
  const [editData, setEditData] = useState({}); // Stores editable fields

  // NEW: Filter state variables
  const [filterAssignmentStatus, setFilterAssignmentStatus] = useState("all");
  const [filterGroup, setFilterGroup] = useState("");
  const [filterBirthYear, setFilterBirthYear] = useState("");
  const [filterSchool, setFilterSchool] = useState("");
  const [filterEnrollmentFrom, setFilterEnrollmentFrom] = useState("");
  const [filterEnrollmentTo, setFilterEnrollmentTo] = useState("");

  // NEW: Create a filtered list of students based on all filters
  const filteredStudents = students.filter((student) => {
    // Filter by assignment status
    let assignmentStatusMatch = true;
    if (filterAssignmentStatus === "unassigned") {
      assignmentStatusMatch = !student.groups || student.groups.length === 0;
    } else if (filterAssignmentStatus === "assigned") {
      assignmentStatusMatch = student.groups && student.groups.length > 0;
    }

    // Filter by specific group if selected (besides the "assignment status" filter)
    let groupMatch = true;
    if (filterGroup) {
      // When a specific group is chosen, we require that the student has at least one group
      // and that the first group's id matches. (Adjust if multiple groups are possible.)
      groupMatch =
        student.groups &&
        student.groups[0] &&
        student.groups[0].id.toString() === filterGroup;
    }

    // Filter by birth year
    let birthYearMatch = true;
    if (filterBirthYear) {
      birthYearMatch =
        student.date_of_birth &&
        student.date_of_birth.slice(0, 4) === filterBirthYear;
    }

    // Filter by school (case-insensitive)
    let schoolMatch = true;
    if (filterSchool) {
      schoolMatch =
        student.school &&
        student.school.toLowerCase().includes(filterSchool.toLowerCase());
    }

    // Filter by enrollment date range
    let enrollmentMatch = true;
    if (filterEnrollmentFrom || filterEnrollmentTo) {
      if (student.enrollment_date) {
        if (filterEnrollmentFrom) {
          enrollmentMatch =
            enrollmentMatch && student.enrollment_date >= filterEnrollmentFrom;
        }
        if (filterEnrollmentTo) {
          enrollmentMatch =
            enrollmentMatch && student.enrollment_date <= filterEnrollmentTo;
        }
      } else {
        enrollmentMatch = false;
      }
    }

    return (
      assignmentStatusMatch &&
      groupMatch &&
      birthYearMatch &&
      schoolMatch &&
      enrollmentMatch
    );
  });

  // Mutation hooks
  const addStudentsToGroup = useAddStudentsToGroup(() => {
    queryClient.invalidateQueries(["studentsWithoutGroups"]);
    alert("Students added to group successfully!");
  });

  const removeStudentsFromGroup = useRemoveStudentsFromGroup(() => {
    queryClient.invalidateQueries(["studentsWithoutGroups"]);
    alert("Students removed from group successfully!");
  });

  const bulkDeleteStudents = useBulkDeleteStudents(() => {
    queryClient.invalidateQueries(["studentsWithoutGroups"]);
    alert("Students deleted successfully!");
  });

  const updateStudent = useUpdateStudent(() => {
    queryClient.invalidateQueries(["studentsWithoutGroups"]);
    alert("Student updated successfully!");
    setEditingStudentId(null); // Close editing mode
  });

  // Handle student selection
  const handleSelectStudent = (id) => {
    setSelectedStudents((prev) =>
      prev.includes(id)
        ? prev.filter((studentId) => studentId !== id)
        : [...prev, id]
    );
  };

  // Start editing
  const handleEditStudent = (student) => {
    setEditingStudentId(student.id);
    setEditData({ ...student });
  };

  // Save changes
  const handleSaveEdit = () => {
    updateStudent.mutate({ studentId: editingStudentId, data: editData });
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setEditingStudentId(null);
    setEditData({});
  };

  // Update editable data
  const handleChange = (field, value) => {
    setEditData((prev) => ({ ...prev, [field]: value }));
  };

  // Handle adding students to a group
  const handleAddToGroup = () => {
    if (!selectedGroup) {
      alert("Please select a group first.");
      return;
    }
    if (!selectedStudents || selectedStudents.length === 0) {
      alert("Please select a student first.");
      return;
    }

    setSelectedStudents([]);

    addStudentsToGroup.mutate({
      groupId: selectedGroup,
      data: selectedStudents,
    });
  };

  // Handle removing students from a group
  const handleRemoveFromGroup = () => {
    if (!selectedGroup) {
      alert("Please select a group first.");
      return;
    }
    if (!selectedStudents || selectedStudents.length === 0) {
      alert("Please select a student first.");
      return;
    }

    setSelectedStudents([]);
    removeStudentsFromGroup.mutate({
      groupId: selectedGroup,
      data: selectedStudents,
    });
  };

  // Handle bulk deletion
  const handleBulkDelete = () => {
    if (window.confirm("Are you sure you want to delete selected students?")) {
      bulkDeleteStudents.mutate(selectedStudents);
    }
  };

  if (studentsLoading || loadingGroups) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <h2>Unassigned Students</h2>

      {/* Combined Filter and Action Controls */}
      <div className="d-flex flex-wrap justify-content-end align-items-center gap-4 mb-3">
        {/* Filter Controls */}
        <div>
          <h5>Filters</h5>
          <div className="d-flex flex-wrap gap-2">
            <select
              className="form-select form-select-sm w-auto"
              value={filterAssignmentStatus}
              onChange={(e) => setFilterAssignmentStatus(e.target.value)}
            >
              <option value="all">All Students</option>
              <option value="unassigned">Unassigned</option>
              <option value="assigned">Assigned</option>
            </select>

            <select
              className="form-select form-select-sm w-auto"
              value={filterGroup}
              onChange={(e) => setFilterGroup(e.target.value)}
            >
              <option value="">Filter by Group</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>

            <input
              type="text"
              className="form-control form-control-sm w-auto"
              placeholder="Filter by School"
              value={filterSchool}
              onChange={(e) => setFilterSchool(e.target.value)}
            />

            <input
              type="number"
              className="form-control form-control-sm w-auto"
              placeholder="Birth Year (YYYY)"
              value={filterBirthYear}
              onChange={(e) => setFilterBirthYear(e.target.value)}
            />

            <input
              type="date"
              className="form-control form-control-sm w-auto"
              placeholder="Enrollment From"
              value={filterEnrollmentFrom}
              onChange={(e) => setFilterEnrollmentFrom(e.target.value)}
            />

            <input
              type="date"
              className="form-control form-control-sm w-auto"
              placeholder="Enrollment To"
              value={filterEnrollmentTo}
              onChange={(e) => setFilterEnrollmentTo(e.target.value)}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div>
          <h5>Actions</h5>
          <div className="d-flex flex-wrap gap-2">
            <select
              id="group-dropdown"
              className="form-select form-select-sm w-auto"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              <option value="">Select a Group</option>
              {loadingGroups
                ? "Loading... "
                : groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
            </select>

            <button
              onClick={handleAddToGroup}
              className="btn btn-sm btn-primary"
              disabled={addStudentsToGroup.isPending}
            >
              Add to Group
            </button>

            <button
              onClick={handleRemoveFromGroup}
              className="btn btn-sm btn-warning"
              disabled={removeStudentsFromGroup.isPending}
            >
              Remove from Group
            </button>
            <button
              onClick={handleBulkDelete}
              className="btn btn-sm btn-danger"
            >
              Delete Selected
            </button>
          </div>
        </div>
      </div>

      {/* Student Table */}
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedStudents(
                      filteredStudents.map((student) => student.id)
                    );
                  } else {
                    setSelectedStudents([]);
                  }
                }}
                checked={selectedStudents.length === filteredStudents.length}
              />
            </th>
            <th>No.</th>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Date of Birth</th>
            <th>Enrollment Date</th>
            <th>School</th>
            <th>Photo</th>
            <th>Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={student.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedStudents.includes(student.id)}
                  onChange={() => handleSelectStudent(student.id)}
                />
              </td>
              <td>{index + 1}</td>
              <td>{student.id}</td>
              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="text"
                    value={editData.first_name || ""}
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.first_name || "-"
                )}
              </td>
              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="text"
                    value={editData.last_name || ""}
                    onChange={(e) => handleChange("last_name", e.target.value)}
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.last_name || "-"
                )}
              </td>

              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="text"
                    value={editData.phone_number || ""}
                    onChange={(e) =>
                      handleChange("phone_number", e.target.value)
                    }
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.phone_number || "-"
                )}
              </td>

              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="date"
                    value={editData.date_of_birth || ""}
                    onChange={(e) =>
                      handleChange("date_of_birth", e.target.value)
                    }
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.date_of_birth || "-"
                )}
              </td>
              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="date"
                    value={editData.enrollment_date || ""}
                    onChange={(e) =>
                      handleChange("enrollment_date", e.target.value)
                    }
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.enrollment_date || "-"
                )}
              </td>

              <td>
                {editingStudentId === student.id ? (
                  <input
                    type="text"
                    value={editData.school || ""}
                    onChange={(e) => handleChange("school", e.target.value)}
                    className="form-control form-control-sm"
                  />
                ) : (
                  student.school || "-"
                )}
              </td>

              <td>{student.photo ? "Uploaded" : "No Photo"}</td>
              <td>{student.groups[0]?.name || "no group"}</td>
              <td>
                {editingStudentId === student.id ? (
                  <>
                    <button
                      onClick={() => handleSaveEdit(student.id)}
                      className="btn btn-sm btn-success me-1"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelEdit}
                      className="btn btn-sm btn-secondary"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => handleEditStudent(student)}
                    className="btn btn-sm btn-warning"
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UnassignedStudents;
