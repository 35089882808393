import React from "react";

// Reusable Assignment List Component
const AssignmentList = ({
  setSelectedAssignmentId,
  title,
  assignments,
  isLoading,
  onPrimaryAction,
  onDelete,
  primaryActionLabel,
}) => {
  // Helper function to format the date
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  if (isLoading) return <div className="text-center my-5">Loading...</div>;

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">{title}</h2>
      {assignments && assignments.length > 0 ? (
        <ul className="list-group">
          {assignments.map((assignment) => (
            <li
              onClick={() => setSelectedAssignmentId(assignment.id)}
              key={assignment.id}
              className="list-group-item d-flex flex-column align-items-start"
            >
              <div className="mb-2">
                <h5 className="mb-1 text-primary">{assignment.description}</h5>
                <p className="mb-1">
                  <strong>Type:</strong> {assignment.type}
                </p>
                <p className="mb-1">
                  <strong>Deadline:</strong>{" "}
                  {formatDateTime(assignment.deadline)}
                </p>
                <p className="mb-1">
                  <strong>Time limit:</strong> {assignment.time_limit} minutes
                </p>
                <p className="mb-1">
                  <strong>Created At:</strong>{" "}
                  {formatDateTime(assignment.created_at)}
                </p>
              </div>
              <div className="d-flex justify-content-start gap-2">
                {/* Primary Action Button */}
                <button
                  className={`btn btn-${
                    primaryActionLabel === "Activate" ? "success" : "warning"
                  } btn-sm`}
                  onClick={() => onPrimaryAction(assignment)}
                >
                  {primaryActionLabel}
                </button>
                {/* Delete Button */}
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => onDelete(assignment)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="alert alert-info text-center">
          No assignments found.
        </div>
      )}
    </div>
  );
};

export default AssignmentList;
