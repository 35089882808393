import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchSimpleSpeakingTests,
  fetchSpeakingTestById,
  bulkUploadSpeakingTests,
  bulkUpdateSpeakingTest,
  deleteSpeakingTest,
} from "../../api/adminAPI/speakingAPI";

// =============================
// 1. Fetch Simple Tests List
// =============================
export const useFetchSimpleSpeakingTests = (subcategoryId) =>
  useQuery({
    queryKey: ["simpleSpeakingTests", subcategoryId],
    queryFn: () => fetchSimpleSpeakingTests(subcategoryId),
    enabled: !!subcategoryId, // Ensure subcategoryId is present before fetching
  });

// =============================
// 2. Fetch Detailed Test
// =============================
export const useFetchSpeakingTestById = (subcategoryId, testId) =>
  useQuery({
    queryKey: ["detailedSpeakingTest", subcategoryId, testId],
    queryFn: () => fetchSpeakingTestById(subcategoryId, testId),
    enabled: !!subcategoryId && !!testId, // Ensure both IDs are present
  });

// =============================
// 3. Bulk Upload Tests
// =============================
export const useBulkUploadSpeakingTests = (subcategoryId) => {
  const queryClient = useQueryClient(); // React Query cache management

  return useMutation({
    mutationFn: (data) => bulkUploadSpeakingTests(subcategoryId, data),
    onSuccess: () => {
      // Invalidate cache to refresh the tests list
      queryClient.invalidateQueries(["simpleSpeakingTests", subcategoryId]);
      alert("Speaking tests added successfully!");
    },
  });
};

// =============================
// 4. Bulk Update Tests
// =============================
export const useBulkUpdateSpeakingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ data, testId, subcategoryId }) =>
      bulkUpdateSpeakingTest(subcategoryId, testId, data),
    onSuccess,
  });
};

// =============================
// 5. Delete a Test
// =============================
export const useDeleteSpeakingTest = (onSuccess) => {
  return useMutation({
    mutationFn: ({ testId, subcategoryId }) =>
      deleteSpeakingTest(subcategoryId, testId),
    onSuccess,
  });
};
