import api from "./api";

/* =======================
   CATEGORIES API FUNCTIONS
   ======================= */

// Fetch all categories
export const fetchCategories = async () => {
  const response = await api.get("/api/admin/categories");
  return response.data;
};

// Add a new category
export const addCategory = async (category) => {
  const response = await api.post("/api/admin/categories/", category);
  return response.data;
};

// Update a category
export const updateCategory = async ({ id, categoryData }) => {
  const res = await api.put(`/api/admin/categories/${id}/`, categoryData);
  return res.data;
};

// Delete a category
export const deleteCategory = async (id) => {
  const res = await api.delete(`/api/admin/categories/${id}/`);
  return res.data;
};

/* ==========================
   SUBCATEGORIES API FUNCTIONS
   ========================== */

// Fetch subcategories for a category
export const fetchSubcategories = async (categoryId) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories`
  );
  return response.data;
};

// **Add a new subcategory**
export const addSubcategory = async ({ categoryId, subcategoryData }) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/`, // Updated URL
    subcategoryData
  );
  return response.data; // Returns the created subcategory
};

// **Update a subcategory**
export const updateSubcategory = async ({
  categoryId,
  subcategoryId,
  subcategoryData,
}) => {
  const response = await api.put(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/`, // Updated URL
    subcategoryData
  );
  return response.data; // Returns the updated subcategory
};

// **Delete a subcategory**
export const deleteSubcategory = async ({ categoryId, subcategoryId }) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/` // Updated URL
  );
  return response.data; // Returns success message
};

/* =======================
   CONTENT API FUNCTIONS
   ======================= */

// Fetch content for a subcategory
export const fetchContent = async (subcategoryId, contentType) => {
  const response = await api.get(
    `/api/admin/subcategories/${subcategoryId}/content?type=${contentType}`
  );
  return response.data;
};

/* =======================
   Grammar Questions API FUNCTIONS
   ======================= */
// **Fetch grammar questions for a subcategory by search term**

export const fetchGrammarQuestions = async (
  categoryId,
  subcategoryId,
  search = ""
) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/?search=${search}`
  );
  return response.data; // Returns grammar questions
};

// get the count
export const fetchGrammarQuestionsCount = async (
  categoryId,
  subcategoryId,
  search = ""
) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/count/?search=${search}`
  );
  return response.data; // Returns grammar questions
};

// **Bulk upload grammar questions**
export const bulkUploadGrammarQuestions = async ({
  categoryId,
  subcategoryId,
  questions,
}) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/bulk-upload/`,
    { questions }
  );

  return response.data; // Returns upload results
};

// **Add a single grammar question**
export const addGrammarQuestion = async ({
  categoryId,
  subcategoryId,
  questionData,
}) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/`,
    questionData
  );
  return response.data; // Returns the added question
};

// **Update an existing grammar question**
export const updateGrammarQuestion = async ({
  categoryId,
  subcategoryId,
  questionId,
  questionData, // Ensure it's 'questionData'
}) => {
  try {
    const response = await api.put(
      `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/${questionId}/`,
      questionData // Send JSON data directly
    );
    return response.data; // Return updated data
  } catch (error) {
    console.error("API Update Error:", error.response?.data || error.message);
    throw error; // Re-throw for further handling
  }
};

// **Delete a grammar question**
export const deleteGrammarQuestion = async (
  categoryId,
  subcategoryId,
  questionId
) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/grammar-questions/${questionId}/`
  );
  return response.data; // Returns success message
};

/* =======================
   Words
   ======================= */

// **Fetch all words**
export const fetchWords = async (categoryId, subcategoryId) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/`
  );
  return response.data; // Returns the words
};

// **Fetch questions for a specific word with optional search**
export const fetchQuestionsByWord = async ({
  categoryId,
  subcategoryId,
  wordId,
  search = "",
}) => {
  const response = await api.get(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/questions/?search=${search}`
  );
  return response.data; // Returns the questions
};

// **Fetch question count for a specific word with optional search**

export const fetchQuestionsCountByWord = async ({
  categoryId,
  subcategoryId,
  wordId,
  search = "",
}) => {
  const response = await api.get(
    `api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/questions/count/?search=${search}`
  );
  return response.data; // Returns the questions
};

// **Update a specific question for a word**
export const updateQuestion = async ({
  categoryId,
  subcategoryId,
  wordId,
  questionId,
  questionData,
}) => {
  const response = await api.put(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/questions/${questionId}/`,
    questionData
  );
  return response.data; // Returns the updated question
};

// **Delete a specific question for a word**
export const deleteQuestion = async ({
  categoryId,
  subcategoryId,
  wordId,
  questionId,
}) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/questions/${questionId}/`
  );
  return response.data; // Returns success message
};

// **Create a new word**
export const addWord = async ({ categoryId, subcategoryId, wordData }) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/`,
    wordData
  );
  return response.data; // Returns the created word
};

// **Update a specific word**
export const updateWord = async ({
  categoryId,
  subcategoryId,
  wordId,
  wordData,
}) => {
  const response = await api.put(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/`,
    wordData
  );
  return response.data; // Returns the updated word
};

// **Delete a specific word**
export const deleteWord = async ({ categoryId, subcategoryId, wordId }) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/`
  );
  return response.data; // Returns success message
};

// **Bulk Upload Words with Questions**
export const bulkUploadWords = async ({
  categoryId,
  subcategoryId,
  wordsData,
}) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/bulk_upload/`,
    { words: wordsData }
  );
  return response.data; // Returns upload results
};

// **Bulk upload questions for a specific word**
export const bulkUploadQuestions = async ({
  categoryId,
  subcategoryId,
  wordId,
  questionsData,
}) => {
  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/questions/bulk_upload/`,
    { questions: questionsData }
  );
  return response.data; // Returns upload results
};

/* =======================
   Words Images
   ======================= */
export const uploadWordImage = async ({
  categoryId,
  subcategoryId,
  wordId,
  imageFile,
}) => {
  const formData = new FormData();
  formData.append("image", imageFile);

  const response = await api.post(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/upload-image/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

// **Delete an image for a specific word**
export const deleteWordImage = async ({
  categoryId,
  subcategoryId,
  wordId,
  imageId,
}) => {
  const response = await api.delete(
    `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}/words/${wordId}/images/${imageId}/delete/`
  );
  return response.data; // Returns success message
};
