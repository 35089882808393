import React from "react";
import LoadingAnimation from "../../../utils/LoadingAnimation";

const StudentProfile = ({ myData }) => {
  if (!myData) {
    return <LoadingAnimation />;
  }

  return (
    <div className="container mt-4">
      <div className="row">
        {/* Profile positioned on the left */}
        <div className="col-lg-4 col-md-5 col-sm-12">
          <div
            className="card border-0"
            style={{
              borderRadius: "15px",
              backgroundColor: "#ffffff",
              maxWidth: "100%", // Adjust width to fit column
              margin: "auto",
            }}
          >
            {/* Profile Content */}
            <div className="card-body d-flex align-items-center">
              {/* Profile Picture */}
              <div className="text-center">
                {myData.photo ? (
                  <img
                    src={myData.photo}
                    alt={`${myData.first_name} ${myData.last_name}`}
                    className="rounded-circle"
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                      border: "3px solid #dcdcdc",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      height: "150px",
                      width: "150px",
                      backgroundColor: "#f0f0f0",
                      fontSize: "18px",
                      color: "#888888",
                      border: "3px solid #dcdcdc",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    No Photo
                  </div>
                )}
              </div>

              {/* Profile Details */}
              <div className="ms-4 w-100">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th className="text-muted" style={{ width: "150px" }}>
                        First Name:
                      </th>
                      <td>{myData.first_name}</td>
                    </tr>
                    <tr>
                      <th className="text-muted">Last Name:</th>
                      <td>{myData.last_name}</td>
                    </tr>
                    <tr>
                      <th className="text-muted">Phone:</th>
                      <td>{myData.phone_number}</td>
                    </tr>
                    <tr>
                      <th className="text-muted">Date of Birth:</th>
                      <td>{myData.date_of_birth}</td>
                    </tr>
                    <tr>
                      <th className="text-muted">Enrollment:</th>
                      <td>{myData.enrollment_date}</td>
                    </tr>
                    <tr>
                      <th className="text-muted">School:</th>
                      <td>{myData.school}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
