// Phone Number Formatter for Uzbekistan
export const formatPhoneNumber = (value) => {
  // Remove all non-numeric characters
  value = value.replace(/\D/g, "");

  // Ensure the phone number starts with the Uzbekistan country code
  if (!value.startsWith("998")) {
    value = "998" + value;
  }

  // Limit to 12 digits (country code + 9 digits)
  if (value.length > 12) value = value.slice(0, 12);

  // Format into +998 XX XXX XX XX
  let formattedPhone = "+998";

  if (value.length > 3) formattedPhone += " " + value.slice(3, 5);
  if (value.length > 5) formattedPhone += " " + value.slice(5, 8);
  if (value.length > 8) formattedPhone += " " + value.slice(8, 10);
  if (value.length > 10) formattedPhone += " " + value.slice(10, 12);

  return formattedPhone;
};
