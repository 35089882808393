import React from "react";

const PopupMessage = ({ message, onClose }) => {
  return (
    <div style={popupStyles.overlay}>
      <div style={popupStyles.popup}>
        <p style={popupStyles.message}>{message}</p>
        <button style={popupStyles.button} onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

const popupStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark overlay for elegance
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
  },
  popup: {
    padding: "20px 30px",
    background: "#f0f0f0", // Light silver/white background
    borderRadius: "8px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Enhanced shadow for depth
    textAlign: "center",
    border: "2px solid #5A5A5A", // Silver border
    minWidth: "300px",
  },
  message: {
    color: "#2A623D", // Slytherin green text
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "15px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#2A623D", // Slytherin green
    color: "#ffffff", // White text
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#1D4D2E", // Darker green on hover
  },
};

export default PopupMessage;
