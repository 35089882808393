import React from "react";
import PayloadPreview from "./PayloadPreview";

const PreviewAssignment = ({
  payload,
  assignmentType,
  setAssignmentType,
  deadline,
  setDeadline,
  timeLimit,
  setTimeLimit,
  startTime,
  setStartTime,
  description,
  setDescription,
  handleCreate,
  assignmentCreating,
  fullGroupData,
}) => {
  return (
    <div>
      <h3>Preview</h3>

      {/* Assignment Details Inputs */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center gap-3">
          <div>
            <label>Assignment Type:</label>
            <select
              className="form-select form-select-sm"
              value={assignmentType}
              onChange={(e) => setAssignmentType(e.target.value)}
            >
              <option value="homework">Homework</option>
              <option value="classwork">Classwork</option>
            </select>
          </div>

          <div>
            <label>Deadline:</label>
            <input
              type="datetime-local"
              className="form-control form-control-sm"
              value={deadline || ""}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </div>

          <div>
            <label>Time Limit (mins):</label>
            <input
              type="number"
              className="form-control form-control-sm"
              value={timeLimit || ""}
              onChange={(e) => setTimeLimit(e.target.value)}
            />
          </div>

          {assignmentType === "classwork" && (
            <div>
              <label>Start Time:</label>
              <input
                type="datetime-local"
                className="form-control form-control-sm"
                value={startTime || ""}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
          )}
        </div>

        {/* Create Button */}
        <button
          className="btn btn-success"
          onClick={handleCreate}
          disabled={assignmentCreating}
        >
          {assignmentCreating ? "Creating..." : "Create"}
        </button>
      </div>

      {/* Assignment Description */}
      <div className="mt-3">
        <label>Assignment Description:</label>
        <textarea
          className="form-control"
          rows="3"
          placeholder="Enter assignment details..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      {/* JSON Preview */}
      <PayloadPreview payload={payload} fullGroupData={fullGroupData} />
    </div>
  );
};

export default PreviewAssignment;
