import { React, useState } from "react";
import { useBulkUploadListeningTests } from "./../../../../../../hooks/AdminDashboard/useListening";
import { validateListeningTests } from "./../../../../../../utils/validation/listeningTestValidation";
import MonacoEditor from "@monaco-editor/react";

const TestBulkupload = ({ subcategoryId }) => {
  const [jsonData, setJsonData] = useState(sampleTests);
  const [uploadErrors, setUploadErrors] = useState([]);

  const bulkUploadMutation = useBulkUploadListeningTests(subcategoryId);

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one test!");
      return;
    }
    try {
      const testsData = JSON.parse(jsonData);
      if (!validateListeningTests(testsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        subcategoryId,
        data: testsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>
        Add NEW tests with sections, transcript, section parts, and questions.
      </h4>

      <MonacoEditor
        height="600px"
        language="json"
        value={jsonData}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestBulkupload;

const sampleTests = `{
  "listening_tests": [
    {
      "name": "IELTS Listening Practice Test 1",
      "description": "This is the first listening test for IELTS practice.",
      "file": null,
      "link": "youtube_video_id",
      "transcript": "transcript of the video/audio",
      "sections": [
        {
          "name": "Section 1",
          "instructions": "You will hear a conversation between two people. Answer the questions below.",
          "order": 1,
          "parts": [
            {
              "name": "Part 1",
              "instructions": "Answer the following questions.",
              "order": 1,
              "questions": [
                {
                  "format": "MCQ",
                  "text": "What is the capital of France?",
                  "choices": ["Paris", "London", "Berlin", "Rome"],
                  "correct_answer": ["Paris"],
                  "feedback": "Paris is the capital of France.",
                  "order": 1
                },
               {
                  "format": "GAP_FILL",
                  "text": "The ___ is the largest ocean.",
                  "choices": null,
                  "correct_answer": ["Pacific"],
                  "feedback": "The Pacific Ocean is the largest ocean.",
                   "order": 2
                },
                {
                  "format": "MATCHING",
                  "text": "Match the countries with their capitals.",
                  "choices": [
                    {"country": "France", "capital": "Paris"},
                    {"country": "Germany", "capital": "Berlin"},
                    {"country": "Italy", "capital": "Rome"}
                  ],
                  "correct_answer": [
                    {"country": "France", "capital": "Paris"},
                    {"country": "Germany", "capital": "Berlin"},
                    {"country": "Italy", "capital": "Rome"}
                  ],
                  "feedback": "Match each country to its correct capital.",
                   "order": 3
                },
                {
                  "format": "MULTI_SELECT",
                  "text": "Select all the prime numbers.",
                  "choices": ["2", "3", "4", "5", "6"],
                  "correct_answer": ["2", "3", "5"],
                  "feedback": "2, 3, and 5 are prime numbers.",
                   "order": 4
                }
              ]
            },
            {
              "name": "Part 2",
              "instructions": "Match the following items.",
              "order": 2,
              "questions": [
                {
                  "format": "MATCHING",
                  "text": "Match the countries with their capitals.",
                  "choices": [
                    {"country": "France", "capital": "Paris"},
                    {"country": "Germany", "capital": "Berlin"},
                    {"country": "Italy", "capital": "Rome"}
                  ],
                  "correct_answer": [
                    {"country": "France", "capital": "Paris"},
                    {"country": "Germany", "capital": "Berlin"},
                    {"country": "Italy", "capital": "Rome"}
                  ],
                  "feedback": "Match each country to its correct capital.",
                   "order": 5
                }
              ]
            }
          ]
        },
        {
          "name": "Section 2",
          "instructions": "You will hear a monologue. Answer the questions below.",
          "order": 2,
          "parts": [
            {
              "name": "Part 1",
              "instructions": "Answer the following questions.",
              "order": 1,
              "questions": [
                 {
                  "format": "TRUE_FALSE_NOT_GIVEN",
                  "text": "Trees produce carbon dioxide.",
                  "choices": ["True", "False", "Not Given"],
                  "correct_answer": ["False"],
                  
                  "feedback": "Trees produce oxygen, not carbon dioxide.",
                   "order": 6
                },
              ]
            }
          ]
        }
      ]
    }
  ]
}
`;
