import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getGroups,
  getGroupById,
  attachCategoriesToGroup,
  detachCategoriesFromGroup,
  deleteGroup,
  updateGroup,
  addGroup,
  addStudentsToGroup,
  removeStudentsFromGroup,
} from "./../../api/adminAPI/groupAPI";

// Fetch groups
export const useGroups = () =>
  useQuery({
    queryKey: ["groups"],
    queryFn: getGroups,
    staleTime: 1000 * 60 * 60,
  });

export const useGroup = (groupId) =>
  useQuery({
    queryKey: ["group", groupId],
    queryFn: () => getGroupById(groupId),
    enabled: !!groupId, // Ensure both IDs are present
  });

// Attach categories to a group
export const useAttachCategories = (onSuccess) =>
  useMutation({
    mutationFn: attachCategoriesToGroup,
    onSuccess,
  });

// **Detach categories from a group**
export const useDetachCategories = (onSuccess) =>
  useMutation({
    mutationFn: detachCategoriesFromGroup,
    onSuccess,
  });

// **Add a new group**
export const useAddGroup = (onSuccess) =>
  useMutation({
    mutationFn: (groupData) => addGroup(groupData),
    onSuccess,
  });

// **Update an existing group**
export const useUpdateGroup = (onSuccess) =>
  useMutation({
    mutationFn: updateGroup,
    onSuccess,
  });

// **Delete a group**
export const useDeleteGroup = (onSuccess) =>
  useMutation({
    mutationFn: deleteGroup,
    onSuccess,
  });

// Add multiple students to a group
export const useAddStudentsToGroup = (onSuccess) =>
  useMutation({
    mutationFn: ({ groupId, data }) => addStudentsToGroup(groupId, data),
    onSuccess,
  });

// Remove multiple students from a group
export const useRemoveStudentsFromGroup = (onSuccess) =>
  useMutation({
    mutationFn: ({ groupId, data }) => removeStudentsFromGroup(groupId, data),
    onSuccess,
  });
