import { BsCheckCircle } from "react-icons/bs";

const TestCard = ({ test, onClick }) => {
  const badgeColors = {
    grammar: "primary",
    vocabulary: "success",
    vocabulary_translation: "dark",
    reading: "info",
    listening: "warning",
    speaking: "danger",
    writing: "secondary",
  };

  return (
    <div
      className="card mb-3 position-relative"
      style={{
        border: "1px solid #e3e3e3",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h5 className="card-title mb-0">{test.name}</h5> */}
          <span
            className={`badge bg-${
              badgeColors[test.type] || "dark"
            } text-uppercase`}
          >
            {test.type === "vocabulary_translation"
              ? "Vocabulary translation"
              : test.type}
          </span>
        </div>
        <p className="card-text mt-2 text-muted">{test.description}</p>
        <hr />
        <p className="card-text">
          <strong>Number of Questions:</strong> {test.number_of_questions}
        </p>
      </div>
      {/* Submitted Section */}
      {test.isSubmitted && (
        <div
          className="d-flex justify-content-center align-items-center py-2"
          style={{
            backgroundColor: "#e9f7ef",
            borderTop: "1px solid #d4edda",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <BsCheckCircle
            style={{
              fontSize: "1.5rem",
              color: "green",
              marginRight: "8px",
            }}
          />
          <span
            style={{
              color: "green",
              fontWeight: "bold",
            }}
          >
            Submitted | {test.score}
          </span>
        </div>
      )}
    </div>
  );
};

export default TestCard;
