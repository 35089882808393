import React, { useState, useCallback } from "react";
import Header from "./Header";
import YouTubePlayer from "./YouTubePlayer";
import Questions from "./Questions";
import Footer from "./Footer";
import createSubmissionPayload from "../ReadingTest/createSubmissionPayload";
import extractQuestionsFromTest from "./extractQuestionsFromTest";
import { useSaveProgress } from "../../../../../hooks/Progress/useProgress";
import { useAnswersManagement } from "../ReadingTest/useAnswersManagement";
import { useQuery } from "@tanstack/react-query";
import extractTests from "../TestsComponent/extractTests";

const ListeningTest = ({ testId, assignmentId }) => {
  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false, // or enabled: !!assignmentId, but we have no queryFn
  });

  const attempt = data?.attempt;
  const tests = extractTests(attempt);
  const currentTest = tests.find((test) => {
    if (test.id === testId && test.type === "listening") return test;
  });
  const questions = extractQuestionsFromTest(currentTest);
  const isSubmitted = currentTest.isSubmitted;
  const attempt_id = data.attempt.id;

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  // Use custom hook for answer management
  const { selectedAnswers, handleAnswerSelect, handleSubmit } =
    useAnswersManagement(questions);

  // Save progress hook
  const { mutate: saveProgress, isPending: isSubmitting } =
    useSaveProgress(assignmentId);

  // Handle navigation between sections
  const handleNavigation = (direction) => {
    setCurrentSectionIndex((prevIndex) =>
      direction === "next"
        ? Math.min(prevIndex + 1, currentTest.sections.length - 1)
        : Math.max(prevIndex - 1, 0)
    );
  };

  const currentSection = currentTest.sections[currentSectionIndex];

  // Handle YouTube player ready event
  const handlePlayerReady = useCallback((event) => {
    event.target.playVideo();
  }, []);

  // Submit handler
  const handleTestSubmit = () => {
    const payload = createSubmissionPayload(
      selectedAnswers,
      "listening",
      attempt_id,
      testId
    );
    handleSubmit(saveProgress, { assignmentId, progressData: payload });
  };

  return (
    <div>
      <Header
        sectionInfo={currentSection}
        score={currentTest.score}
        isSubmitted={isSubmitted}
      />
      {/* Content: Player and Questions */}
      <div className="listening-test-content d-flex flex-grow-1">
        <div style={{ flex: 1, padding: "5px" }}>
          <YouTubePlayer
            videoId={currentTest.link}
            onPlayerReady={handlePlayerReady}
          />
        </div>
        <div style={{ flex: 1.5, overflowY: "auto", padding: "5px" }}>
          <Questions
            sections={[currentSection]}
            answers={currentTest.answers}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        </div>
      </div>

      <Footer
        currentPassageIndex={currentSectionIndex}
        totalPassages={currentTest.sections.length}
        onNavigate={handleNavigation}
        handleSubmit={handleTestSubmit}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ListeningTest;
