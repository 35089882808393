import React from "react";

const CategoriesList = ({ fullGroupData, groupLoading }) => {
  if (groupLoading) {
    return <p>Loading ...</p>;
  }

  // Debugging output for categories
  console.log(fullGroupData?.categories);

  return (
    <div>
      <h2>Categories</h2>
      <ul>
        {fullGroupData?.categories && fullGroupData?.categories.length > 0 ? (
          fullGroupData?.categories.map((category) => (
            <li key={category.id}>
              <strong>{category.name}</strong> - {category.description}
            </li>
          ))
        ) : (
          <p>No categories available.</p>
        )}
      </ul>
    </div>
  );
};

export default CategoriesList;
