import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useStartAssignment } from "../../../../hooks/AdminDashboard/useAssignment";
import TestsBoard from "./TestsComponent/TestsBoard";
import { useMyActiveAssignments } from "../../../../hooks/StudentDashboard/useStudent";
import LoadingAnimation from "../../../../utils/LoadingAnimation";
import AssignmentCard from "./AssignmentCard";

const Assignments = ({ myName, myId, selectedGroupId, type }) => {
  // 1. All hooks at the top
  const [assignmentId, setAssignmentId] = useState(null);

  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false,
  });

  const {
    data: assignmentsData = [],
    isLoading: assignmentsLoading,
    isError,
    error,
  } = useMyActiveAssignments(selectedGroupId);

  // Always call this, unconditionally:
  const startAssignmentMutation = useStartAssignment(assignmentId);

  // 2. Then handle loading & error states
  if (assignmentsLoading) {
    return <LoadingAnimation />;
  }
  if (isError) {
    return <div>Error: {error?.message || "Something went wrong!"}</div>;
  }

  // 3. Handler logic
  const handleStartAssignment = async (id) => {
    try {
      setAssignmentId(id);
      const data = await startAssignmentMutation.mutateAsync({
        assignmentId: id,
        studentId: myId,
      });
      if (!data) {
        setAssignmentId(null);
        alert("You cannot start the assignment yet!");
      }
    } catch (error) {
      console.error("Error starting assignment:", error);
    }
  };

  const handleExitTest = () => {
    const confirm = window.confirm("Are you sure you want to exit?");
    if (!confirm) return;
    setAssignmentId(null);
  };

  if (startAssignmentMutation.isPending) {
    return <LoadingAnimation />;
  }

  // 4. Filter and render
  const filteredAssignments = assignmentsData
    ?.filter((assignment) => assignment.type === type)
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

  return (
    <div className="container mt-4">
      <h2 className="mb-4">
        {type === "classwork" ? "Classwork" : "Homework"}
      </h2>

      {!assignmentId ? (
        <div className="row">
          {filteredAssignments.length > 0 ? (
            filteredAssignments.map((assignment) => (
              <div className="col-md-4 mb-4" key={assignment.id}>
                <AssignmentCard
                  key={`assignmentCard-${assignment.id}`}
                  assignment={assignment}
                  type={type}
                  onStartAssignment={handleStartAssignment}
                />
              </div>
            ))
          ) : (
            <div className="text-center">
              <p className="text-muted">No {type} assignments available.</p>
            </div>
          )}
        </div>
      ) : (
        <div style={overlayStyle}>
          <TestsBoard
            myName={myName}
            assignmentId={data?.assignment?.id}
            onBack={handleExitTest}
          />
        </div>
      )}
    </div>
  );
};

export default Assignments;

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  zIndex: 9999,
  overflow: "auto",
};
