function extractQuestionsFromTest(readingTest) {
  const questions = [];

  if (readingTest.passages && Array.isArray(readingTest.passages)) {
    readingTest.passages.forEach((passage) => {
      if (passage.sections && Array.isArray(passage.sections)) {
        passage.sections.forEach((section) => {
          if (section.questions && Array.isArray(section.questions)) {
            section.questions.forEach((question) => {
              questions.push({
                id: question.id,
                format: question.format,
                text: question.text,
                choices: question.choices || null,
                correct_answer: question.correct_answer || null,
              });
            });
          }
        });
      }
    });
  }

  return questions;
}

export default extractQuestionsFromTest;
