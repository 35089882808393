import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import { useBulkUploadWritingTests } from "./../../../../../../hooks/AdminDashboard/useWriting";
import { validateWritingTests } from "./../../../../../../utils/validation/writingTestValidation";

const TestBulkupload = ({ subcategoryId }) => {
  const [jsonData, setJsonData] = useState("");
  const [uploadErrors, setUploadErrors] = useState([]);

  const bulkUploadMutation = useBulkUploadWritingTests(subcategoryId);

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one test!");
      return;
    }
    try {
      const testsData = JSON.parse(jsonData);
      console.log(testsData);
      if (!validateWritingTests(testsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        data: testsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add NEW writing tests with tasks.</h4>

      <MonacoEditor
        height="600px"
        language="json"
        value={sampleTests}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestBulkupload;

const sampleTests = `{
  "tests": [
    {
      "name": "IELTS Writing Test 1",
      "description": "This test evaluates task 1 and task 2 skills.",
      "tasks": [
        {
          "task_number": 1,
          "prompt": "Describe the information in the graph below.",
          "details": "detailed description of the task",
          "image": "https://example.com/image1.jpg"
        },
        {
          "task_number": 2,
          "prompt": "Discuss both views and give your own opinion.",
          "image": "https://example.com/image2.jpg"
        }
      ]
    }
  ]
}

`;
