import React, { useState } from "react";
import Tabs from "../../../Shared/Tabs";

import UnassignedStudents from "./UnassignedStudents";

const StudentsManagement = () => {
  // State for selected group
  const [selectedGroup, setSelectedGroup] = useState(null);

  // Tabs configuration
  const tabs = [
    {
      key: "students",
      label: "Unassigned Students",
      content: <UnassignedStudents />,
    },
  ];

  // if (isLoading) {
  //   return <>Loading ... plz wait</>;
  // }

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row h-100">
        {/* Main Content */}
        <div className="col-9">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default StudentsManagement;
