import { React, useState } from "react";
import TestCard from "./TestCard";
import extractTests from "./extractTests";
import TestDoDisplay from "./TestDoDisplay";
import Header from "./Header";
import { useQuery } from "@tanstack/react-query";

const TestsBoard = ({ myName, onBack, assignmentId }) => {
  const [selectedTest, setSelectedTest] = useState(null);
  // const testsArray = extractTests(attempt);

  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false, // or enabled: !!assignmentId, but we have no queryFn
  });

  const attempt = data?.attempt;
  const time_left = attempt?.time_left;
  const testsArray = extractTests(attempt || []);

  const handleCardClick = (test) => {
    setSelectedTest(test); // Set the clicked test
  };

  const handleBackToBoard = () => {
    setSelectedTest(null); // Reset to show all cards
  };

  return (
    <>
      <Header
        myName={myName}
        assignmentId={assignmentId}
        onBack={handleBackToBoard}
        onExit={onBack}
        time_left={time_left}
        selectedTest={selectedTest}
      />
      <div className="container">
        {selectedTest ? (
          <TestDoDisplay
            selectedTest={selectedTest}
            assignmentId={assignmentId}
            attempt_id={attempt.id}
          />
        ) : (
          <div className="row mt-3">
            {testsArray.map((test, index) => (
              <div className="col-md-3" key={index}>
                <TestCard test={test} onClick={() => handleCardClick(test)} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TestsBoard;
