import React from "react";
import HollowCircularScore from "./HollowCircularScore";

const ResponseFeedback = ({ currentTaskAnswer }) => {
  return (
    <div
      className="p-3"
      style={{ flex: 2, display: "flex", flexDirection: "column" }}
    >
      <div
        className="form-control"
        style={{
          flex: 1,
          resize: "none",
          lineHeight: "1.5", // Match line height of textarea
          paddingBottom: "36px", // Match padding
          overflowY: "auto", // Allow scrolling for long content
        }}
      >
        <p>
          <strong>Your Response:</strong> <br />
          {currentTaskAnswer.response}
        </p>
        <p>
          <strong>Feedback:</strong> <br />
          {currentTaskAnswer.feedback || "No feedback provided."}
        </p>
        <HollowCircularScore score={currentTaskAnswer.score} />
      </div>

      <div className="mt-3 d-flex justify-content-between align-items-center">
        <span>
          <strong>Word Count:</strong>{" "}
          {(currentTaskAnswer.response || "").split(" ").filter(Boolean).length}
        </span>
      </div>
    </div>
  );
};

export default ResponseFeedback;
