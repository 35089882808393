import { React, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useBulkUploadReadingTests } from "./../../../../../../hooks/AdminDashboard/useReading";
import { validateReadingTests } from "./../../../../../../utils/validation/readingTestValidation";

import MonacoEditor from "@monaco-editor/react";

const TestBulkupload = ({ subcategoryId }) => {
  const [jsonData, setJsonData] = useState(sampleTests);
  const [uploadErrors, setUploadErrors] = useState([]);

  const queryClient = useQueryClient();

  const bulkUploadMutation = useBulkUploadReadingTests(subcategoryId, () => {
    queryClient.invalidateQueries(["simpleReadingTests", subcategoryId]);

    alert("Tests uploaded successfully!");
  });

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one test!");
      return;
    }
    try {
      const testsData = JSON.parse(jsonData);
      if (!validateReadingTests(testsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        subcategoryId,
        data: testsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add NEW tests with passages, sections, and questions.</h4>
      <MonacoEditor
        height="600px"
        language="json"
        value={jsonData}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestBulkupload;

const sampleTests = `{
  "tests": [
    {
      "name": "Test 1",
      "description": "Beginner Reading Test.",
      "passages": [
        {
          "title": "The Importance of Trees",
          "content": ["paragraph 1", "paragraph 2", "paragraph 3"],
          "image": null,
          "order": 1,
          "sections": [
            {
              "name": "Section 1",
              "instructions": "Read the passage and answer the following questions.",
              "image": null,
              "order": 1,
              "questions": [
                {
                  "format": "MCQ",
                  "text": "What do trees produce?",
                  "choices": ["Oxygen", "Carbon dioxide", "Nitrogen", "Hydrogen"],
                  "correct_answer": ["Oxygen"],
                  "order": 1,
                  "feedback": "Trees produce oxygen during photosynthesis.",
                  "image": null
                },
                {
                  "format": "GAP_FILL",
                  "text": "Trees provide ___ to animals.",
                  "choices": null,
                  "correct_answer": ["shelter"],
                  "order": 2,
                  "feedback": "Trees provide shelter to animals.",
                  "image": null
                },
                {
                  "format": "MATCHING",
                  "text": "Match the parts of the tree with their functions.",
                  "choices": [
                    {"key": "Leaves", "value": "Photosynthesis"},
                    {"key": "Roots", "value": "Absorb water"},
                    {"key": "Trunk", "value": "Support structure"}
                  ],
                  "correct_answer": [
                    {"key": "Leaves", "value": "Photosynthesis"},
                    {"key": "Roots", "value": "Absorb water"},
                    {"key": "Trunk", "value": "Support structure"}
                  ],
                  "order": 3,
                  "feedback": "Match the parts of the tree correctly.",
                  "image": null
                },
                {
                  "format": "TRUE_FALSE_NOT_GIVEN",
                  "text": "Trees produce carbon dioxide.",
                  "choices": ["True", "False", "Not Given"],
                  "correct_answer": ["False"],
                  "order": 4,
                  "feedback": "Trees produce oxygen, not carbon dioxide.",
                  "image": null
                },
                {
                  "format": "MULTI_SELECT",
                  "text": "Select all the benefits of trees.",
                  "choices": ["Provide oxygen", "Prevent soil erosion", "Cause pollution", "Give shelter"],
                  "correct_answer": ["Provide oxygen", "Prevent soil erosion", "Give shelter"],
                  "order": 5,
                  "feedback": "Trees have multiple benefits for the environment.",
                  "image": null
                },
                {
                  "format": "DIAGRAM_COMPLETION",
                  "text": "Label the parts of the tree shown in the diagram.",
                  "choices": ["Leaves", "Roots", "Trunk", "Branches"],
                  "correct_answer": ["Leaves", "Roots", "Trunk", "Branches"],
                  "order": 6,
                  "feedback": "Label all parts of the tree correctly.",
                  "image": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "Test 2",
      "description": "Advanced Reading Test.",
      "passages": [
        {
          "title": "The Water Cycle",
          "content": "Water cycles through various stages like evaporation, condensation, and precipitation.",
          "image": null,
          "order": 1,
          "sections": [
            {
              "name": "Section 1",
              "instructions": "Read the passage and answer the following questions.",
              "image": null,
              "order": 1,
              "questions": [
                {
                  "format": "MCQ",
                  "text": "What causes water to evaporate?",
                  "choices": ["Sunlight", "Rain", "Snow", "Wind"],
                  "correct_answer": ["Sunlight"],
                  "order": 1,
                  "feedback": "Sunlight causes water to evaporate.",
                  "image": null
                },
                {
                  "format": "GAP_FILL",
                  "text": "Water falls back to the earth as ___.",
                  "choices": null,
                  "correct_answer": ["precipitation"],
                  "order": 2,
                  "feedback": "Water falls as precipitation like rain or snow.",
                  "image": null
                },
                {
                  "format": "MATCHING",
                  "text": "Match the stages of the water cycle with their descriptions.",
                  "choices": [
                    {"key": "Evaporation", "value": "Water turns into vapor"},
                    {"key": "Condensation", "value": "Water vapor forms clouds"},
                    {"key": "Precipitation", "value": "Water falls as rain"}
                  ],
                  "correct_answer": [
                    {"key": "Evaporation", "value": "Water turns into vapor"},
                    {"key": "Condensation", "value": "Water vapor forms clouds"},
                    {"key": "Precipitation", "value": "Water falls as rain"}
                  ],
                  "order": 3,
                  "feedback": "Match the stages correctly.",
                  "image": null
                },
                {
                  "format": "TRUE_FALSE_NOT_GIVEN",
                  "text": "The water cycle stops during winter.",
                  "choices": ["True", "False", "Not Given"],
                  "correct_answer": ["False"],
                  "order": 4,
                  "feedback": "The water cycle continues throughout the year.",
                  "image": null
                },
                {
                  "format": "MULTI_SELECT",
                  "text": "Select all sources of water in the cycle.",
                  "choices": ["Oceans", "Rivers", "Mountains", "Plants"],
                  "correct_answer": ["Oceans", "Rivers", "Plants"],
                  "order": 5,
                  "feedback": "Water comes from oceans, rivers, and plants through transpiration.",
                  "image": null
                },
                {
                  "format": "DIAGRAM_COMPLETION",
                  "text": "Label the stages of the water cycle in the diagram.",
                  "choices": ["Evaporation", "Condensation", "Precipitation"],
                  "correct_answer": ["Evaporation", "Condensation", "Precipitation"],
                  "order": 6,
                  "feedback": "Label all the stages correctly.",
                  "image": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
`;
