import api from "../api";

export const fetchMyStudentData = async () => {
  const response = await api.get("/api/admin/students/me/");
  return response.data;
};

// export const fetchMyActiveAsignments = async () => {
//   const response = await api.get(
//     "/api/assignments/assignments/my-active-assignments/"
//   );
//   return response.data;
// };

export const fetchMyActiveAsignments = async (groupId) => {
  const response = await api.get(
    `/api/assignments/assignments/my-active-assignments/?group_id=${groupId}`
  );
  return response.data;
};
