const Passage = ({ content, title, image }) => {
  return (
    <div
      className="passage border-end"
      style={{ flex: 1, overflowY: "auto", height: "100%" }}
    >
      <div
        className="questions p-2"
        style={{
          maxHeight: "calc(100vh - 195px)", // Adjust based on layout
          overflowY: "auto",
          paddingRight: "10px",
        }}
      >
        <h4>{title}</h4>

        {image && (
          <div className="mb-3 text-center">
            <img
              src={image}
              alt={title || "Passage Image"}
              style={{
                maxWidth: "50%",
                height: "auto",
                borderRadius: "5px",
              }}
            />
          </div>
        )}
        {content.map((paragraph, index) => (
          <p key={index} style={{ marginBottom: "1rem" }}>
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Passage;
