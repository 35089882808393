import { React, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUploadWordImage,
  useDeleteWordImage,
} from "../../../../../../hooks/AdminDashboard/useWords";

const Images = ({ selectedWord, categoryId, subcategoryId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState("");
  const queryClient = useQueryClient();

  // React Query Mutation for Upload
  const uploadWordImageMutation = useUploadWordImage(() => {
    queryClient.invalidateQueries(["words", categoryId, subcategoryId]);
    alert("Image uploaded successfully!");
  });

  // Handle File Choose
  const handleChooseImage = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("No file selected.");
      return;
    }

    if (!selectedWord) {
      alert("Please select a word first.");
      return;
    }

    if (!validateFile(file)) {
      return;
    }

    // Store the selected file in the state
    setSelectedFile(file);

    alert("File selected. Click Save to upload.");
  };

  // Handle Save (Upload the file)
  const handleSave = () => {
    if (!selectedFile) {
      alert("No file selected.");
      return;
    }

    if (!selectedWord) {
      alert("Please select a word first.");
      return;
    }

    // Perform the upload
    uploadWordImageMutation.mutate({
      categoryId,
      subcategoryId,
      wordId: selectedWord.id,
      imageFile: selectedFile,
    });

    // Clear the selected file after upload
    setSelectedFile(null);
  };

  const deleteImageMutation = useDeleteWordImage(() => {
    queryClient.invalidateQueries(["words", categoryId, subcategoryId]);
    alert("Image successfully deleted!");
  });

  function handleDelete() {
    if (!selectedWord || !selectedImageId) {
      alert("Select a word and an image plz!");
      return;
    }
    deleteImageMutation.mutate({
      categoryId,
      subcategoryId,
      wordId: selectedWord.id,
      imageId: selectedImageId,
    });
  }

  return (
    <div>
      {/* Word Images */}
      <h5>Images</h5>
      <div className="mb-3 d-flex gap-2 flex-wrap">
        {selectedWord?.images && selectedWord?.images.length > 0 ? (
          selectedWord?.images.map((image, index) => (
            <img
              onClick={() => setSelectedImageId(image.id)}
              key={index}
              src={image.url}
              alt="word"
              className={`img-thumbnail ${
                selectedImageId === image.id ? "selected-image" : ""
              }`}
              style={{
                width: "100px",
                height: "100px",
                cursor: "pointer", // Add pointer cursor for better UX
              }}
            />
          ))
        ) : (
          <p className="text-muted">No images available.</p>
        )}
      </div>

      {/* Upload Images */}
      <input
        type="file"
        accept="image/*"
        className="form-control mb-3"
        onChange={handleChooseImage} // Updated to use file input
      />

      {/* Action Buttons */}
      <div className="d-flex justify-content-end gap-2 mt-3">
        <button
          disabled={uploadWordImageMutation.isPending}
          className="btn btn-success"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          disabled={deleteImageMutation.isPending}
          className="btn btn-danger"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default Images;

// File Validation Function
const validateFile = (file) => {
  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const maxSize = 20 * 1024; // 10 KB

  if (!allowedTypes.includes(file.type)) {
    alert("Only JPG, JPEG, and PNG files are allowed.");
    return false;
  }

  if (file.size > maxSize) {
    alert("File size exceeds 20kb.");
    return false;
  }

  return true;
};
