import React from "react";
import { useSubcategories } from "../../../../../../hooks/AdminDashboard/useSubcategories";

const SubcategoryList = ({
  setSelectedSubcategoryIds,
  setSelectedSubcategoryId,
  selectedSubcategoryIds,
  selectedCategoryId,
}) => {
  const { data: subcategories, isLoading } =
    useSubcategories(selectedCategoryId);

  // Toggle category selection
  const toggleSubcategorySelection = (subcategoryId) => {
    setSelectedSubcategoryIds(
      (prevSelected) =>
        prevSelected.includes(subcategoryId)
          ? prevSelected.filter((id) => id !== subcategoryId) // Deselect
          : [...prevSelected, subcategoryId] // Select
    );
  };
  return (
    <div>
      {isLoading ? (
        "Loading ..."
      ) : (
        <ul className="list-group">
          {subcategories.map((subcategory) => (
            <li
              key={subcategory.id}
              className="list-group-item d-flex align-items-center justify-content-between"
            >
              {/* Checkbox */}
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={selectedSubcategoryIds.includes(subcategory.id)}
                  onChange={() => toggleSubcategorySelection(subcategory.id)}
                  className="me-2"
                />

                <span onClick={() => setSelectedSubcategoryId(subcategory.id)}>
                  {subcategory.name} | {subcategory.description}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SubcategoryList;
