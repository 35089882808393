import React from "react";
import ReadingTestList from "./ReadingTestList"; // Import Reading Test List component
import ListeningTestList from "./ListeningTestList"; // Import Listening Test List component
import SpeakingTestList from "./SpeakingTestList"; // Import Speaking Test List component
import WritingTestList from "./WritingTestList"; // Import Writing Test List component

const TestList = ({
  selectedType,
  selectedSubcategoryId,
  setSelectedReadingTests,
  setSelectedListeningTests,
  setSelectedSpeakingTests,
  setSelectedWritingTests,
  selectedReadingTests,
  selectedListeningTests,
  selectedSpeakingTests,
  selectedWritingTests,
}) => {
  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row h-100">
        {/* Dynamically Render Based on Selected Type */}
        {selectedType === "Reading" && (
          <ReadingTestList
            selectedSubcategoryId={selectedSubcategoryId}
            setSelectedReadingTests={setSelectedReadingTests}
            selectedReadingTests={selectedReadingTests}
          />
        )}

        {selectedType === "Listening" && (
          <ListeningTestList
            selectedSubcategoryId={selectedSubcategoryId}
            setSelectedListeningTests={setSelectedListeningTests}
            selectedListeningTests={selectedListeningTests}
          />
        )}

        {selectedType === "Speaking" && (
          <SpeakingTestList
            selectedSubcategoryId={selectedSubcategoryId}
            setSelectedSpeakingTests={setSelectedSpeakingTests}
            selectedSpeakingTests={selectedSpeakingTests}
          />
        )}

        {selectedType === "Writing" && (
          <WritingTestList
            selectedSubcategoryId={selectedSubcategoryId}
            setSelectedWritingTests={setSelectedWritingTests}
            selectedWritingTests={selectedWritingTests}
          />
        )}
      </div>
    </div>
  );
};

export default TestList;
