export const validateWritingTests = (tests) => {
  console.log("Validating writing tests:", tests);

  // Validate each test
  const isValid = tests.every((test, testIndex) => {
    console.log(`\nValidating Test ${testIndex + 1}:`, test);

    // Validate Test fields
    if (!test.name || !test.description) {
      console.error(
        `Test ${testIndex + 1} failed: Missing name or description.`
      );
      return false;
    }

    // Validate tasks array
    if (!Array.isArray(test.tasks) || test.tasks.length === 0) {
      console.error(
        `Test ${testIndex + 1} failed: Tasks must be a non-empty array.`
      );
      return false;
    }

    // Validate each task
    const taskValid = test.tasks.every((task, taskIndex) => {
      console.log(`  Validating Task ${taskIndex + 1}:`, task);

      // Validate Task fields
      if (!task.task_number || !task.prompt) {
        console.error(
          `Task ${taskIndex + 1} failed: Missing task_number or prompt.`
        );
        return false;
      }

      // Validate Image URL (if provided)
      if (task.image && !isValidURL(task.image)) {
        console.error(
          `Task ${taskIndex + 1} failed: Invalid image URL - ${task.image}`
        );
        return false;
      }

      return true; // Task is valid
    });

    if (!taskValid) return false;

    return true; // Test is valid
  });

  console.log("Validation Result:", isValid);
  return isValid;
};

// Helper function to validate URLs
const isValidURL = (url) => {
  const urlRegex = /^(http|https):\/\/[^\s$.?#].[^\s]*$/gm; // Basic URL validation regex
  return urlRegex.test(url);
};
