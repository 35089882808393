import React, { useState } from "react";

const Matching = ({
  questionNumber,
  questionData,
  answer,
  isSubmitted,
  handleAnswerSelect,
}) => {
  const [selectedMatches, setSelectedMatches] = useState({});

  const handleSelectChange = (key, selectedValue) => {
    const updatedMatches = {
      ...selectedMatches,
      [key]: selectedValue,
    };
    setSelectedMatches(updatedMatches);
    handleAnswerSelect(questionData.id, updatedMatches); // Pass the updated selections to the parent
  };

  return (
    <div className="mb-4 border rounded shadow-sm p-3">
      <p className="fw-semibold">
        {questionNumber}. {questionData.text}
      </p>
      <ul className="list-group">
        {questionData.choices.map((pair, idx) => {
          const keyField = Object.keys(pair)[0]; // Example: 'meaning'
          const valueField = Object.keys(pair)[1]; // Example: 'preposition'

          const isCorrectChoice =
            isSubmitted && selectedMatches[pair[keyField]] === pair[valueField];
          const isSelectedChoice =
            isSubmitted && selectedMatches[pair[keyField]] !== pair[valueField];

          const choiceClass = isSubmitted
            ? isCorrectChoice
              ? "border-success bg-light-success"
              : isSelectedChoice
              ? "border-danger bg-light-danger"
              : ""
            : "";

          return (
            <li
              key={idx}
              className={`list-group-item shadow-sm mb-2 list-group-item-action ${choiceClass}`}
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: isCorrectChoice
                  ? "#28a745"
                  : isSelectedChoice
                  ? "#dc3545"
                  : "#e3e3e3",
                backgroundColor: isCorrectChoice
                  ? "rgba(40, 167, 69, 0.1)" // Light green
                  : isSelectedChoice
                  ? "rgba(220, 53, 69, 0.1)" // Light red
                  : "#fff",
              }}
            >
              <label className="form-check-label w-100 d-flex flex-column">
                <span className="fw-semibold">{pair[keyField]}</span>
                <select
                  id={`select-${questionData.id}-${idx}`}
                  className="form-select mt-2"
                  onChange={(e) =>
                    handleSelectChange(pair[keyField], e.target.value)
                  }
                  disabled={isSubmitted}
                  value={selectedMatches[pair[keyField]] || ""}
                >
                  <option value="">Select an option</option>
                  {questionData.choices.map((choice, i) => (
                    <option key={i} value={choice[valueField]}>
                      {choice[valueField]}
                    </option>
                  ))}
                </select>
              </label>

              {isSubmitted && isCorrectChoice && (
                <span
                  className="ms-auto mt-2 text-success"
                  style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  ✅
                </span>
              )}
              {isSubmitted && isSelectedChoice && (
                <span
                  className="ms-auto mt-2 text-danger"
                  style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  ❌
                </span>
              )}
            </li>
          );
        })}
      </ul>

      {/* Show correct answers if submission is incorrect */}
      {isSubmitted &&
        answer?.selectedAnswers &&
        Object.keys(selectedMatches).some(
          (key) => selectedMatches[key] !== answer.correctAnswers[key]
        ) && (
          <p className="mt-3 text-success">
            <strong>Correct Answers:</strong>{" "}
            {Object.entries(answer.correctAnswers)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")}
          </p>
        )}

      {/* Feedback Section */}
      {answer?.feedback && (
        <p className="mt-2 text-muted">
          <em>{answer.feedback}</em>
        </p>
      )}
    </div>
  );
};

export default Matching;
