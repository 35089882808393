import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUpdateSubcategory,
  useDeleteSubcategory,
} from "./../../../../../hooks/AdminDashboard/useSubcategories";

const SubcategoryList = ({
  categoryId,
  subcategories,
  onSelectSubcategory,
  onBack,
  onAddLesson,
  children,
}) => {
  const [editSubcategoryId, setEditSubcategoryId] = useState(null);
  const [editSubcategoryData, setEditSubcategoryData] = useState({
    name: "",
    description: "",
  });

  const queryClient = useQueryClient();

  // Mutations
  const updateSubcategoryMutation = useUpdateSubcategory(() => {
    alert("Subcategory updated successfully!");
    console.log(categoryId);
    queryClient.invalidateQueries(["subcategories", categoryId]);
  });

  const deleteSubcategoryMutation = useDeleteSubcategory(() =>
    alert("Subcategory deleted successfully!")
  );

  const handleEditClick = (subcategory) => {
    setEditSubcategoryId(subcategory.id);
    setEditSubcategoryData({
      name: subcategory.name,
      description: subcategory.description,
    });
  };

  const handleEditSave = () => {
    updateSubcategoryMutation.mutate({
      categoryId: categoryId, // Correct key and value
      subcategoryId: editSubcategoryId, // Correct key and value
      subcategoryData: editSubcategoryData, // Subcategory data
    });

    console.log(categoryId);
    console.log(editSubcategoryId);

    console.log(editSubcategoryData);

    setEditSubcategoryId(null); // Reset after saving
  };

  const handleDeleteClick = (subcategoryId) => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      deleteSubcategoryMutation.mutate({
        categoryId,
        subcategoryId,
      });
    }
  };

  return (
    <div className=" mt-4">
      <Button variant="secondary" className="mb-3" onClick={onBack}>
        Back
      </Button>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Lessons</h5>
        <Button variant="primary" size="sm" onClick={onAddLesson}>
          Add Lesson
        </Button>
      </div>

      <ul className="list-group">
        {subcategories.map((subcategory) => (
          <li
            key={subcategory.id}
            className="list-group-item d-flex align-items-center justify-content-between"
          >
            <div>
              {editSubcategoryId === subcategory.id ? (
                <>
                  <Form.Control
                    type="text"
                    value={editSubcategoryData.name}
                    onChange={(e) =>
                      setEditSubcategoryData({
                        ...editSubcategoryData,
                        name: e.target.value,
                      })
                    }
                  />
                  <Form.Control
                    type="text"
                    value={editSubcategoryData.description}
                    onChange={(e) =>
                      setEditSubcategoryData({
                        ...editSubcategoryData,
                        description: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <span
                  onClick={() => onSelectSubcategory(subcategory.id)}
                  className="me-3"
                >
                  {subcategory.name} | {subcategory.description}
                </span>
              )}
            </div>
            <div>
              {editSubcategoryId === subcategory.id ? (
                <Button
                  className="btn btn-primary btn-sm me-2"
                  onClick={handleEditSave}
                  disabled={updateSubcategoryMutation.isPending}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={updateSubcategoryMutation.isPending}
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => handleEditClick(subcategory)}
                >
                  Edit
                </Button>
              )}
              <Button
                className="btn btn-danger btn-sm"
                onClick={() => handleDeleteClick(subcategory.id)}
              >
                Delete
              </Button>
            </div>
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
};

export default SubcategoryList;
