import React, { useState } from "react";

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      style={{
        marginBottom: "0.5rem",
        border: "1px solid #ddd",
        borderRadius: "4px",
      }}
    >
      <div
        onClick={toggle}
        style={{
          backgroundColor: "#f2f2f2",
          padding: "0.5rem",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      {isOpen && <div style={{ padding: "0.5rem" }}>{children}</div>}
    </div>
  );
};

export default Collapsible;
