const extractTests = (attempt) => {
  const tests = [];

  // Grammar and Vocabulary tests
  ["grammar", "vocabulary", "vocabulary_translation"].forEach((key) => {
    if (attempt.questions[key].length == 0) return;
    if (attempt.questions[key]) {
      const answers = attempt.answers[key];
      const isSubmitted = answers?.answers.length > 0 ? true : false;

      tests.push({
        type: key,
        // name:
        //   key === "vocabulary_translation"
        //     ? "vocabulary translation"
        //     : key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
        description: `This is the ${
          key === "vocabulary_translation" ? "vocabulary translation" : key
        } test.`,
        number_of_questions: attempt.questions[key].length,
        questions: attempt.questions[key],
        answers: answers?.answers || [],
        score: answers?.score,
        isSubmitted: isSubmitted,
      });
    }
  });

  // Reading, Listening, Speaking, Writing tests
  ["reading", "listening", "speaking", "writing"].forEach((key) => {
    if (attempt.questions[key].length == 0) return;
    if (attempt.questions[key]) {
      attempt.questions[key].forEach((test) => {
        const totalQuestions = calculateTotalQuestions(test, key);

        const answers = attempt.answers?.[key]?.[test.id];

        let isSubmitted = false;
        let averageScore = 0;

        if (key === "writing") {
          isSubmitted =
            Object.keys(answers || {}).length === test.tasks.length && true;

          averageScore = isSubmitted ? calculateAverageScore(answers) : 0;
        } else {
          isSubmitted = answers?.answers.length > 0 ? true : false;
        }

        tests.push({
          type: key,
          number_of_questions: totalQuestions,
          answers: key === "writing" ? answers : answers?.answers || [],
          score: key === "writing" ? averageScore : answers?.score,
          isSubmitted: isSubmitted,
          ...test,
        });
      });
    }
  });

  return tests;
};

// Helper function to calculate total questions for reading, listening, speaking, and writing
const calculateTotalQuestions = (test, key) => {
  if (key === "reading") {
    return test.passages
      ? test.passages.reduce(
          (total, passage) =>
            total +
            passage.sections.reduce(
              (sectionTotal, section) =>
                sectionTotal +
                (section.questions ? section.questions.length : 0),
              0
            ),
          0
        )
      : 0;
  } else if (key === "listening") {
    return test.sections
      ? test.sections.reduce(
          (total, section) =>
            total +
            section.parts.reduce(
              (partTotal, part) =>
                partTotal + (part.questions ? part.questions.length : 0),
              0
            ),
          0
        )
      : 0;
  } else if (key === "speaking") {
    return test.parts
      ? test.parts.reduce(
          (total, part) => total + (part.questions ? part.questions.length : 0),
          0
        )
      : 0;
  } else if (key === "writing") {
    return test.tasks ? test.tasks.length : 0;
  }
  return 0;
};

function calculateAverageScore(answers) {
  const values = Object.values(answers); // Extract values from the dictionary
  if (!values.length) return 0; // Handle empty dictionary

  const totalScore = values.reduce((sum, answer) => sum + answer.score, 0);
  return totalScore / values.length;
}

export default extractTests;
