import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import {
  useGroups,
  useAttachCategories,
  useDetachCategories,
} from "./../../../../../hooks/AdminDashboard/useGroups";

import {
  useUpdateCategory,
  useDeleteCategory,
} from "./../../../../../hooks/AdminDashboard/useCategories";

const CategoryList = ({
  categories,
  onSelectCategory,
  onAddCategory,
  children,
}) => {
  // Fetch groups
  const { data: groups, isLoading: loadingGroups } = useGroups();

  // React State Hooks
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryData, setEditCategoryData] = useState({
    name: "",
    description: "",
  });

  // Query Client
  const queryClient = useQueryClient();

  // Mutations
  const attachMutation = useAttachCategories(() => {
    alert("Attached successfully!");
  });

  const detachMutation = useDetachCategories(() =>
    alert("Successfully detached!")
  );

  const updateCategoryMutation = useUpdateCategory(() => {
    queryClient.invalidateQueries(["categories"]);
    alert("Successfully updated!");
  });

  const deleteCategoryMutation = useDeleteCategory(() => {
    queryClient.invalidateQueries(["categories"]);
    alert("Successfully deleted!");
  });

  // Toggle category selection
  const toggleCategorySelection = (categoryId) => {
    setSelectedCategories(
      (prevSelected) =>
        prevSelected.includes(categoryId)
          ? prevSelected.filter((id) => id !== categoryId) // Deselect
          : [...prevSelected, categoryId] // Select
    );
  };

  // Handle Attach Categories
  const handleAttachClick = () => {
    if (!selectedGroup) {
      alert("Please select a group first.");
      return;
    }

    if (selectedCategories.length === 0) {
      alert("Please select at least one category.");
      return;
    }

    // Perform Attach Mutation
    attachMutation.mutate({
      groupId: selectedGroup,
      categories: selectedCategories,
    });
  };

  // Handle Detach Categories
  const handleDetachClick = () => {
    if (!selectedGroup) {
      alert("Please select a group first.");
      return;
    }

    if (selectedCategories.length === 0) {
      alert("Please select at least one category.");
      return;
    }

    // Perform Detach Mutation
    detachMutation.mutate({
      groupId: selectedGroup,
      categories: selectedCategories,
    });
  };

  // Handle Edit Category
  const handleEditClick = (category) => {
    setEditCategoryId(category.id);
    setEditCategoryData({
      name: category.name,
      description: category.description,
    });
  };

  const handleEditSave = () => {
    updateCategoryMutation.mutate({
      id: editCategoryId,
      categoryData: editCategoryData,
    });
    setEditCategoryId(null);
  };

  // Handle Delete Category
  const handleDeleteClick = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      deleteCategoryMutation.mutate(categoryId);
    }
  };

  return (
    <div className=" mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Categories</h5>
        <div className="d-flex align-items-center mt-4">
          {loadingGroups ? (
            "Loading groups..."
          ) : (
            <select
              id="group-dropdown"
              className="form-select w-35 me-2"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              <option value="">Select a Group</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
          )}

          {/* Attach Button */}
          <button
            className="btn btn-success"
            size="sm"
            onClick={handleAttachClick}
            disabled={attachMutation.isPending || loadingGroups}
          >
            {attachMutation.isPending ? "Attaching..." : "Attach"}
          </button>

          {/* Detach Button */}
          <button
            className="btn btn-danger ms-2"
            size="sm"
            onClick={handleDetachClick}
            disabled={detachMutation.isPending || loadingGroups}
          >
            {detachMutation.isPending ? "Detaching..." : "Detach"}
          </button>

          <button className="btn btn-success ms-2" onClick={onAddCategory}>
            Add Category
          </button>
        </div>
      </div>

      <ul className="list-group">
        {categories.map((category) => (
          <li
            key={category.id}
            className="list-group-item d-flex align-items-center justify-content-between"
          >
            {/* Checkbox */}
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={selectedCategories.includes(category.id)}
                onChange={() => toggleCategorySelection(category.id)}
                className="me-2"
              />
              {editCategoryId === category.id ? (
                <>
                  <input
                    type="text"
                    value={editCategoryData.name}
                    onChange={(e) =>
                      setEditCategoryData({
                        ...editCategoryData,
                        name: e.target.value,
                      })
                    }
                    className="form-control me-2"
                  />
                  <input
                    type="text"
                    value={editCategoryData.description}
                    onChange={(e) =>
                      setEditCategoryData({
                        ...editCategoryData,
                        description: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </>
              ) : (
                <span onClick={() => onSelectCategory(category.id)}>
                  {category.name} | {category.description}
                </span>
              )}
            </div>

            {/* Action Buttons */}
            <div>
              {editCategoryId === category.id ? (
                <button
                  className="btn btn-primary btn-sm me-2"
                  onClick={handleEditSave}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => handleEditClick(category)}
                  disabled={updateCategoryMutation.isPending}
                >
                  Edit
                </button>
              )}

              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleDeleteClick(category.id)}
                disabled={deleteCategoryMutation.isPending}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>

      {children}
    </div>
  );
};

export default CategoryList;
