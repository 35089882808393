import { useState } from "react";
import DangerConfirmationPopup from "../../../../Shared/DangerConfirmationPopup";

const Footer = ({
  currentPassageIndex,
  totalPassages,
  onNavigate,
  handleSubmit,
  isSubmitted,
  isSubmitting,
}) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  return (
    <div className="footer bg-light p-3 d-flex justify-content-between align-items-center border-top">
      {/* Submit Button on the Left */}
      {!isSubmitted && (
        <button
          className="btn btn-success"
          onClick={() => setShowConfirmPopup(true)}
          disabled={isSubmitting || currentPassageIndex !== totalPassages - 1}
        >
          Submit
        </button>
      )}
      <DangerConfirmationPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        onConfirm={handleSubmit}
        message={
          "Are you sure you want to submit the test? You cannot undo this action!"
        }
      />

      <span>
        Passage {currentPassageIndex + 1} of {totalPassages}
      </span>

      {/* Navigation Buttons on the Right */}
      <div className="d-flex">
        <button
          className="btn btn-warning me-2"
          onClick={() => onNavigate("back")}
          disabled={currentPassageIndex === 0}
        >
          &larr; Back
        </button>
        <button
          className="btn btn-warning"
          onClick={() => onNavigate("next")}
          disabled={currentPassageIndex === totalPassages - 1}
        >
          Next &rarr;
        </button>
      </div>
    </div>
  );
};

export default Footer;
