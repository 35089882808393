import React from "react";
import TimeLimit from "../TimeLimit";

const Header = ({
  myName,
  assignmentId,
  onExit,
  onBack,
  time_left,
  selectedTest,
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between bg-dark p-2 sticky-top"
      style={{ zIndex: 1050 }}
    >
      {/* Left side buttons */}
      <div className="d-flex align-items-center">
        {!selectedTest ? (
          <button className="btn btn-danger me-3 btn-sm" onClick={onExit}>
            Exit
          </button>
        ) : (
          <button className="btn btn-light btn-sm" onClick={onBack}>
            Back
          </button>
        )}
      </div>

      {/* Center time limit */}
      <div className="d-flex justify-content-center flex-grow-1">
        <TimeLimit initialTimeInSeconds={time_left} />
      </div>

      {/* Right side: Student name and assignment ID on one line */}
      <div className="d-flex align-items-center">
        <span className="text-light me-2">{myName}</span>
        <span className="text-secondary">| Assignment #{assignmentId}</span>
      </div>
    </div>
  );
};

export default Header;
