import React from "react";

export const ErrorPage = () => {
  return (
    <div>
      <h1>
        <center>Siz izlayotgan sahifa muvjud emas!</center>
      </h1>
    </div>
  );
};
