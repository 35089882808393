import api from "../api";

// ==========================
// 1. Fetch All Writing Tests (Simple List)
// ==========================
export const fetchSimpleWritingTests = async (subcategoryId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/writing-tests/`
  );
  return response.data;
};

// ==========================
// 2. Fetch a Single Writing Test with Details (Tasks)
// ==========================
export const fetchWritingTestById = async (subcategoryId, testId) => {
  const response = await api.get(
    `/api/admin/subcategory/${subcategoryId}/writing-tests/${testId}/`
  );
  return response.data;
};

// ==========================
// 3. Bulk Upload Writing Tests
// ==========================
export const bulkUploadWritingTests = async (subcategoryId, data) => {
  console.log(" data ===== ", data); // Debugging data before sending
  const response = await api.post(
    `/api/admin/subcategory/${subcategoryId}/writing-tests/bulk_create/`,
    data
  );
  return response.data;
};

// ==========================
// 4. Bulk Update Writing Test
// ==========================
export const bulkUpdateWritingTest = async (subcategoryId, testId, data) => {
  const response = await api.put(
    `/api/admin/subcategory/${subcategoryId}/writing-tests/${testId}/update_test/`,
    data
  );
  return response.data;
};

// ==========================
// 5. Delete a Writing Test
// ==========================
export const deleteWritingTest = async (subcategoryId, testId) => {
  const response = await api.delete(
    `/api/admin/subcategory/${subcategoryId}/writing-tests/${testId}/`
  );
  return response.data;
};
