import React from "react";

const StudentProfile = ({ student }) => {
  return (
    <div className="card shadow-sm p-4">
      <div className="card-body">
        {/* Student Name */}
        <h3 className="card-title text-primary">
          {student.first_name} {student.last_name}
        </h3>

        <div className="mt-3">
          {/* Date of Birth */}
          <p className="mb-2">
            <strong>Date of Birth:</strong>{" "}
            <span className="text-muted">{student.date_of_birth || "N/A"}</span>
          </p>

          {/* School */}
          <p className="mb-2">
            <strong>School:</strong>{" "}
            <span className="text-muted">{student.school || "N/A"}</span>
          </p>

          {/* Phone Number */}
          <p className="mb-2">
            <strong>Phone Number:</strong>{" "}
            <span className="text-muted">{student.phone_number || "N/A"}</span>
          </p>

          {/* Enrollment Date */}
          <p className="mb-2">
            <strong>Enrollment Date:</strong>{" "}
            <span className="text-muted">
              {student.enrollment_date || "N/A"}
            </span>
          </p>

          {/* Photo */}
          <p className="mb-0">
            <strong>Photo:</strong>{" "}
            <span
              className={`badge ${student.photo ? "bg-success" : "bg-danger"}`}
            >
              {student.photo ? "Uploaded" : "Not Available"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
