import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { registerUser } from "../../api/authAPI";
import {
  validatePhoneNumber,
  validatePasswordStrength,
  validatePasswordMatch,
} from "../../utils/validation";
import { formatPhoneNumber } from "../../utils/formatter";

function RegisterPage() {
  const navigate = useNavigate();

  // Form and error states
  const [formData, setFormData] = useState({
    phone_number: "+998",
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
  });
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility

  // Mutation for registration
  const { mutate, isError, isPending, error } = useMutation({
    mutationFn: registerUser,
    onSuccess: () => {
      alert("Ro'yxatdan o'tdingiz!");
      navigate("/login");
    },
    onError: (error) => {
      console.error(
        "Registration failed:",
        error.response?.data || error.message
      );
      alert("Ro'yxatdan o'tishda xatolik ro'y berdi.");
    },
  });

  // Handle phone input change
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormData((prev) => ({ ...prev, phone_number: formattedPhone }));

    setPhoneError(
      validatePhoneNumber(formattedPhone)
        ? ""
        : "Telefon raqam noto'g'ri formatda!"
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "password") {
      setPasswordStrength(validatePasswordStrength(value));
      setPasswordError(validatePasswordMatch(value, formData.confirmPassword));
    }
    if (name === "confirmPassword") {
      setPasswordError(validatePasswordMatch(formData.password, value));
    }
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneError || passwordError || passwordStrength === "Weak") return;

    let cleanedPhoneNumber = formData.phone_number.replace(/\D/g, "");

    if (!cleanedPhoneNumber.startsWith("998")) {
      cleanedPhoneNumber = "998" + cleanedPhoneNumber.slice(3);
    }

    if (cleanedPhoneNumber.length > 12) {
      cleanedPhoneNumber = cleanedPhoneNumber.slice(0, 12);
    }

    const cleanedFormData = { ...formData, phone_number: cleanedPhoneNumber };

    mutate(cleanedFormData);
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card shadow">
            <div className="card-header text-white bg-success">
              <h3 className="text-center">Ro'yxatdan o'tish</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                {/* Phone Input */}
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Telefon raqamingiz
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handlePhoneChange}
                    autoComplete="off"
                    required
                  />
                  {phoneError && (
                    <small className="text-danger">{phoneError}</small>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label">
                    Ismingiz
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label">
                    Familiyangiz
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                  />
                </div>

                {/* Password Input */}
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Parol
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    autoComplete="new-password"
                    required
                  />
                </div>

                {/* Confirm Password Input */}
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Parolni tasdiqlang
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    autoComplete="new-password"
                    required
                  />
                  {(passwordError || passwordStrength) && (
                    <small className="text-danger">
                      {passwordError} <br />
                      {passwordStrength === "Weak" &&
                        "Parol kuchsiz! Kuchliroq parol kiriting."}
                    </small>
                  )}
                </div>

                {/* Show Password Toggle */}
                <div className="mb-3">
                  <input
                    type="checkbox"
                    id="showPassword"
                    onChange={() => setShowPassword(!showPassword)}
                  />
                  <label htmlFor="showPassword" className="ms-2">
                    Parolni ko'rsatish
                  </label>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="btn w-100 text-white bg-success mb-3"
                  disabled={
                    isPending ||
                    phoneError ||
                    passwordError ||
                    passwordStrength === "Weak"
                  }
                >
                  {isPending ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Ro'yxatdan o'tish"
                  )}
                </button>

                {/* Error Alert */}
                {isError && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {error.response?.data?.detail ||
                      "Ro'yxatdan o'tishda xatolik yuz berdi!"}
                  </div>
                )}

                {/* Redirect to Login */}
                <center>
                  <p>Akkauntingiz bormi?</p>
                  <Link to="/login" className="text-link">
                    Kirish
                  </Link>
                </center>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
