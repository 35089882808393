import { React, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useAddGroup } from "../../../../../../hooks/AdminDashboard/useGroups";
import AddGroupModel from "./AddGroupModel";
import PopupMessage from "./../../../../../Shared/PopupMessage";

const AddNewGroupButton = () => {
  const queryClient = useQueryClient();
  const [showMessage, setShowMessage] = useState(false);
  const [showAddGroupModel, setShowAddGroupModel] = useState(false);
  const [groupData, setGroupData] = useState({
    name: "",
    description: "",
    class_days: ["Monday", "Wednesday", "Friday"],
    start_time: "16:00",
  });

  const { mutate: addGroup, isPending: addingGroup } = useAddGroup(() => {
    queryClient.invalidateQueries(["groups"]);
    setShowAddGroupModel(false);
    setShowMessage(true);
  });

  function handleAddGroup() {
    if (!groupData.name || !groupData.description) {
      alert("Group name and description must not be empty!");
      return;
    }
    addGroup(groupData);
    console.log("Add group");
  }
  return (
    <div>
      <button
        onClick={() => setShowAddGroupModel(true)}
        className="btn btn-light btn-sm"
      >
        +
      </button>
      <AddGroupModel
        show={showAddGroupModel}
        handleClose={() => setShowAddGroupModel(false)}
        handleAdd={handleAddGroup}
        newItem={groupData}
        setNewItem={setGroupData}
        isLoading={addingGroup}
        title={"Add a new group"}
      />

      {showMessage && (
        <PopupMessage
          onClose={() => setShowMessage(false)}
          message={"Successfully added!"}
        />
      )}
    </div>
  );
};

export default AddNewGroupButton;
