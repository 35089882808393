import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import { useBulkUploadWords } from "../../../../../../hooks/AdminDashboard/useWords";
import { validateBulkWordsWithQuestions } from "../../../../../../utils/validation";

const WordsBulkupload = ({ categoryId, subcategoryId }) => {
  const [jsonData, setJsonData] = useState(sampleWords);
  const [uploadErrors, setUploadErrors] = useState([]);

  const bulkUploadMutation = useBulkUploadWords(
    () => {
      queryClient.invalidateQueries(["words", categoryId, subcategoryId]);
      setJsonData("");
      setUploadErrors([]);
      alert("Uploaded successfully!");
    },
    (err) => handleErrors(err)
  );

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one question!");
      return;
    }
    try {
      const wordsData = JSON.parse(jsonData);
      if (!validateBulkWordsWithQuestions(wordsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        categoryId,
        subcategoryId,
        wordsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add NEW words.</h4>

      <MonacoEditor
        height="600px"
        language="json"
        value={jsonData}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WordsBulkupload;

const sampleWords = `[
  {
    "name": "Ocean",
    "description": "A large body of salt water."
  }
]`;
