import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchStudentsWithoutGroups,
  // fetchStudentsByGroup,
  fetchStudentById,
  updateStudent,
  bulkDeleteStudents,
} from "./../../api/adminAPI/studentAPI";

// Fetch all students
// export const useStudentsByGroup = (groupId) =>
//   useQuery({
//     queryKey: ["students"],
//     queryFn: () => fetchStudentsByGroup(groupId),
//   });

// Fetch students without groups
export const useStudentsWithoutGroups = () =>
  useQuery({
    queryKey: ["studentsWithoutGroups"],
    queryFn: fetchStudentsWithoutGroups,
    staleTime: 1000 * 60 * 60,
  });

// Fetch a single student by ID
export const useStudent = (id) =>
  useQuery({
    queryKey: ["student", id],
    queryFn: () => fetchStudentById(id),
    enabled: !!id,
  });

// Update student data
export const useUpdateStudent = (onSuccess) =>
  useMutation({
    mutationFn: ({ studentId, data }) => updateStudent(studentId, data),
    onSuccess,
  });

// Bulk delete students
export const useBulkDeleteStudents = (onSuccess) =>
  useMutation({
    mutationFn: bulkDeleteStudents,
    onSuccess,
  });
