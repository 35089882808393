import { React, useState } from "react";

const TrueFalseNotGiven = ({
  questionNumber,
  questionData,
  answer,
  isSubmitted,
  handleAnswerSelect,
}) => {
  // Local state for selected choice
  const [selectedChoice, setSelectedChoice] = useState(
    answer?.selectedAnswer || ""
  );

  // Handle selection change
  const handleChange = (choice) => {
    setSelectedChoice(choice); // Update local state
    handleAnswerSelect(questionData.id, choice); // Call parent handler
  };

  return (
    <div className="mb-4 border rounded shadow-sm p-3">
      <p className="fw-semibold">
        {questionNumber}. {questionData.text}
      </p>
      <ul className="list-group">
        {questionData.choices.map((choice, idx) => {
          return (
            <li
              key={idx}
              className={`list-group-item shadow-sm mb-2 list-group-item-action ${
                isSubmitted ? "disabled" : ""
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => handleChange(choice)} // Trigger when clicking on <li>
            >
              <label
                className="form-check-label w-100 d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  className="me-2"
                  id={`truefalse-${questionData.id}-${idx}`}
                  name={`truefalse-question-${questionData.id}`}
                  value={choice}
                  onChange={() => handleChange(choice)}
                  disabled={isSubmitted}
                  checked={selectedChoice === choice}
                  style={{ cursor: "pointer" }}
                />
                {choice}
              </label>
            </li>
          );
        })}
      </ul>

      {/* Show feedback and correct answer if submission is incorrect */}
      {isSubmitted && !answer?.is_correct && (
        <>
          <p className="mt-2 text-success">
            <strong>Correct Answer:</strong> {questionData.correct_answer}
          </p>
          <p className="mt-3 text-danger">
            <strong>Feedback:</strong> <em>{questionData.feedback}</em>
          </p>
        </>
      )}
    </div>
  );
};

export default TrueFalseNotGiven;
