import React, { useState, useEffect } from "react";

import "bootstrap-icons/font/bootstrap-icons.css";

const TimeLimit = ({ initialTimeInSeconds }) => {
  // Safely parse initial time
  const initialTime = Number(initialTimeInSeconds);
  const [timeLeft, setTimeLeft] = useState(
    !Number.isNaN(initialTime) && initialTime > 0 ? Math.floor(initialTime) : 0
  );

  useEffect(() => {
    // If we've reached 0 or below, no need to schedule another timeout
    if (timeLeft <= 0) return;

    // Schedule to decrement timeLeft after 1 second
    const timerId = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Cleanup on unmount or re-render
    return () => clearTimeout(timerId);
  }, [timeLeft]);
  // ^ Including timeLeft so each change schedules the next timeout

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format as HH:MM:SS with zero padding
    const hh = String(hours).padStart(2, "0");
    const mm = String(minutes).padStart(2, "0");
    const ss = String(seconds).padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
  };

  return (
    <div className="text-light">
      <i className="bi bi-clock me-1"></i> {formatTime(timeLeft)} left
    </div>
  );
};

export default TimeLimit;
