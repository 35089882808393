export const validateListeningTests = (tests) => {
  console.log("Validating tests:", tests);

  // Validate each test
  const isValid = tests.every((test, testIndex) => {
    console.log(`\nValidating Test ${testIndex + 1}:`, test);

    // Validate Test fields
    if (!test.name || !test.description || !test.link) {
      console.error(
        `Test ${testIndex + 1} failed: Missing name, description, or link.`
      );
      return false;
    }

    if (!Array.isArray(test.sections) || test.sections.length === 0) {
      console.error(
        `Test ${testIndex + 1} failed: Sections must be a non-empty array.`
      );
      return false;
    }

    // Validate each section
    const sectionValid = test.sections.every((section, sectionIndex) => {
      console.log(`  Validating Section ${sectionIndex + 1}:`, section);

      // Validate Section fields
      if (!section.name || !section.instructions || !section.order) {
        console.error(
          `Section ${
            sectionIndex + 1
          } failed: Missing name, instructions, order`
        );
        return false;
      }

      // // **Validate Transcript**
      // if (!section.transcript) {
      //   console.error(
      //     `Section ${sectionIndex + 1} failed: Missing transcript field.`
      //   );
      //   return false;
      // }

      if (!Array.isArray(section.parts) || section.parts.length === 0) {
        console.error(
          `Section ${sectionIndex + 1} failed: Parts must be a non-empty array.`
        );
        return false;
      }

      // Validate each part
      const partValid = section.parts.every((part, partIndex) => {
        console.log(`    Validating Part ${partIndex + 1}:`, part);

        // Validate Part fields
        if (!part.name || !part.instructions || !part.order) {
          console.error(
            `Part ${
              partIndex + 1
            } failed: Missing name, instructions, or order.`
          );
          return false;
        }

        if (!Array.isArray(part.questions) || part.questions.length === 0) {
          console.error(
            `Part ${partIndex + 1} failed: Questions must be a non-empty array.`
          );
          return false;
        }

        // Validate each question
        const questionValid = part.questions.every((q, questionIndex) => {
          console.log(`      Validating Question ${questionIndex + 1}:`, q);

          // Required fields
          if (!q.format || !q.text || !q.feedback || !q.order) {
            console.error(
              `Question ${
                questionIndex + 1
              } failed: Missing format, text, feedback, or order.`
            );
            return false;
          }

          // Format-specific validation
          switch (q.format) {
            case "MCQ":
              if (!Array.isArray(q.choices) || q.choices.length === 0) {
                console.error(
                  `MCQ Question ${
                    questionIndex + 1
                  } failed: Choices must be an array.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length !== 1 ||
                !q.choices.includes(q.correct_answer[0])
              ) {
                console.error(
                  `MCQ Question ${
                    questionIndex + 1
                  } failed: Invalid correct answer.`
                );
                return false;
              }
              break;

            case "GAP_FILL":
              if (q.choices !== null) {
                console.error(
                  `GAP_FILL Question ${
                    questionIndex + 1
                  } failed: Choices must be null.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length === 0
              ) {
                console.error(
                  `GAP_FILL Question ${
                    questionIndex + 1
                  } failed: Invalid correct answer.`
                );
                return false;
              }
              break;

            case "MATCHING":
              if (
                !Array.isArray(q.choices) ||
                !Array.isArray(q.correct_answer)
              ) {
                console.error(
                  `MATCHING Question ${
                    questionIndex + 1
                  } failed: Choices and answers must be arrays.`
                );
                return false;
              }
              break;

            case "MULTI_SELECT":
              if (
                !Array.isArray(q.choices) ||
                !Array.isArray(q.correct_answer) ||
                !q.correct_answer.every((ans) => q.choices.includes(ans))
              ) {
                console.error(
                  `MULTI_SELECT Question ${
                    questionIndex + 1
                  } failed: Invalid answers.`
                );
                return false;
              }
              break;

            case "TRUE_FALSE_NOT_GIVEN":
              if (
                !Array.isArray(q.choices) ||
                (q.choices.length !== 2 && q.choices.length !== 3)
              ) {
                console.error(
                  `TRUE_FALSE_NOT_GIVEN Question ${
                    questionIndex + 1
                  } failed: Choices must contain exactly 2 or 3 options.`
                );
                return false;
              }
              if (
                !Array.isArray(q.correct_answer) ||
                q.correct_answer.length !== 1
              ) {
                console.error(
                  `TRUE_FALSE_NOT_GIVEN Question ${
                    questionIndex + 1
                  } failed: Correct answer must be a single item.`
                );
                return false;
              }
              break;

            default:
              console.error(
                `Question ${questionIndex + 1} failed: Unsupported format '${
                  q.format
                }'.`
              );
              return false;
          }

          return true; // Question is valid
        });

        return questionValid;
      });

      return partValid;
    });

    return sectionValid;
  });

  console.log("Validation Result:", isValid);
  return isValid;
};
