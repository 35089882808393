import React, { useEffect, useRef } from "react";

const YouTubePlayer = ({ videoId, onPlayerReady }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    // Check if the YouTube API script is already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else if (window.YT.Player) {
      initializePlayer();
    }

    // Initialize the player when the API is ready
    window.onYouTubeIframeAPIReady = initializePlayer;

    function initializePlayer() {
      if (!playerRef.current) {
        playerRef.current = new window.YT.Player("youtube-player", {
          videoId: videoId,
          events: {
            onReady: (event) => {
              if (onPlayerReady) onPlayerReady(event);
            },
          },
        });
      }
    }

    return () => {
      // Clean up the player when the component unmounts
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
      delete window.onYouTubeIframeAPIReady; // Reset the callback
    };
  }, [videoId, onPlayerReady]);

  return (
    <div id="youtube-player" style={{ width: "100%", height: "100%" }}></div>
  );
};

export default YouTubePlayer;
