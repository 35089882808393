import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createAssignment,
  activateAssignment,
  deactivateAssignment,
  deleteAssignment,
  updateAssignment,
  getActiveAssignmentsByGroup,
  getInactiveAssignmentsByGroup,
  getActiveAssignmentsByStudent,
  getInactiveAssignmentsByStudent,
  startAssignment,
} from "../../api/assignmentsAPI/assignmentAPI";

export const useFetchActiveAssignmentsByGroup = (groupId) => {
  return useQuery({
    queryKey: ["activeAssignmentsByGroup", groupId],
    queryFn: () => getActiveAssignmentsByGroup(groupId),
    enabled: !!groupId, // Ensures the query runs only if groupId is provided
  });
};

export const useFetchInactiveAssignmentsByGroup = (groupId) => {
  return useQuery({
    queryKey: ["inactiveAssignmentsByGroup", groupId],
    queryFn: () => getInactiveAssignmentsByGroup(groupId),
    enabled: !!groupId, // Ensures the query runs only if groupId is provided
  });
};

export const useFetchActiveAssignmentsByStudent = (studentId) => {
  return useQuery({
    queryKey: ["activeAssignmentsByStudent", studentId],
    queryFn: () => getActiveAssignmentsByStudent(studentId),
    enabled: !!studentId, // Ensures the query runs only if studentId is provided
  });
};

export const useFetchInactiveAssignmentsByStudent = (studentId) => {
  return useQuery({
    queryKey: ["inactiveAssignmentsByStudent", studentId],
    queryFn: () => getInactiveAssignmentsByStudent(studentId),
    enabled: !!studentId, // Ensures the query runs only if studentId is provided
  });
};

export const useCreateAssignment = () => {
  return useMutation({
    mutationFn: (assignmentData) => createAssignment(assignmentData),
    onSuccess: () => {
      alert("Created successfully!");
    },
  });
};

export const useActivateAssignment = () => {
  return useMutation({
    mutationFn: (assignmentId) => activateAssignment(assignmentId),
    onSuccess: () => {
      alert("Activated successfully!");
    },
  });
};

export const useDeactivateAssignment = () => {
  return useMutation({
    mutationFn: (assignmentId) => deactivateAssignment(assignmentId),
    onSuccess: () => {
      alert("Deactivated successfully!");
    },
  });
};

export const useDeleteAssignment = () => {
  return useMutation({
    mutationFn: (assignmentId) => deleteAssignment(assignmentId),
    onSuccess: () => {
      alert("Deleted successfully!");
    },
  });
};

export const useUpdateAssignment = () => {
  return useMutation({
    mutationFn: ({ assignmentId, updatedData }) =>
      updateAssignment({ assignmentId, updatedData }),
    onSuccess: () => {
      alert("Updated successfully!");
    },
  });
};

export const useStartAssignment = (assignmentId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ assignmentId, studentId }) =>
      startAssignment({ assignmentId, studentId }),
    onSuccess: (data) => {
      // Suppose data contains the fresh attempt object
      queryClient.setQueryData(["assignmentAttempt", assignmentId], data);
    },

    onError: (error) => {
      console.error(
        "Failed to start assignment:",
        error.response?.data || error.message
      );
      // Handle error (e.g., show an error notification)
    },
  });
};
