import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useSaveProgress } from "../../../../../hooks/Progress/useProgress";
import ResponseFeedback from "./ResponseFeedback";
import DangerConfirmationPopup from "../../../../Shared/DangerConfirmationPopup";
import { useQuery } from "@tanstack/react-query";
import extractTests from "../TestsComponent/extractTests";

const WritingTest = ({ testId, assignmentId }) => {
  const { data } = useQuery({
    queryKey: ["assignmentAttempt", assignmentId],
    enabled: false, // or enabled: !!assignmentId, but we have no queryFn
  });

  const attempt = data?.attempt;
  const tests = extractTests(attempt);
  const currentTest = tests.find((test) => {
    if (test.id === testId && test.type === "writing") return test;
  });
  const attempt_id = data.attempt.id;

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const currentTask = currentTest.tasks[currentTaskIndex];
  const currentTaskAnswer = currentTest.answers?.[currentTask.id] || {};

  const handleNavigation = (direction) => {
    setCurrentTaskIndex((prevIndex) =>
      direction === "next"
        ? Math.min(prevIndex + 1, currentTest.tasks.length - 1)
        : Math.max(prevIndex - 1, 0)
    );
  };

  const handleResponseChange = (e) => {
    setResponses({
      ...responses,
      [currentTask.id]: e.target.value,
    });
  };

  const { mutate: saveProgress, isPending: isSubmitting } =
    useSaveProgress(assignmentId);

  const handleSubmit = () => {
    const studentResponse = responses[currentTask.id] || "";

    const payload = {
      skill: "writing",
      response: studentResponse,
      taskId: currentTask.id,
      attempt_id: attempt_id,
      testId: testId,
      time_spent: 10,
    };

    console.log(payload);

    saveProgress({ assignmentId, progressData: payload });
  };

  const wordCount = (responses[currentTask.id] || "")
    .split(/\s+/)
    .filter(Boolean).length;

  return (
    <div
      className="d-flex flex-column"
      style={{ height: "calc(100vh - 60px)" }}
    >
      {/* Header */}
      <Header taskNumber={currentTask.task_number} />
      {/* Main Content */}

      <div className="flex-grow-1 d-flex overflow-auto">
        {/* Task Content */}
        <div className="p-3 border-end" style={{ flex: 2 }}>
          <p>{currentTask.prompt}</p>
          {currentTask.image && (
            <img
              src={currentTask.image}
              alt="Task Illustration"
              className="img-fluid mt-3"
            />
          )}
        </div>

        {/* Response Area */}
        {currentTaskAnswer.response ? (
          <ResponseFeedback currentTaskAnswer={currentTaskAnswer} />
        ) : (
          <div
            className="p-3"
            style={{ flex: 2, display: "flex", flexDirection: "column" }}
          >
            <textarea
              id={`textarea-${currentTask.id}`}
              className="form-control"
              value={responses[currentTask.id] || ""}
              disabled={currentTaskAnswer.response}
              onChange={handleResponseChange}
              placeholder="Write your response here..."
              style={{
                flex: 1,
                resize: "none",
                lineHeight: "1.5", // Adjust line height for better spacing
                paddingBottom: "36px", // Space at the bottom if needed
              }}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
            />

            <div className="mt-3 d-flex justify-content-between align-items-center">
              <span>
                <strong>Word Count:</strong> {wordCount}
              </span>

              <button
                className="btn btn-success"
                onClick={() => setShowConfirmPopup(true)}
                disabled={isSubmitting || wordCount === 0}
              >
                Submit
              </button>
              <DangerConfirmationPopup
                showConfirmPopup={showConfirmPopup}
                setShowConfirmPopup={setShowConfirmPopup}
                onConfirm={handleSubmit}
                message={
                  "Are you sure you want to submit the test? You cannot undo this action!"
                }
              />
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <Footer
        currentTaskIndex={currentTaskIndex}
        totalTasks={currentTest.tasks.length}
        onNavigate={handleNavigation}
      />
    </div>
  );
};

export default WritingTest;
