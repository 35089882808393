import { React } from "react";

import AddNewGroupButton from "./AddNewGroup/AddNewGroupButton";

const GroupsList = ({ groups, selectedGroup, setSelectedGroup }) => {
  return (
    <div
      className="col-3 border-end p-3 overflow-auto"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="m-0">Groups</h5>
        <AddNewGroupButton />
      </div>
      <ul className="list-group">
        {groups.map((group) => (
          <li
            key={group.id}
            className={`list-group-item ${
              selectedGroup?.id === group.id ? "active" : ""
            }`}
            onClick={() => setSelectedGroup(group)}
            style={{ cursor: "pointer" }}
          >
            {group.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupsList;
