import Navbar from "./components/Shared/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/Auth/LoginPage";
import RegisterPage from "./components/Auth/RegisterPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import Home from "./pages/Home";
import { UserDashboard } from "./pages/UserDashboard";
import { ErrorPage } from "./pages/ErrorPage";
import Unauthorized from "./pages/Unauthorised";

import { setNavigate } from "./utils/navigation";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate(); // React Router hook

  // Set the navigation function for global use
  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return (
    <>
      <Navbar />
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegisterPage />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Protected Routes */}
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute allowedRoles={["student", "teacher", "admin"]}>
              <UserDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}
