import React from "react";

const Footer = ({ currentPartIndex, totalParts, onNavigate }) => {
  return (
    <div className="footer bg-light p-3 d-flex justify-content-between align-items-center border-top">
      {/* Part info on the left */}
      <span>
        Part {currentPartIndex + 1} of {totalParts}
      </span>

      {/* Navigation buttons on the right */}
      <div className="ms-auto d-flex">
        <button
          className="btn btn-warning me-2"
          onClick={() => onNavigate("back")}
          disabled={currentPartIndex === 0}
        >
          &larr; Back
        </button>
        <button
          className="btn btn-warning"
          onClick={() => onNavigate("next")}
          disabled={currentPartIndex === totalParts - 1}
        >
          Next &rarr;
        </button>
      </div>
    </div>
  );
};

export default Footer;
