import api from "../api";

export const fetchStudentsByGroup = async (groupId) => {
  const response = await api.get(`/api/admin/groups/${groupId}/students/`);
  return response.data;
};

// Fetch students without groups
export const fetchStudentsWithoutGroups = async () => {
  const response = await api.get("/api/admin/students/without_groups/");
  console.log("response = ", response.data);
  return response.data;
};

// Fetch a specific student by ID
export const fetchStudentById = async (studentId) => {
  const response = await api.get(`/api/admin/students/${studentId}/`);
  return response.data;
};

// Update a student's data
export const updateStudent = async (studentId, data) => {
  const response = await api.put(`/api/admin/students/${studentId}/`, data);
  return response.data;
};

// Bulk delete students
export const bulkDeleteStudents = async (data) => {
  const response = await api.post("/api/admin/students/delete_multiple/", data);
  return response.data;
};
