export function disableUserActions(role) {
  if (import.meta.env.MODE !== "production") return; // Only apply in production
  if (role === "admin") return;

  // === BLOCK MOUSE EVENTS (Right-Click, Select, Copy, etc.) ===
  document.addEventListener("contextmenu", (e) => e.preventDefault());
  document.addEventListener("selectstart", (e) => e.preventDefault());
  document.addEventListener("copy", (e) => e.preventDefault());
  document.addEventListener("paste", (e) => e.preventDefault());
  document.addEventListener("dragstart", (e) => {
    if (e.target.tagName === "IMG") e.preventDefault();
  });
  document.addEventListener("touchstart", (e) => {
    if (e.target.tagName === "IMG") e.preventDefault();
  });

  // === BLOCK KEYBOARD SHORTCUTS ===
  document.addEventListener("keydown", (event) => {
    // Detect combined keys with code-based checks
    const { ctrlKey, shiftKey, key, code } = event;

    // List of combos you want to block (using `code` for letters)
    // - F12 => opens DevTools
    // - Ctrl + Shift + KeyI => DevTools
    // - Ctrl + Shift + KeyJ => DevTools console
    // - Ctrl + Shift + KeyC => Element inspector
    // - Ctrl + KeyU => View source
    // - Ctrl + KeyS => Save
    // - Ctrl + KeyP => Print
    // - Ctrl + KeyR => Refresh
    // ... etc.

    if (
      code === "F12" || // F12
      (ctrlKey && shiftKey && ["KeyI", "KeyJ", "KeyC"].includes(code)) ||
      (ctrlKey && ["KeyU", "KeyS", "KeyP", "KeyR"].includes(code))
    ) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  });

  console.log("🚫 User interactions disabled (Only in production)");
}
