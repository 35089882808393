import MonacoEditor from "@monaco-editor/react";
const BulkUploader = ({
  jsonData,
  setJsonData,
  handleJsonUpload,
  uploadErrors,
  uploading,
}) => (
  <>
    <h4>Bulk Upload</h4>

    <MonacoEditor
      height="600px"
      language="json"
      value={jsonData}
      onChange={(value) => setJsonData(value)}
      options={{ minimap: { enabled: false } }}
    />
    <div className="d-flex justify-content-end mt-2">
      <button
        className="btn btn-success"
        onClick={handleJsonUpload}
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Bulk upload"}
      </button>
    </div>
    {uploadErrors.length > 0 && (
      <div className="alert alert-danger mt-3">
        <h5>Errors:</h5>
        <ul>
          {uploadErrors.map((err, index) => (
            <li key={index}>
              Row {err.row}: {err.errors.join(", ")}
            </li>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default BulkUploader;
