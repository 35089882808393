const GrammarVocabularySettings = ({ selectedType, settings, setSettings }) => {
  const questionFormats =
    selectedType === "Vocabulary"
      ? [
          { value: "MCQ", label: "Multiple Choice" },
          { value: "GAP_FILL", label: "Gap-Fill" },
          { value: "MATCHING", label: "Matching" },
          { value: "MULTI_SELECT", label: "Multiple Answers" },
          { value: "WORD", label: "Words" },
          { value: "WORD_AND_EXAMPLE", label: "Words with examples" },
          { value: "SENTENCE", label: "Sentences" },
        ]
      : [
          { value: "MCQ", label: "Multiple Choice" },
          { value: "GAP_FILL", label: "Gap-Fill" },
          { value: "MATCHING", label: "Matching" },
          { value: "MULTI_SELECT", label: "Multiple Answers" },
        ];

  return (
    <div className="mt-3">
      <h3>{selectedType} Settings</h3>
      {questionFormats.map((format) => (
        <div key={format.value} className="mb-2">
          <label>{format.label}:</label>
          <input
            type="number"
            min={1}
            className="form-control"
            value={settings[format.value] || ""}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                [format.value]: e.target.value,
              }))
            }
          />
        </div>
      ))}
    </div>
  );
};

export default GrammarVocabularySettings;
