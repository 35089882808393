import api from "./api";

// Fetch Auth Status
export const fetchAuthStatus = async () => {
  const response = await api.get("/api/users/auth/status/");
  return response.data; // Example: { is_authenticated: true, role: "admin" }
};

// Login API Call
export const loginUser = async (data) => {
  const response = await api.post("/api/users/auth/login/", data);
  return response.data; // Example: { is_authenticated: true, role: "admin" }
};

// Register API Call
export const registerUser = async (data) => {
  const response = await api.post("/api/users/auth/register/", data);
  return response.data; // Example: { success: true }
};

// logout API Call
export const logoutUser = async () => {
  const response = await api.post("/api/users/auth/logout/");
  return response.data;
};
