import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center text-light">
      <div className="text-center">
        {/* Welcome Message */}
        <h1 className="display-4 fw-bold text-success mb-3">
          Welcome to Sherzod's English Learning Platform
        </h1>
        <p className="fs-5 text-dark mb-4">
          We're building something amazing to help you learn English. Stay tuned
          for updates!
        </p>

        {/* Action Buttons */}
        <div className="d-flex justify-content-center gap-3">
          <Link
            to="/signup"
            className="btn btn-success btn-lg px-4 shadow"
            style={{ color: "white" }}
          >
            Get Started
          </Link>
          <Link to="/login" className="btn btn-success btn-lg px-4 shadow">
            Login
          </Link>
        </div>
        {/* Under Construction Icon */}
        <div className="">
          <img
            src="https://png.pngtree.com/png-clipart/20230209/original/pngtree-website-under-construction-concept-png-image_8949640.png"
            alt="Under Construction"
            style={{ width: "400px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
