import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const languageOptions = {
  uz: { label: "O'zbek", flag: "🇺🇿" },
  kz: { label: "Қазақ", flag: "🇰🇿" },
  ru: { label: "Русский", flag: "🇷🇺" },
};

const DangerConfirmationPopup = ({
  onConfirm,
  showConfirmPopup,
  setShowConfirmPopup,
  message,
  setLanguage = null,
}) => {
  const [input, setInput] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("uz");

  const handleConfirm = () => {
    if (input.trim().toLowerCase() === "yes") {
      onConfirm();
      setShowConfirmPopup(false);
    } else {
      alert("Please type 'yes' to confirm.");
    }
  };

  const handleCancel = () => {
    setShowConfirmPopup(false);
  };

  if (!showConfirmPopup) return null;

  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
    >
      <div
        className="bg-white rounded p-4 shadow-lg"
        style={{ width: "600px" }}
      >
        <h4 className="text-center mb-3 fw-bold text-success">{message}</h4>

        {setLanguage && (
          <div className="mb-3 text-center">
            <label className="fw-bold">Choose your language:</label>
            <div className="d-flex justify-content-center mt-2">
              {Object.entries(languageOptions).map(([key, { label, flag }]) => (
                <button
                  key={key}
                  className={`btn mx-2 ${
                    selectedLanguage === key
                      ? "btn-success"
                      : "btn-outline-secondary"
                  }`}
                  onClick={() => {
                    setSelectedLanguage(key);
                    setLanguage(key);
                  }}
                  style={{
                    fontSize: "1.2rem",
                    padding: "8px 12px",
                    minWidth: "60px",
                  }}
                >
                  {flag}
                </button>
              ))}
            </div>
            <p className="mt-2 fw-bold">
              {languageOptions[selectedLanguage].label}
            </p>
          </div>
        )}

        <p className="text-center">Please type YES to confirm:</p>

        <input
          type="text"
          className="form-control mb-3"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type YES here"
        />

        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-secondary w-45"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="btn btn-success w-45" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DangerConfirmationPopup;
