import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <div>
      {/* Tabs Navigation */}
      <ul className="nav nav-tabs mb-3" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" key={tab.key} role="presentation">
            <button
              className={`nav-link ${activeTab === tab.key ? "active" : ""}`}
              onClick={() => handleTabChange(tab.key)}
              style={{
                color: activeTab === tab.key ? "#fff" : "#2a623d",
                backgroundColor:
                  activeTab === tab.key ? "#2a623d" : "transparent",
                borderColor: activeTab === tab.key ? "#2a623d" : "#d6d6d6",
                borderRadius: activeTab === tab.key ? "5px 5px 0 0" : "5px",
                fontWeight: activeTab === tab.key ? "bold" : "normal",
              }}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>

      {/* Tabs Content */}
      <div className="tab-content">
        {tabs.map(
          (tab) =>
            activeTab === tab.key && (
              <div
                key={tab.key}
                className="tab-pane active"
                style={{
                  backgroundColor: "#ffffff",
                  // border: "1px solid #d6d6d6",
                  // borderRadius: "5px",
                  padding: "20px",
                  // boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {tab.content}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Tabs;
