import getAnswerByQuestionId from "../GrammarVocabulary/getAnswerByQuestionId";
import GAPFILL from "../QuestionFormats/GAPFILL";
import Matching from "../QuestionFormats/Matching";
import MCQ from "../QuestionFormats/MCQ";
import MultiSelect from "../QuestionFormats/MultiSelect";
import TrueFalseNotGiven from "../QuestionFormats/TrueFalseNotGiven";

const Questions = ({ sections, isSubmitted, handleAnswerSelect, answers }) => {
  const renderQuestionContent = (question, answer, questionNumber) => {
    switch (question.format) {
      case "MCQ":
        return (
          <MCQ
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "MULTI_SELECT":
        return (
          <MultiSelect
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "GAP_FILL":
        return (
          <GAPFILL
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "MATCHING":
        return (
          <Matching
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      case "TRUE_FALSE_NOT_GIVEN":
        return (
          <TrueFalseNotGiven
            key={questionNumber}
            questionData={question}
            answer={answer}
            questionNumber={questionNumber}
            isSubmitted={isSubmitted}
            handleAnswerSelect={handleAnswerSelect}
          />
        );

      default:
        return (
          <p key={questionNumber} className="text-danger">
            Unknown question format
          </p>
        );
    }
  };

  return (
    <div
      className="questions p-3"
      style={{
        flex: 1,
        overflowY: "auto",
        maxHeight: "calc(100vh - 195px)", // Adjust height dynamically
      }}
    >
      {sections.map((section) => (
        <div key={section.id} className="mb-4">
          <h5>{section.name}</h5>
          <p className="text-muted">{section.instructions}</p>
          <div>
            {section.questions
              .sort((a, b) => a.order - b.order) // sort questions by question.order
              .map((question) => {
                const answer = getAnswerByQuestionId(answers, question.id);
                return (
                  <div key={question.id} className="mb-3">
                    {renderQuestionContent(question, answer, question.order)}
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Questions;
