import React from "react";
import getQuestionRange from "./getQuestionRange";
import HollowCircularScore from "../WritingTest/HollowCircularScore";

const Header = ({ sectionInfo, score, isSubmitted }) => {
  return (
    <div className="header bg-light py-1 px-2 border-bottom d-flex justify-content-between align-items-center">
      <div>
        <h4 className="m-0">Section {sectionInfo.order}</h4>
        <p className="text-muted m-0">
          Listen carefully and answer questions {getQuestionRange(sectionInfo)}
        </p>
      </div>
      {/* Right content (Score component) */}
      {isSubmitted && (
        <div>
          <HollowCircularScore score={score} />
        </div>
      )}
    </div>
  );
};

export default Header;
