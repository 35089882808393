import { React, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import { useBulkUploadSpeakingTests } from "./../../../../../../hooks/AdminDashboard/useSpeaking";
import { validateSpeakingTests } from "./../../../../../../utils/validation/speakingTestValidation";

const TestBulkupload = ({ subcategoryId }) => {
  const [jsonData, setJsonData] = useState(sampleTests);
  const [uploadErrors, setUploadErrors] = useState([]);

  const bulkUploadMutation = useBulkUploadSpeakingTests(subcategoryId);

  const handleJsonUpload = () => {
    if (!jsonData) {
      alert("Add at least one test!");
      return;
    }
    try {
      const testsData = JSON.parse(jsonData);
      if (!validateSpeakingTests(testsData))
        throw new Error("Invalid question format.");
      bulkUploadMutation.mutate({
        data: testsData,
      });
    } catch (error) {
      setUploadErrors([{ row: "N/A", errors: [error.message] }]);
    }
  };

  return (
    <div>
      <h4>Add NEW tests with parts, questions and cure cards.</h4>

      <MonacoEditor
        height="600px"
        language="json"
        value={sampleTests}
        onChange={(value) => setJsonData(value)}
        options={{ minimap: { enabled: false } }}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-success"
          onClick={handleJsonUpload}
          disabled={bulkUploadMutation.isPending}
        >
          {bulkUploadMutation.isPending ? "Uploading..." : "Bulk upload"}
        </button>
      </div>
      {uploadErrors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <h5>Errors:</h5>
          <ul>
            {uploadErrors.map((err, index) => (
              <li key={index}>
                Row {err.row}: {err.errors.join(", ")}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestBulkupload;

const sampleTests = `{
  "tests": [
    {
      "name": "Speaking Test 1",
      "description": "This is a beginner-level speaking test.",
      "parts": [
        {
          "part_number": 1,
          "instructions": "Introduce yourself and talk about your hobbies.",
          "image": "https://example.com/image1.jpg",
          "cue_card": null,
          "questions": [
            {
              "question_text": "What is your name?",
              "order": 1
            },
            {
              "question_text": "What do you like to do in your free time?",
              "order": 2
            }
          ]
        },
        {
          "part_number": 2,
          "instructions": "Describe an interesting place you have visited.",
          "image": "https://example.com/image2.jpg",
          "cue_card": {
            "topic": "An Interesting Place",
            "prompt": "Describe the place, why it is interesting, and your experience there.",
            "hints": "Talk about location, activities, and memorable moments."
          },
          "questions": [
            {
              "question_text": "Why did you visit this place?",
              "order": 1
            },
            {
              "question_text": "Would you recommend this place to others?",
              "order": 2
            }
          ]
        }
      ]
    },
    {
      "name": "Speaking Test 2",
      "description": "This is an intermediate-level speaking test.",
      "parts": [
        {
          "part_number": 1,
          "instructions": "Talk about your daily routine.",
          "image": "https://example.com/image3.jpg",
          "questions": [
            {
              "question_text": "What time do you usually wake up?",
              "order": 1
            },
            {
              "question_text": "What activities do you do in the morning?",
              "order": 2
            }
          ]
        },
        {
          "part_number": 2,
          "instructions": "Discuss the advantages and disadvantages of technology.",
          "image": "https://example.com/image4.jpg",
          "cue_card": {
            "topic": "Technology",
            "prompt": "Talk about how technology has changed our lives. Focus on pros and cons.",
            "hints": "Think about communication, jobs, and daily activities."
          },
          "questions": [
            {
              "question_text": "What is one major benefit of technology?",
              "order": 1
            },
            {
              "question_text": "What is one drawback of relying too much on technology?",
              "order": 2
            }
          ]
        }
      ]
    }
  ]
}
`;
