import React from "react";

const WordList = ({ words, selectedWord, handleSelectWord }) => {
  return (
    <div
      className="col-3 border-end p-3 overflow-auto"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <h5 className="mb-3">Word List</h5>
      <ul className="list-group">
        {words.map((word) => (
          <li
            key={word.id}
            className={`list-group-item ${
              selectedWord?.id === word.id ? "active" : ""
            }`}
            onClick={() => handleSelectWord(word)}
            style={{ cursor: "pointer" }}
          >
            {word.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WordList;
