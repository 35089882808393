import React from "react";
import { Link } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "./../../hooks/auth/useAuth";
import { logoutUser } from "../../api/authAPI";

export default function Navbar() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Mutation for handling logout
  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: async () => {
      // Clear cache and localStorage, redirect to login
      queryClient.clear();
      localStorage.clear();
      navigate("/login");
    },
    onError: () => {
      console.error("Logout failed");
      queryClient.clear();
      localStorage.clear();
      navigate("/login");
    },
  });

  const { isAuthenticated } = useAuth();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top shadow-sm">
      <div className="container-fluid">
        {/* Logo */}
        <Link className="navbar-brand fw-bold" to="/">
          SherzodsEnglish
        </Link>

        {/* Toggle Button */}
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}

        {/* Collapsible Menu */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            {/* Add more links here if necessary */}
          </ul>
        </div>

        {/* Always Visible Links */}
        <ul className="navbar-nav ms-auto">
          {/* Dashboard Link for Authenticated Users */}
          {isAuthenticated && (
            <li className="nav-item">
              <Link className="nav-link" to="/user-dashboard">
                My Room
              </Link>
            </li>
          )}

          {/* Logout or Login Link */}
          <li className="nav-item">
            {logoutMutation.isPending ? (
              <span className="nav-link">
                <span
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </span>
            ) : isAuthenticated ? (
              <Link onClick={logoutMutation.mutate} className="nav-link" to="#">
                Log out
              </Link>
            ) : (
              <Link className="nav-link" to="/login">
                Log in
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}
