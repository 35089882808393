export const validateSpeakingTests = (tests) => {
  console.log("Validating speaking tests:", tests);

  // Validate each test
  const isValid = tests.every((test, testIndex) => {
    console.log(`\nValidating Test ${testIndex + 1}:`, test);

    // Validate Test fields
    if (!test.name || !test.description) {
      console.error(
        `Test ${testIndex + 1} failed: Missing name or description.`
      );
      return false;
    }

    if (!Array.isArray(test.parts) || test.parts.length === 0) {
      console.error(
        `Test ${testIndex + 1} failed: Parts must be a non-empty array.`
      );
      return false;
    }

    // Validate each part
    const partValid = test.parts.every((part, partIndex) => {
      console.log(`  Validating Part ${partIndex + 1}:`, part);

      // Validate Part fields
      if (!part.part_number || !part.instructions) {
        console.error(
          `Part ${partIndex + 1} failed: Missing part_number or instructions.`
        );
        return false;
      }

      // Validate Questions
      if (!Array.isArray(part.questions)) {
        console.error(
          `Part ${partIndex + 1} failed: Questions must be a non-empty array.`
        );
        return false;
      }

      const questionValid = part.questions.every((q, questionIndex) => {
        console.log(`    Validating Question ${questionIndex + 1}:`, q);

        if (!q.question_text || !q.order) {
          console.error(
            `Question ${
              questionIndex + 1
            } failed: Missing question_text or order.`
          );
          return false;
        }

        return true; // Question is valid
      });

      if (!questionValid) return false;

      // Validate Cue Card (if present)
      if (part.cue_card) {
        console.log(
          `  Validating Cue Card for Part ${partIndex + 1}:`,
          part.cue_card
        );

        if (!part.cue_card.topic || !part.cue_card.prompt) {
          console.error(
            `Part ${partIndex + 1} failed: Missing topic or prompt in cue card.`
          );
          return false;
        }
      }

      return true; // Part is valid
    });

    return partValid;
  });

  console.log("Validation Result:", isValid);
  return isValid;
};
