import React from "react";
import StudentDashboard from "./../components/Student/StudentDashbord/StudentDashboard";
import TeacherDashboard from "./../components/Teacher/TeacherDashboard/TeacherDashboard";
import AdminDashboard from "./../components/Admin/AdminDashboard/AdminDashboard";
import { useAuth } from "../hooks/auth/useAuth";
import { Navigate } from "react-router-dom";
import LoadingAnimation from "../utils/LoadingAnimation";

export const UserDashboard = () => {
  const { role, loading, error } = useAuth();

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    console.error("Authentication error:", error);
    return <h1>Xatolik yuz berdi. Qayta urinib ko'ring.</h1>;
  }

  switch (role) {
    case "student":
      return <StudentDashboard />;
    case "teacher":
      return <TeacherDashboard />;
    case "admin":
      return <AdminDashboard />;
    default:
      return <Navigate to="/unauthorized" replace />;
  }
};

export default UserDashboard;
