import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MonacoEditor from "@monaco-editor/react";

const AddGroupModel = ({
  show,
  handleClose,
  handleAdd,
  newItem,
  setNewItem,
  isLoading,
  title,
}) => {
  // Handle changes in the Monaco editor
  const handleEditorChange = (value) => {
    try {
      setNewItem(JSON.parse(value)); // Update state with parsed JSON
    } catch (error) {
      console.error("Invalid JSON format", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MonacoEditor
          height="200px"
          // width="200px"
          language="json"
          value={JSON.stringify(newItem, null, 2)} // Display JSON data
          onChange={handleEditorChange} // Handle editor change
          options={{ minimap: { enabled: false } }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAdd} disabled={isLoading}>
          {isLoading ? "Adding..." : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddGroupModel;
