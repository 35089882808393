import { useState } from "react";
import { useWords } from "../../../../../../hooks/AdminDashboard/useWords";
import WordList from "./WordList";
import Word from "./Word";
import Images from "./Images";
import Questions from "./Questions";

import Tabs from "./../../../../../Shared/Tabs";
import WordsBulkupload from "./WordsBulkupload";
import QuestionsBulkupload from "./QuestionsBulkupload";
import ExamplesBulkUpload from "./ExamplesBulkUpload";
import Examples from "./Examples";

const VocabularyContent = ({ categoryId, subcategoryId }) => {
  // States
  const [selectedWord, setSelectedWord] = useState(null); // For selected word

  const { data, isLoading } = useWords(categoryId, subcategoryId);

  if (isLoading) {
    return <>Loading ... plz wait</>;
  }

  console.log("data = ", data);

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row h-100">
        {/* Left Panel */}
        <WordList
          words={data}
          selectedWord={selectedWord}
          handleSelectWord={setSelectedWord}
        />
        {/* Main Content */}
        <div className="col-9">
          <Tabs
            tabs={[
              {
                key: "word",
                label: "Word",
                content: (
                  <Word
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    selectedWord={selectedWord}
                  />
                ),
              },
              {
                key: "images",
                label: "Images",
                content: (
                  <Images
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    selectedWord={selectedWord}
                  />
                ),
              },
              {
                key: "questions",
                label: "Questions",
                content: (
                  <Questions
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    selectedWord={selectedWord}
                  />
                ),
              },
              {
                key: "examples",
                label: "Examples",
                content: (
                  <Examples
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    selectedWord={selectedWord}
                  />
                ),
              },
              {
                key: "bulkuploadquestions",
                label: "Bulk upload questions",
                content: (
                  <QuestionsBulkupload
                    selectedWord={selectedWord}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                  />
                ),
              },
              {
                key: "bulkuploadexamples",
                label: "Bulk upload examples",
                content: (
                  <ExamplesBulkUpload
                    selectedWord={selectedWord}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                  />
                ),
              },
              {
                key: "bulkuploadnew",
                label: "Bulk upload new",
                content: (
                  <WordsBulkupload
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default VocabularyContent;
