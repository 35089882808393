import React, { useState, useEffect } from "react";
import {
  useFetchSimpleReadingTests,
  useFetchReadingTestById,
} from "../../../../../../hooks/AdminDashboard/useReading";
import MonacoEditor from "@monaco-editor/react";

const ReadingTestList = ({
  selectedSubcategoryId,
  setSelectedReadingTests,
  selectedReadingTests,
}) => {
  // Fetch test list
  const { data: tests, isLoading } = useFetchSimpleReadingTests(
    selectedSubcategoryId
  );

  // States for selected test and its details
  const [selectedTest, setSelectedTest] = useState(null);
  const [testDetails, setTestDetails] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);

  // Fetch test details when a test is selected
  const { data: readingDetails, isLoading: detailsLoading } =
    useFetchReadingTestById(selectedSubcategoryId, selectedTest?.id);

  // Update details when test data is fetched
  useEffect(() => {
    if (readingDetails) {
      setTestDetails(readingDetails);
    }
    setLoadingDetails(detailsLoading);
  }, [readingDetails, detailsLoading]);

  // Handle checkbox selection for tests
  const handleCheckboxChange = (testId) => {
    setSelectedReadingTests(
      (prev) =>
        prev.includes(testId)
          ? prev.filter((id) => id !== testId) // Remove if already selected
          : [...prev, testId] // Add if not selected
    );
  };

  // Loading state for test list
  if (isLoading) return <p>Loading Reading Tests...</p>;

  return (
    <div>
      {/* Render Test List if no test is selected */}
      {!selectedTest ? (
        <>
          <h5 className="mb-3">Reading Tests</h5>
          <ul className="list-group">
            {tests.map((test) => (
              <li
                key={test.id}
                className="list-group-item d-flex align-items-center justify-content-between"
              >
                {/* Test Name and Description */}
                <span
                  onClick={() => setSelectedTest(test)} // Set selected test
                >
                  <strong>{test.name}</strong> - {test.description}
                </span>

                {/* Checkbox for selection */}
                <input
                  type="checkbox"
                  checked={selectedReadingTests.includes(test.id)}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent click from selecting the test
                    handleCheckboxChange(test.id);
                  }}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          {/* Render Test Details when a test is selected */}
          <div>
            <button
              className="btn btn-dark"
              onClick={() => setSelectedTest(null)} // Go back to test list
            >
              Back to Test List
            </button>
            <h3>{selectedTest.name}</h3>
            <p>{selectedTest.description}</p>
            {loadingDetails ? (
              <p>Loading details...</p>
            ) : (
              <MonacoEditor
                height="600px"
                language="json"
                value={JSON.stringify(testDetails, null, 2)} // Display JSON details
                options={{ minimap: { enabled: false } }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReadingTestList;
